/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 11/5/2019
 * @Example
 */
import React, { useRef, useImperativeHandle, forwardRef } from "react";
import { Notify } from "diginet-core-ui/components";

const NotifyCom = forwardRef((props, ref) => {

    const refNoti = useRef(null);

    useImperativeHandle(ref, () => ({ show }));

    const show = (type, message, time, option = {}) => {
        if (!refNoti.current) return;
        refNoti.current.add(message, {
            duration: time,
            color: type === "error" ? "danger" : type,
            position: {
                vertical: "bottom",
                horizontal: "center",
            },
            ...option,
        });
    };

    return (
        <Notify
            ref={refs => (refNoti.current = refs)}
            position={{ vertical: "bottom", horizontal: "center" }}
            autoDisappear
        />
    );
});

export default NotifyCom;
