/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 1/8/2020
 * @Example 
 */
import React from "react";
import {withStyles, FormControl, Input, InputLabel, FormHelperText} from "@material-ui/core";
import PropTypes from 'prop-types';
import ButtonGeneral from "../common/button/button-general";
import Config from "../../config";
import {FormGroup} from "react-bootstrap";

const styles = theme => {
    return {
        card: {
            width: "590px",
            height: "178px",
            padding: "37px 28px 0px 28px",
            [theme.breakpoints.down('xs')]: {
                width: "300px",
                height: "170px",
            },
        },
        input: {
            padding: '0px 0px 21px 21px'
        },
        underline: {
            paddingTop: '10px'
        },
        shrink: {
            transform: 'translate(0, 1.5px) scale(1)',
        },
    }
};


class ApprovalPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notes: "",
        };
    }

    onNoteChange = (e) => {
        this.setState({
            notes: e.target.value
        })
    };

    render() {
        const {type, loading, classes, labelBtnApprove, labelBtnNotApprove, errorNote} = this.props;
        const { notes } = this.state;
        return (
            <React.Fragment>
                <FormControl fullWidth={true} error={!!errorNote && !notes} style={{padding: "0px 0px 21px 0px"}}>
                    <InputLabel
                        color={type === "approve" ? "primary" : "secondary"}
                        shrink={true}
                        style={{
                            fontSize: '14px',
                            fontWeight: 200,
                        }}
                        classes={{shrink: classes.shrink}}
                    >
                        {Config.lang("DHR_Ghi_chu_duyet")}
                    </InputLabel>
                    <Input fullWidth={true} classes={{underline: classes.underline}}
                           color={type === "approve" ? "primary" : "secondary"}
                           rowsMin={3}
                           multiline={true}
                           onChange={this.onNoteChange}
                    >
                    </Input>
                    <FormHelperText>{!notes && errorNote ? errorNote : ""}</FormHelperText>
                </FormControl>
                <FormGroup>
                {type === "approve" && <ButtonGeneral
                            name={labelBtnApprove ? labelBtnApprove : Config.lang("DHR_Duyet")}
                            color={"primary"}
                            variant={"contained"}
                            className="pull-right"
                            loading={loading}
                            style={{textTransform: 'uppercase'}}
                            size={"large"}
                            onClick={()=> {
                                const {onApproval} = this.props;
                                if (onApproval) onApproval(this.state.notes);
                            }}
                        />}
                {type === "cancel" && <ButtonGeneral
                        name={labelBtnNotApprove ? labelBtnNotApprove : Config.lang("DHR_Tu_choi")}
                        color={"danger"}
                        variant={"contained"}
                        className="pull-right"
                        loading={loading}
                        style={{textTransform: 'uppercase'}}
                        size={"large"}
                        onClick={()=> {
                            const {onCancel} = this.props;
                            if (onCancel) onCancel(this.state.notes);
                        }}
                    />}
                </FormGroup>
            </React.Fragment>
        );
    }

}

ApprovalPopup.propTypes = {
    className: PropTypes.string,
    type: PropTypes.string,
    labelBtnApprove: PropTypes.string,
    labelBtnNotApprove: PropTypes.string,
    loading: PropTypes.bool,

    onApproval: PropTypes.func,
    onCancel: PropTypes.func,
};

export default withStyles(styles, {withTheme: true})(ApprovalPopup);