/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 11/8/2019
 * @Example 
 */

import React, {PureComponent} from 'react';

import PropTypes from "prop-types";
import TextFieldM from "@material-ui/core/TextField";

class TextField extends PureComponent {

    onChange = (e) => {
        const {onChange} = this.props;
        if (e && e.target) e.target.value = e.target.value.normalize();
        if (onChange) onChange(e);
    };

    render() {
        const {disabled, value, label, placeholder, variant, margin, fullWidth,
            required, children, name, error, helperText,
            ...props} = this.props;

        return (
            <TextFieldM
                {...props}
                error={!!error && !disabled && (required ? !value : true)}
                disabled={disabled}
                label={label}
                variant={variant}
                margin={margin}
                placeholder={placeholder}
                value={value}
                name={name}
                onChange={this.onChange}
                fullWidth={fullWidth}
                required={required}
                helperText={typeof error === "string" && (required ? !value : true) ? error : helperText}
            >
                {children}
            </TextFieldM>
        )
    }
}

TextField.propTypes = {
    error: PropTypes.any,
    helperText: PropTypes.string,
    name: PropTypes.string,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    variant: PropTypes.string,
    margin: PropTypes.string,
    placeholder: PropTypes.string,
    fullWidth: PropTypes.any,
    value: PropTypes.string,
    required: PropTypes.bool,
    onChange: PropTypes.func,
};

export default TextField;