/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 8/17/2020
 * @Example
 */


import React from "react";
import {withStyles} from "@material-ui/core";
import propTypes from "prop-types";
import {compose} from "redux";
import Icon from '@material-ui/core/Icon';
import InlineSVG from "react-inlinesvg";
import {Image} from "react-bootstrap";
import CIcon from 'diginet-core-ui/icons';
import { ButtonIcon } from 'diginet-core-ui/components';

const styles = theme => ({
    icon: {
        cursor: "pointer"
    },
    primary: {
        color: theme.palette.primary.main,
        "& path, & circle, & line": {
            fill: theme.palette.primary.main
        },
    },
    success: {
        color: theme.palette.success.main,
        "& path, & circle, & line": {
            fill: theme.palette.success.main
        },
    },
    danger: {
        color: theme.palette.danger.main,
        "& path, & circle, & line": {
            fill: theme.palette.danger.main
        },
    },
    info: {
        color: theme.palette.info.main,
        "& path, & circle, & line": {
            fill: theme.palette.info.main
        },
    },
    warning: {
        color: theme.palette.warning.main,
        "& path, & circle, & line": {
            fill: theme.palette.warning.main
        },
    },

});

const defaultColorHover = {
    edit: "info",
    delete: "warning",
    view: "info",
    approval: "success",
    notapproval: "danger"
};
class Icons extends React.Component {

    convertIconName = (name, width, height, style, className) => {
        const {color} = this.props;
        const colorHover = this.props?.colorHover || defaultColorHover[name?.toLowerCase()] || "";
        const listName = {
            add: "AddCircle",
            add_user: "AddPerson",
            approval: "Approval",
            arrowhead_right: "ArrowDoubleRight",
            back_filled: "Back",
            biggroup: "More",
            book: "Guide",
            calendar: "Calendar",
            cancel: "Cancel",
            cancel_filled: "CancelFilled",
            circle_plus: "AddOutline",
            clock: "HalfClock",
            "compress-arrows-alt-solid": "Minimize",
            copy: "Copy",
            date: "Calendar",
            delete: "Delete",
            down: "ArrowDown",
            down_filled: "ArrowDownFilled",
            download: "Download",
            download_filled: "Download",
            edit: "Edit",
            email: "Mail",
            error: "Block",
            export: "Export",
            filter: "Filter",
            group: "PersonGroup",
            guide: "Guide",
            instruction: "Info",
            left: "ArrowLeft",
            left_filled: "ArrowLeftFilled",
            listview: "ListView",
            maximize_filled: "Maximize",
            menu: "Menu",
            minimize_filled: "Minimize",
            more_horizonal: "MoreHoriz",
            ninedot: "Ninedot",
            notapproval: "Cancel",
            notification: "Notification",
            "person-done": "PersonAccept",
            phone: "Phone",
            plus_filled: "AddCircle",
            right: "ArrowRight",
            right_filled: "ArrowRightFilled",
            save: "Save",
            save_filled: "Save",
            scan: "Scan",
            share: "Share",
            search: "Search",
            subtract_filled: "MinusFilled",
            success: "ApprovalOutline",
            unpin: "UnPin",
            up: "ArrowUp",
            up_filled: "ArrowUpFilled",
            view: "View",
            waiting: "Wait",
            warehoure_filled: "Warehouse",
            warning: "Warning",

            add_custom: "add_custom",
            address: "Marker",
            "approval-circle": "ApprovalOutline",
            attachment: "Attachment",
            cancel2: "Cancel",
            checklist: "CheckList",
            detail: "DocumentSearch",
            important: "Star",
            "Inheritance-task": "Copy",
            left_arrow: "ArrowLeft",
            list2: "List",
            orange_warning: "Warning",
            position: "Bag",
            risk: "Warning",
            role: "PersonAccept",
            show_notes: "ArrowUp",
            stage1: "SpeedHigh",
            stage2: "SpeedLow",
            swap: "Sync",
            "swap-user": "PersonSync",
            treeview: "Treeview",
            update: "Update",
            "update-info-cancel": "InfoCancel",
            urgent: "NotificationV2",
            user: "Person",
            user_default: "Account",
            website: "Website",
            white_close: "Close",
            work: "Bag"
        };
        return !name || !listName[name] ? null
            : (colorHover ? <ButtonIcon
                        circular
                        viewBox
                        name={listName[name]}
                        viewType={"ghost"}
                        size={"small"}
                        color={color}
                        colorHover={colorHover}
                        width={width}
                        height={height}
                        style={style}
                        className={className}
                    />
                    : <CIcon viewBox
                             name={listName[name]}
                             width={width}
                             height={height}
                             style={style}
                             color={color}
                             className={className}
                    />
            );
    };

    getDefineIcons = () => {
        const {name : _name, src, color, width, height, typeIcon} = this.props;
        const name = typeIcon !== "filled" ? _name : _name + "_filled";
        if (!name && !src) return {src: "", color, width, height};
        if (src) return {src: src, color, width, height};
        if (!name) return null;
        // const icon = listIcons.find(l => l.name === name);
        return {
            name: name || "",
            src: "",
            color: color || "default",
            width: width,
            height: height,
        };
    };

    render() {
        const {classes, name: _name, type, fontSize, className, style, src : _src,
                  component, children
              } = this.props;
        if (!_src && !className && !_name && !component && !children) return null;
        let _className = className + " icons-ui " + classes.icon;

        const {name, src, color, width, height} = this.getDefineIcons();

        //add core icon
        const IconCore = this.convertIconName(name, width, height, style, _className);

        if (color && classes[color]) _className += " " + classes[color];

        return (
            <>
                {IconCore ? (
                    IconCore
                ) : src ? (
                    type === "inline" ? <InlineSVG uniquifyIDs={true} src={src} width={width} height={height} style={style} className={_className}/>
                        :
                        <Image src={src} width={width} height={height} style={style} className={_className}/>
                ) : (component || children) ? (
                    <div className={_className} style={style}>
                        {component ? component : children}
                    </div>
                ) : (
                    <Icon fontSize={fontSize} className={_className} style={style} />
                )
                }
            </>
        );
    }
}
Icons.defaultProps = {
    type: "inline",
    typeIcon: "normal",
    color: "default",
    className: "",
};
Icons.propTypes = {
    name: propTypes.string,
    component: propTypes.any,
    className: propTypes.any,
    style: propTypes.any,
    src: propTypes.string,
    width: propTypes.any,
    height: propTypes.any,
    disabled: propTypes.bool,
    color: propTypes.any,
    fontSize: propTypes.string, //for Icon tag
    type: propTypes.oneOf(["normal", "inline"]),
    typeIcon: propTypes.oneOf(["normal", "filled"])
};

export default compose(withStyles(styles))(Icons)
