/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 12/10/2019
 * @Example
 */

import PropTypes from "prop-types";
import React from "react";
import {Popover as PopoverM} from "@material-ui/core"

class Popover extends React.Component {

    render(){
        const {open, style, className, anchorOrigin, PaperProps, transformOrigin, anchorEl,cursor, items, onClose} = this.props;
        return(
            <React.Fragment>
                {cursor && typeof cursor === "function" ? cursor() : cursor}
                <PopoverM
                    PaperProps={PaperProps}
                    style={style}
                    className={className}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={onClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                        ...anchorOrigin
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                        ...transformOrigin
                    }}
                >
                    {items && typeof items === "function" ? items() : items}
                </PopoverM>
            </React.Fragment>
        )
    }
}

Popover.propTypes = {
    items: PropTypes.any,
    cursor: PropTypes.any,
    open: PropTypes.bool,
    anchorEl: PropTypes.any,
    anchorOrigin: PropTypes.any,
    transformOrigin: PropTypes.any,
    PaperProps: PropTypes.any,
    style: PropTypes.any,
    className: PropTypes.string,

    onClose: PropTypes.func
};

export default Popover;
