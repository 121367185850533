/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 10/29/2019
 * @Example 
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from "react-swipeable-views";
import {Typography, Box} from "@material-ui/core";

let currentKeyTab = 0;
let lazyLoaded = false;
class TabContent extends Component {

    onChangeIndex = (e) => {
        if (this.props.onChangeIndex) {
            this.props.onChangeIndex(e);
        }
    };

    render() {
        const {className, activeKey, children, style, direction, disableSwipe, disableTranstion, lazyLoading} = this.props;
        currentKeyTab = activeKey;
        lazyLoaded = lazyLoading;
        return (
            <SwipeableViews
                style={style}
                axis={typeof direction === "undefined" ? 'x' : direction}
                index={activeKey}
                disabled={disableSwipe}
                slideClassName={className}
                animateTransitions={!disableTranstion}
                slideStyle={{overflow: 'hidden'}}
                onChangeIndex={this.onChangeIndex}
            >{children}</SwipeableViews>
        );
    }
}

TabContent.propTypes = {
    activeKey: PropTypes.number,
    style: PropTypes.object,
    className: PropTypes.string,
    disableSwipe: PropTypes.bool,
    disableTranstion: PropTypes.bool,
    onChangeIndex: PropTypes.func,
    lazyLoading: PropTypes.bool
};

class TabPanel extends Component {

    render() {
        const { children, tabKey, index, lazyLoading, ...other } = this.props;
        currentKeyTab = tabKey || tabKey === 0 ? tabKey : currentKeyTab;
        lazyLoaded = lazyLoading ? lazyLoading : lazyLoaded;

        return (
            <Typography
                component="div"
                role="tabpanel"
                hidden={currentKeyTab !== index}
                id={`full-width-tabpanel-${index}`}
                aria-labelledby={`full-width-tab-${index}`}
                {...other}
            >
                {(currentKeyTab === index || !lazyLoaded) && <Box component={'div'}>{children}</Box>}
            </Typography>
        );
    }
}

TabPanel.propTypes = {
    tabKey: PropTypes.number,
    index: PropTypes.number,
    other: PropTypes.object,
    lazyLoading: PropTypes.bool
};


export {
    TabPanel,
    TabContent
}

export default TabContent;