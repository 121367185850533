import React, { useState, forwardRef, useRef, useEffect, useImperativeHandle } from "react";
import Config from "../../config";
import { makeStyles } from "diginet-core-ui/theme";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    AccordionGroup,
    Button,
    ButtonIcon,
    TabContainer,
    TabHeader,
    TabItem,
    TabPanel,
    Typography,
    Tooltip,
} from "diginet-core-ui/components";
import Icon from "diginet-core-ui/icons";
import JSONTree from "react-json-tree";

const useStyles = makeStyles({
    debuggerButton: {
        position: "fixed",
        bottom: 8,
        right: 8,
        zIndex: 9003,
    },
    debuggerPopup: {
        display: "flex",
        flexDirection: "column",
        position: "fixed",
        zIndex: 1399,
        bottom: 72,
        right: 8,
        width: 350,
        minWidth: 350,
        height: 520,
        minHeight: 520,
        backgroundColor: "#FFF",
        borderRadius: 4,
        boxShadow: "-1px 2px 10px lightgrey",
        "@media (max-width:720px)": {
            // eslint-disable-line no-useless-computed-key
            width: "85%",
            right: 0,
            left: 0,
            marginRight: "auto",
            marginLeft: "auto",
        },
    },
    debuggerPopupBodyItem: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        width: "100%",
    },
    btnCopy: {
        position: "absolute",
        right: 4,
    },
    iconDanger: {
        "& .Accordion-Expand-Icon": {
            color: `${Config.coreTheme?.colors?.danger} !important`,
        },
        "& .Accordion-Collapse-Icon": {
            color: `${Config.coreTheme?.colors?.danger} !important`,
        },
    },
    tabPanel: {
        height: "100%",
        overflow: "auto",
    },
    flexCenter: {
        display: "flex",
        alignItems: "center",
    },
    tabHeader: {
        "& .DGN-UI-Tab-Header": {
            width: "100%",
            justifyContent: "flex-end",
        },
    },
    clearContainer: {
        display: "flex",
        justifyContent: "flex-end",
        padding: "0 8px",
    },
    blinkingAnimation: {
        animation: "blinking 1s infinite"
    }
});

const theme = {
    base00: "#272822",
    base01: "#383830",
    base02: "#49483e",
    base03: "#75715e",
    base04: "#a59f85",
    base05: "#f8f8f2",
    base06: "#f5f4f1",
    base07: "#f9f8f5",
    base08: "#f92672",
    base09: "#fd971f",
    base0A: "#f4bf75",
    base0B: "#a6e22e",
    base0C: "#a1efe4",
    base0D: "#66d9ef",
    base0E: "#ae81ff",
    base0F: "#cc6633",
};

const Debugger = forwardRef((props, reference) => {
    const ref = useRef(null);
    const [showDebuggerPopup, setshowDebuggerPopup] = useState(false);
    const [dataDebugger, setDataDebugger] = useState(JSON.parse(Config.getLocalStorage("DEBUGGER")) || []);
    const showDebugger = Config.getSetting("DEBUGGER") === "true";
    const dataDebuggerError = dataDebugger.filter(item => item?.code !== 200);
    const classes = useStyles();

    let tabPanelArr = [dataDebugger, dataDebuggerError];

    const setData = data => {
        setDataDebugger(data);
    };

    const pushData = data => {
        let dataArray = Config.debugger?.dataDebugger || [];
        if (dataArray.length > 50) {
            dataArray.splice(20);
            Config.setLocalStorage("DEBUGGER", JSON.stringify(dataArray));
        }
        setData([data, ...dataArray]);
    };

    useEffect(() => {
        if (dataDebuggerError?.length > 0 && Config.getLocalStorage("DEBUGGER") !== JSON.stringify(dataDebuggerError)) {
            Config.setLocalStorage("DEBUGGER", JSON.stringify(dataDebuggerError));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataDebugger]);

    useImperativeHandle(reference, () => {
        const currentRef = ref.current || {};
        currentRef.setData = setData;
        currentRef.pushData = pushData;
        currentRef.dataDebugger = dataDebugger;
        currentRef.dataDebuggerError = dataDebuggerError;
        return currentRef;
    });

    const onCopy = data => {
        const temp = document.createElement("textarea");
        temp.value = data;
        document.body.appendChild(temp);
        temp.select();
        document.execCommand("copy");
        temp.remove();
        Config.notify.show("success", Config.lang("SQL copied successfully"), 2000);
    };

    const onClear = () => {
        Config.removeLocalStorage("DEBUGGER");
        setData([]);
    };

    return (
        <div ref={ref}>
            {showDebugger && (
                <ButtonIcon
                    circular
                    viewType={"filled"}
                    color={dataDebuggerError?.length > 0 ? "danger" : "primary"}
                    size={"giant"}
                    name={showDebuggerPopup ? "Close" : "Bug"}
                    onClick={() => setshowDebuggerPopup(!showDebuggerPopup)}
                    className={classes.debuggerButton}
                />
            )}
            {showDebuggerPopup && (
                <div className={classes.debuggerPopup}>
                    <TabContainer style={{ height: "100%" }}>
                        <TabHeader className={classes.tabHeader}>
                            <Typography style={{ alignSelf: "center", paddingLeft: 16, marginRight: "auto" }} type={"title4"}>
                                Debugger
                            </Typography>
                            <TabItem index={0} label={"All"} />
                            <TabItem index={1}>
                                <Icon name={"Warning"} color={"danger"} />
                            </TabItem>
                        </TabHeader>
                        <div className={classes.clearContainer}>
                            <Button color={"info"} size={"small"} viewType={"link"} onClick={onClear}>
                                Clear All
                            </Button>
                        </div>
                        {tabPanelArr.map((tabPanel, tabPanelIdx) => {
                            return (
                                <TabPanel key={tabPanelIdx} index={tabPanelIdx} className={classes.tabPanel}>
                                    <AccordionGroup style={{ overflow: "overlay" }}>
                                        {tabPanel.map((item, idx) => {
                                            const code = item?.code;
                                            const route = item?.route;
                                            const responseTime = item?.debugger?.responseTime?.total;
                                            const startTime = item?.debugger?.startTime
                                                ? Config.convertDate(item?.debugger?.startTime, undefined, "DD/MM/YYYY HH:mm:ss", false)
                                                : "";
                                            const sql = item?.debugger?.sql;
                                            const status = code === 200 ? "success" : item?.message || "";
                                            const blinking = responseTime > 3000 ? classes.blinkingAnimation : {};
                                            return (
                                                <Accordion key={idx}>
                                                    <AccordionSummary
                                                        expandIconAt="end"
                                                        expandIconProps={{ className: code === 200 ? "" : classes.iconDanger }}
                                                        style={{ paddingRight: 4 }}
                                                    >
                                                        <div className={classes.debuggerPopupBodyItem}>
                                                            <div className={classes.flexCenter}>
                                                                <Tooltip style={{ alignSelf: "flex-start" }} title={"Route"}>
                                                                    <Icon
                                                                        name={"Link"}
                                                                        color={code === 200 ? "currentColor" : "danger"}
                                                                        style={{ marginRight: 4 }}
                                                                    />
                                                                </Tooltip>
                                                                <Typography color={code === 200 ? "default" : "danger"} type={"h5"}>
                                                                    {route}
                                                                </Typography>
                                                            </div>
                                                            {(responseTime || startTime) && (
                                                                <div className={`${classes.flexCenter} ${blinking}`}>
                                                                    <Tooltip style={{ alignSelf: "flex-start" }} title={"Response-time; Start-time"}>
                                                                        <Icon
                                                                            name={"Clock"}
                                                                            color={code === 200 ? "currentColor" : "danger"}
                                                                            style={{ marginRight: 4 }}
                                                                        />
                                                                    </Tooltip>
                                                                    <Typography className={`${blinking}`} color={code === 200 ? "default" : "danger"} type={"h5"}>
                                                                        {responseTime ? responseTime + "ms; " : ""}
                                                                        {startTime}
                                                                    </Typography>
                                                                </div>
                                                            )}
                                                            <div className={classes.flexCenter}>
                                                                <Tooltip style={{ alignSelf: "flex-start" }} title={"Status"}>
                                                                    <Icon
                                                                        name={"Star"}
                                                                        color={code === 200 ? "currentColor" : "danger"}
                                                                        style={{ marginRight: 4 }}
                                                                    />
                                                                </Tooltip>
                                                                <Typography
                                                                    style={{ whiteSpace: "normal" }}
                                                                    color={code === 200 ? "default" : "danger"}
                                                                    type={"h5"}
                                                                >
                                                                    {status}
                                                                </Typography>
                                                            </div>
                                                        </div>
                                                    </AccordionSummary>
                                                    <AccordionDetails style={{ fontSize: 14 }}>
                                                        <div className={classes.btnCopy}>
                                                            <Tooltip title={"Copy SQL"}>
                                                                <ButtonIcon
                                                                    viewType={"text"}
                                                                    size={"tiny"}
                                                                    name={"Copy"}
                                                                    disabled={!sql}
                                                                    onClick={() => onCopy(JSON.stringify(sql, null, 4))}
                                                                />
                                                            </Tooltip>
                                                        </div>
                                                        <JSONTree data={item} theme={theme} hideRoot />
                                                    </AccordionDetails>
                                                </Accordion>
                                            );
                                        })}
                                    </AccordionGroup>
                                </TabPanel>
                            );
                        })}
                    </TabContainer>
                </div>
            )}
        </div>
    );
});

export default Debugger;
