import React, { Component } from 'react';
import { FormHelperText }   from "@material-ui/core";
import InputLabel           from "@material-ui/core/InputLabel";
import FormControl          from "@material-ui/core/FormControl";
import Config               from '../../../config/index';
import InlineSVG            from 'react-inlinesvg'
import PropTypes            from "prop-types";
import UserImage            from "../user/user-image";
class CbEmployees extends Component {
    itemRenderCbEmployees = (e) => {
        const {valueExpr, displayExpr, avatarHover, hiddenName, fieldRender} = this.props;
        if (fieldRender) return fieldRender(e);
        return (
            <div key={e[valueExpr]} className="display_row align-center" style={{ padding: '5px 5px 0 5px' }}>
                <div className="display_row align-center">
                    <UserImage style={{marginRight: 0}}
                               width={30} height={30} data={e} allowHover={!!avatarHover}/>
                </div>
                {!hiddenName && <span style={{ paddingLeft: 5, paddingRight: 5 }}> {e[displayExpr]} </span>}
            </div>
        )
    };
    render() {
        const {
            label, style, margin, error, helperText, disabled, InputLabelProps, required, loading, value, onClick,
            className
        } = this.props;
        let disabledColor = disabled ? '1px solid #8F9BB3' : '1px solid #222B45';
        return (
            <div>
                <FormControl
                    error={!!error && !disabled && (Array.isArray(value) ? value.length <= 0 : Config.isEmpty(value, true))}
                    className={className}
                    disabled={disabled || loading}
                    margin={margin ? margin : "normal"}
                    onClick={disabled ? '' : onClick}
                    fullWidth={true} style={style}>
                    {label &&
                        <InputLabel
                            required={required}
                            shrink={true}
                            disabled={disabled || loading}
                            {...InputLabelProps}
                        >{label}</InputLabel>}
                    <div style={{ borderBottom: disabledColor, justifyContent: 'space-between', alignItems: 'flex-end', paddingTop: 20, display: 'flex' }}>
                        <div className="display_row align-center" style={{ width: '100%' }}>
                            {value.length > 0 && value.map(rs => (this.itemRenderCbEmployees(rs)))}
                        </div>
                        <InlineSVG className={'btn-icon-svg'} src={require('../../../assets/images/general/arrow_down.svg')} />
                    </div>

                    <FormHelperText>{typeof error === "string" && (Array.isArray(value) ? value.length <= 0 : Config.isEmpty(value, true)) ? error : helperText}</FormHelperText>
                </FormControl>
            </div>
        );
    }
}

CbEmployees.propTypes = {
    valueExpr: PropTypes.string,
    displayExpr: PropTypes.string,
    avatarHover: PropTypes.bool,
    hiddenName: PropTypes.bool,
    label: PropTypes.string,
    style: PropTypes.object,
    margin: PropTypes.any,
    error: PropTypes.any,
    helperText: PropTypes.string,
    disabled: PropTypes.bool,
    InputLabelProps: PropTypes.object,
    required: PropTypes.bool,
    loading: PropTypes.bool,
    value: PropTypes.any,
    className: PropTypes.string,

    onClick: PropTypes.func,
    fieldRender: PropTypes.func
};

export default CbEmployees;
