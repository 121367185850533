/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 11/20/2019
 * @updated QUOCUY|13/09/2021
 * @Example
 */

import React, { PureComponent } from 'react';

import PropTypes from "prop-types";
import Config from '../../../config/index';
import { Typography, ButtonIcon } from "diginet-core-ui/components";
import {makeStyles, useTheme} from "diginet-core-ui/theme";
const {spacing, colors} = useTheme();
const useStyles = makeStyles(theme => ({
    root: {
        position: "relative",
        padding: spacing([1,0]),
        display: "flex",
        gap: spacing(2),
        width: '100%',
        flex: 1,
        flexWrap: "wrap",
        [theme.breakpoints.down("sm")]: {
            padding: spacing([3,0]),
            gap: spacing([3,2]),
        }
    },
    onTop: {
        position: 'fixed',
        zIndex: 240,
        backgroundColor: colors.system.white,
    },
    onTopDiv: {
        marginTop: '-15px',
    },
    fullWidth: {
        width: '100%',
    },
    pdToolbar: {
        padding: spacing([0,4]),
        [theme.breakpoints.up("xl")]: {
            padding: spacing([0,6]),
        }
    },
    pdToolbarMobile: {
        padding: spacing([3,4,0,4]),
    },
    actionToolbar: {
        overflow: "hidden",
        width: "100%",
        whiteSpace: "nowrap",
    },
    actionToolbarTitle: {
        float: "left",
        display: "flex",
        alignItems: "center",
        marginRight: spacing(6),
        whiteSpace: "pre-line",
    },
}));
class ActionToolbar extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            customHeight: 0,
            inPopup: false
        };
    }

    /**
     * Get list parent of Action Toolbar, list Parent has className is modal|tooltip|popup|popover => is inside modal|tooltip|popup|popover
     */
    isPopup = (el) => {
        let a = el;

        // classname-aware string
        const className = /modal|tooltip|popup|popover|dialog/;

        do{
            a = a.parentNode;
            // check action toolbar in modal|tooltip|popup
            if(a.className.toLowerCase().search(className) > -1){
                this.setState({ inPopup: true });
                break;
            }
        }
        while(a.nodeName !== 'BODY' && a.className.toLowerCase().search(className) === -1)
    };

    detectHeight = (el) => {
        const main = el.getBoundingClientRect();
        let prevValue = JSON.stringify(main);
        const handle = setInterval(() => {
            let nextValue = JSON.stringify(main);
            if (nextValue === prevValue) {
                clearInterval(handle);
                let height = main.height;
                this.setState({ customHeight: height });
            } else {
                prevValue = nextValue;
            }
        }, 50);
    };

    render() {
        let { allwaysTop } = this.props;
        const { upperCase, width, height, title, style, className, showBorder, onBack, children, alignment, endGroup } = this.props;
        const { customHeight, inPopup } = this.state;
        const classes = useStyles();

        // if allwaysTop is undefined => default true
        // if allwaysTop is undefined && Action Toolbar is inside Popup|Modal|PopupOver|Tooltip => false
        if(typeof allwaysTop === "undefined") allwaysTop = !inPopup;

        let border = "none";
        if (showBorder || typeof showBorder === "undefined") {
            border = `0.5px solid ${colors.line.category}`;
        }

        // custom style normal and allwaysTop
        let customStyle = {
            width: Config.isMobile ? '100%' : 'calc(100% - 80px)',
            maxWidth: Config.isMobile ? '100%' : 'calc(100% - 30px)',
            borderBottom: border,
            marginLeft: -15,
        };

        if(!allwaysTop){
            customStyle = {
                marginTop: -15,
                borderBottom: border
            }
        }

        return (
            <React.Fragment>
                <div className={`${allwaysTop ? classes.onTopDiv : classes.fullWidth}`}
                     style={{ height: allwaysTop && customHeight && !height ? customHeight : 'auto'}}>

                    <div className={`display_row align-center flex-wrap ${classes.actionToolbar} ${customHeight > 80 ? classes.pdToolbarMobile : classes.pdToolbar} ${className} ${allwaysTop ? classes.onTop : ''}`}
                         id={"action-toolbar"}
                         ref={el=>{
                             if (!el) return;

                             // if allwaysTop is undefined then check is inside modal|tooltip|popup|popover
                             if(typeof this.props.allwaysTop === 'undefined') this.isPopup(el);

                             this.detectHeight(el);
                         }}
                         style={{
                             ...customStyle,
                             ...style,
                             minHeight: spacing(14)
                         }}>

                        {(title || onBack) &&
                            <Typography
                                type={"h1"}
                                style={{ display:'flex' }}
                                uppercase={typeof upperCase === "undefined" || upperCase} // uppercase default true
                                className={`${classes.actionToolbarTitle}`}
                            >
                                {onBack &&
                                    <ButtonIcon
                                        circular
                                        viewBox
                                        viewType={"text"}
                                        name={"Back"}
                                        onClick={onBack}
                                    />
                                }
                                {title && title}
                            </Typography>
                        }
                        <div className="display_row" style={{flexGrow: 1}}>
                            {children &&
                                <div className={classes.root}
                                    style={{
                                        width: width, ...(!height ? { minHeight: 50 } : {height: height}),
                                        justifyContent: alignment ? alignment : 'flex-start'
                                    }}>
                                    {children}
                                </div>
                            }
                            {/* end group */}
                            {endGroup &&
                                <div className={classes.root}
                                    style={{
                                        width: width, ...(!height ? { minHeight: 50 } : {height: height}),
                                        justifyContent: alignment ? alignment : 'flex-end'
                                    }}>
                                    {endGroup}
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

ActionToolbar.propTypes = {
    className: PropTypes.string,
    style: PropTypes.any,
    title: PropTypes.string,
    height: PropTypes.any,
    width: PropTypes.any,
    showBorder: PropTypes.bool,
    upperCase: PropTypes.bool,
    alignment: PropTypes.oneOf(['flex-start', 'flex-end', 'center', 'space-around', 'space-between']),
    onBack: PropTypes.func,
    allwaysTop: PropTypes.bool,
    endGroup: PropTypes.element,
};

export default ActionToolbar;
