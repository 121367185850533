import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import React from "react";

class Popup extends React.Component {

    render(){
        const {props} = this;
        return(
            <OverlayTrigger ref={props.reference} placement="bottom"
                            rootClose
                            onEntered={props.onEntered}
                            onExited={props.onExited}
                            trigger="click"
                            overlay={
                                <Tooltip id="edit"
                                         bsClass={"fade in tooltip bottom "+ (props.className ? props.className : '')}
                                >
                                    {props.title && <div className="title-tooltip">
                                        {props.title.toUpperCase()}
                                    </div>}
                                    {props.renderItem && props.renderItem()}
                                    {props.footer && <div className="footer-tooltip">
                                        {props.footer}
                                    </div>}
                                </Tooltip>}
            >
                {props.renderCursor && props.renderCursor()}
            </OverlayTrigger>
        )
    }
}

export default Popup;
