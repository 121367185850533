import * as React from "react";
import PropTypes from "prop-types";
import { CircularProgress } from "diginet-core-ui/components";
import { makeStyles, useTheme } from "diginet-core-ui/theme";

const { zIndex } = useTheme();

const useStyles = makeStyles(() => ({
    loadingShadingMui: {
        position: "absolute",
        top: 0,
        left: 0,
        zIndex: zIndex(100),
        background: "rgba(255, 255, 255, 0.3)",
    },
    fullScreen: {
        width: "100%",
        height: "100%",
    },
    fixed: {
        position: "fixed",
    },
}));

export const Loading = ({ className, visible, fixed, ...rest }) => {
    const classes = useStyles();
    if (!visible) return null;
    return (
        <div className={`${classes.loadingShadingMui} ${classes.fullScreen} ${className} ${fixed && classes.fixed}`}>
            <div className={`display_row align-center valign-middle ${classes.fullScreen}`}>
                <CircularProgress color={"primary"} {...rest} />
            </div>
        </div>
    );
};

Loading.defaultProps = {
    visible: true,
    className: "",
    fixed: false,
};

Loading.propTypes = {
    visible: PropTypes.bool,
    className: PropTypes.string,

    // show loading full screen with position: fixed
    fixed: PropTypes.bool,
    size: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.oneOf(['xl', 'lg', 'md', 'sm', 'xs', 'extraLarge', 'large', 'medium', 'small', 'extraSmall'])]),
};
