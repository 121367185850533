
/**
 * @copyright 2020 @ DigiNet
 * @author CANHTOAN
 * @create 07/23/2020
 * @Example
 */

import React, { Component } from 'react';
import PropTypes from "prop-types";
import { DateRange } from 'react-date-range';
import { vi, en } from 'react-date-range/dist/locale';
import { TextField } from "@material-ui/core";
import { Box } from "@material-ui/core";
import moment from 'moment';
import Config from '../../../config';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const styles = {
    dateRange: {
        zIndex: 10,
        position: 'absolute',
        top: 45
    },
    icon: {
        position: 'absolute',
        top: 20,
        right: 12,
        display: 'flex'
    }
}

class DateRangeBox extends Component {
    constructor(props) {
        super(props);

        this.state = {
            DateRangeForm: [
                {
                    startDate: new Date(),
                    endDate  : new Date(),
                    key      : 'selection'
                }
            ],
            displayDate: '',
            showDateRange: false,
        }
        
        this.DateRangeRef = React.createRef();
    }

    clearDateRange = () => {
        this.setState({
            DateRangeForm: [{
                startDate: null,
                endDate: null,
                key: 'selection'
            }],
            displayDate: '',
        }, () => {
            const { onChange, onClose } = this.props;
            if (onChange) onChange(this.state.DateRangeForm);
            if (onClose) onClose(this.state.DateRangeForm);
        });
    }

    onChange = e => {
        if (!e || (e.target && !e.target.value.includes('-'))) return;
        let DateRangeForm = [];
        let displayDate = '';
        if (e.target) {
            let value = e.target.value;
            if (this.props.textInput) {
                value = value.replace(this.props.textInput, '');
            }
            const date = value.split('-');
            date[0] = date[0].trim();
            date[1] = date[1].trim();
            const startDateValid = moment(date[0], 'DDMMYYYY').isValid() && moment(date[0], 'DDMMYYYY').get('y') > 1900;
            const endDateValid = moment(date[1], 'DDMMYYYY').isValid() && moment(date[1], 'DDMMYYYY').get('y') > 1900;
            let startDate = startDateValid ? moment(date[0], 'DDMMYYYY') : date[0];
            let endDate = endDateValid ? moment(date[1], 'DDMMYYYY') : date[1];

            // Compare startDate with endDate
            if (startDateValid && endDateValid && startDate.diff(endDate) > 0) {
                const { displayDate } = this.state;
                const oldDate = displayDate.split('-');
                if (date[0] === oldDate[0].trim()) {
                    startDate = endDate;
                } else {
                    endDate = startDate;
                }
            }

            DateRangeForm = [{
                startDate : startDateValid ? new Date(startDate.get('y'), startDate.get('month') + 1, startDate.get('date')) : null,
                endDate   : endDateValid   ? startDateValid ? new Date(endDate.get('y'), endDate.get('month') + 1, endDate.get('date')) : null : null,
                key       : 'selection'
            }];
            displayDate = e.target.value.trim();
        } else {
            DateRangeForm = [e];
            displayDate = `${moment(e.startDate).format('DD/MM/YYYY')} - ${moment(e.endDate).format('DD/MM/YYYY')}`
        }

        this.setState({
            DateRangeForm,
            displayDate
        })
        const { onChange } = this.props;
        if ( onChange ) {
            onChange(DateRangeForm);
        }
    }

    onInputIn = (isIcon) => {
        if (!this.state.showDateRange || isIcon) {
            this.setState({showDateRange: isIcon ? !this.state.showDateRange : true});
            document.addEventListener('click', this.handleClickOutside);
            document.addEventListener('keydown', this.handlePressEsc);
        }
    }

    onInputOut = e => {
        if (!e || !e.target || !e.target.value) return;
        const date = e.target.value.split('-');
        date[0]              = date[0].trim();
        date[1]              = date[1].trim();
        const startDateValid = moment(date[0], 'DDMMYYYY').isValid() && moment(date[0], 'DDMMYYYY').get('y') > 1900;
        const endDateValid   = moment(date[1], 'DDMMYYYY').isValid() && moment(date[1], 'DDMMYYYY').get('y') > 1900;
        if (!startDateValid || !endDateValid) return;
        const startDate      = moment(date[0], 'DDMMYYYY');
        const endDate        = moment(date[1], 'DDMMYYYY');
        if (startDate.diff(endDate) > 0) {
            const { DateRangeForm } = this.state;
            this.setState({
                displayDate: moment(DateRangeForm[0].startDate).format('DD/MM/YYYY') + ' - ' + moment(DateRangeForm[0].endDate).format('DD/MM/YYYY')
            });

            const { onChange } = this.props;
            if (onChange) onChange(DateRangeForm);
        }
    }

    handleClickOutside = (event) => {
        if (this.DateRangeRef && this.DateRangeRef.current && !this.DateRangeRef.current.contains(event.target)) {
            this.setState({ showDateRange: false }, () => {
                const { onClose } = this.props;
                if (onClose) onClose(this.state.DateRangeForm);
                document.removeEventListener('click', this.handleClickOutside)
            });
        }
    }

    handlePressEsc = (event) => {
        if (event.key === 'Escape') {
            this.setState({ showDateRange: false }, () => {
                const { onClose } = this.props;
                if (onClose) onClose(this.state.DateRangeForm);
                document.removeEventListener('keydown', this.handlePressEsc)
            });
        }
    }

    componentDidMount = () => {
        let { value, ranges } = this.props;
        if (value || ranges) {
            if (value && value[0] && value[0].startDate && value[0].endDate) {
                ranges = value;
            }
            if (ranges && ranges[0] && ranges[0].startDate && ranges[0].endDate) {
                this.setState({
                    DateRangeForm: ranges,
                    displayDate: moment(ranges[0].startDate).format('DD/MM/YYYY') + ' - ' + moment(ranges[0].endDate).format('DD/MM/YYYY')
                });
            }
        }
    }

    componentDidUpdate(prevProps, _prevState) {
        // Check date ranges
        const key = this.props.ranges ? 'ranges' : 'value';
        if (prevProps[key][0].startDate !== this.props[key][0].startDate || prevProps[key][0].endDate !== this.props[key][0].endDate) {
            const startDate = this.props[key][0].startDate !== null ? moment(this.props[key][0].startDate).format('DD/MM/YYYY') : null;
            const endDate = this.props[key][0].endDate !== null ? moment(this.props[key][0].endDate).format('DD/MM/YYYY') : null;
            this.setState({
                DateRangeForm: this.props[key],
                displayDate: startDate && endDate ? startDate + ' - ' + endDate : ""
            })
        }
    }

    render() {
        const {error, variant, label, required, InputLabelProps, disabled, inputProps, margin, fullWidth, isInsideFilter,
            showSelectionPreview, moveRangeOnFirstSelection, showMonthAndYearPickers, dateDisplayFormat, monthDisplayFormat,
            className, rangeColors, shownDate, minDate, maxDate, disabledDates, scroll, showMonthArrow, navigatorRenderer,
            showPreview, showDateDisplay, startDatePlaceholder, endDatePlaceholder, months, direction, locale, showClearIcon,
            textInput, styleIcon, classDateRangeCont, classTextField, placeHolder
        } = this.props;

        const { displayDate, showDateRange, DateRangeForm } = this.state;
        return (
            <div ref={this.DateRangeRef} className={classDateRangeCont}>
                <TextField
                    error={error}
                    variant={variant}
                    label={label}
                    value={displayDate ? textInput + displayDate : ''}
                    required={required}
                    InputLabelProps={InputLabelProps}
                    disabled={disabled}
                    inputProps={inputProps}
                    margin={margin || "normal"}
                    onFocus={() => this.onInputIn()}
                    onBlur={e => this.onInputOut(e)}
                    onChange={e => this.onChange(e)}
                    fullWidth={fullWidth}
                    className={classTextField}
                    placeholder={placeHolder}
                />
                {/* <span><HighlightOffIcon/></span> */}
                <div style={{
                    ...styles.icon, pointerEvents: disabled ? 'none' : 'auto',
                    top: variant === "outlined" ? 32 : 20,
                    right: variant === "outlined" ? 26 : 12,
                    ...styleIcon
                }}>
                    {!disabled && <span onClick={this.clearDateRange}
                        className="dx-clear-button-area dx-show-clear-button"
                        style={{top: 10, display: showClearIcon && displayDate ? 'block' : 'none'}}>
                        <span className="dx-icon dx-icon-clear"/>
                    </span>}
                    <span onClick={() => this.onInputIn(1)} style={{ cursor: 'pointer', display: 'flex', marginTop: 3 }}>
                        <img src={require('../../../assets/images/icon-calendar.svg')} alt={''}/>
                    </span>
                </div>
                <Box
                    boxShadow={3}
                    style={{
                        display: showDateRange ? 'block' : 'none', ...styles.dateRange,
                        position: isInsideFilter ? 'fixed' : 'absolute'
                    }}
                >
                    <DateRange className={className}
                        onChange={item => this.onChange(item.selection)}
                        rangeColors={rangeColors || []}
                        shownDate={shownDate}
                        minDate={minDate}
                        maxDate={maxDate}
                        disabledDates={disabledDates || []}
                        scroll={scroll || { enabled: false }}
                        showMonthArrow={showMonthArrow !== 'undefined' ? showMonthArrow : true}
                        navigatorRenderer={navigatorRenderer}
                        showPreview={showPreview !== 'undefined' ? showPreview : true}
                        showDateDisplay={showDateDisplay !== 'undefined' ? showDateDisplay : true}
                        showSelectionPreview={showSelectionPreview !== 'undefined' ? showSelectionPreview : true}
                        moveRangeOnFirstSelection={moveRangeOnFirstSelection || false}
                        showMonthAndYearPickers={showMonthAndYearPickers !== 'undefined' ? showMonthAndYearPickers : true}
                        dateDisplayFormat={dateDisplayFormat || 'dd MMMM yyyy'}
                        monthDisplayFormat={monthDisplayFormat || 'MMMM yyyy'}
                        startDatePlaceholder={startDatePlaceholder || Config.lang('DHR_Thoi_gian_bat_dau')}
                        endDatePlaceholder={endDatePlaceholder || Config.lang('DHR_Thoi_gian_ket_thuc')}
                        months={months || 2}
                        ranges={DateRangeForm}
                        direction={direction || 'horizontal'}
                        locale={locale && locale === 'en' ? en : vi}
                    />
                </Box>
            </div>
        )
    }
}

DateRangeBox.propTypes = {
    showClearIcon: PropTypes.bool, // Default: false
    variant: PropTypes.string,
    value: PropTypes.array, // as ranges's prop
    label: PropTypes.string,
    required: PropTypes.bool,
    InputLabelProps: PropTypes.object, //{ shrink: true }
    disabled: PropTypes.bool,
    inputProps: PropTypes.object, //{min: 0}
    margin: PropTypes.string, // Default: 'normal'
    fullWidth: PropTypes.any,
    error: PropTypes.any,
    onChange: PropTypes.func,
    className: PropTypes.string,
    rangeColors: PropTypes.array,
    shownDate: PropTypes.any, // Date
    minDate: PropTypes.any, // Date
    maxDate: PropTypes.any, // Date
    disabledDates: PropTypes.array, // Default: []
    scroll: PropTypes.object, // Default: { enabled: false }
    showMonthArrow: PropTypes.bool, // Default: true
    navigatorRenderer: PropTypes.func,
    showPreview: PropTypes.bool, // Default: true
    showDateDisplay: PropTypes.bool, // Default: true
    showSelectionPreview: PropTypes.bool, // Default: true
    moveRangeOnFirstSelection: PropTypes.bool, // Default: false
    showMonthAndYearPickers: PropTypes.bool, // Default: true
    dateDisplayFormat: PropTypes.string, // Default: 'dd MMMM yyyy'
    monthDisplayFormat: PropTypes.string, // Default: 'MMMM yyyy'
    startDatePlaceholder: PropTypes.string, // Default: 'Thời gian bắt đầu'
    endDatePlaceholder: PropTypes.string, // Default: 'Thời gian kết thúc'
    months: PropTypes.number, // Default: 2
    ranges: PropTypes.array, // [{startDate: DateTime, endDate: DateTime, key: 'selection'}] -- 'selection' is default
    direction: PropTypes.string, // Default: 'horizontal'
    locale: PropTypes.string, // Default: 'vi'
    textInput: PropTypes.string,
    styleIcon: PropTypes.object,
    classDateRangeCont: PropTypes.string,
    onClose: PropTypes.func,
    classTextField: PropTypes.string,
    placeHolder: PropTypes.string,
    isInsideFilter: PropTypes.bool, // Default: false
};

DateRangeBox.defaultProps = {
    textInput: '',
    styleIcon: {},
    classDateRangeCont: '',
    onClose: null,
    classTextField: '',
    placeHolder: '',
};

export default DateRangeBox;