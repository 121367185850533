/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 6/8/2020
 * @Example
 */


import React from "react";
import propTypes from "prop-types";
import {Status as StatusCORE} from 'diginet-core-ui/components'

class Status extends React.Component {

    render() {
        const {data, color, icon, keyExpr, displayExpr , iconExpr, renderItem,className, style, colorExpr} = this.props;
        let _icon = icon ? icon : (data && data[iconExpr] ? data[iconExpr] : null);
        if (!_icon && keyExpr && data) {
            switch (Number(data[keyExpr])) {
                case 0:
                    _icon = "HourGlass";
                    break;
                case 1:
                    _icon = "Loading";
                    break;
                case 2:
                    _icon = "Approval";
                    break;
                case 4:
                case 100:
                    _icon = "Close";
                    break;
                case 5:
                    _icon = "Delete";
                    break;
                default:
                    break;
            }
        }

        let _color = color ? color : (data && data[colorExpr] ? data[colorExpr] : "");
        if (!_color && keyExpr && data) {
            switch (Number(data[keyExpr])) {
                case 0:
                case 1:
                    _color = "info";
                    break;
                case 2:
                    _color = "success";
                    break;
                case 4:
                case 100:
                    _color = "danger";
                    break;
                case 5:
                    _color = "warning";
                    break;
                default:
                    break;
            }
        }

        return (
            <div className={"fal-status display_row valign-middle" + (className ? " " + className : "")}
                 style={style}
            >
                {renderItem && (typeof renderItem === "function" ? renderItem(data) : renderItem)}
                {!renderItem &&
                    <React.Fragment>
                        <StatusCORE
                            color={_color}
                            icon={_icon}
                            text={data[displayExpr] || ""}
                        />
                    </React.Fragment>
                }
            </div>
        );
    }
}

Status.defaultProps = {
    keyExpr: "AppStatusID",
    displayExpr: "AppStatusName",
    iconExpr: "AppStatusIcon",
    colorExpr: "AppStatusColor",
    type: 'text',
};
Status.propTypes = {
    className: propTypes.any,
    style: propTypes.any,
    type: propTypes.oneOf(['text', 'outlined', 'default']),
    clickable: propTypes.bool,
    /**
     * data: {AppStatusID: "abc", AppStatusName: ""}
     */
    data: propTypes.any,
    color: propTypes.string,
    icon: propTypes.any,
    keyExpr: propTypes.string,
    displayExpr: propTypes.string,
    iconExpr: propTypes.string,
    colorExpr: propTypes.string,

    renderItem: propTypes.any,
    onClick: propTypes.func,
};

export default Status;
