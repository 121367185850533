/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 11/20/2019
 * @Example
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import InlineSVG from "react-inlinesvg";
import { Button } from 'diginet-core-ui/components';

class ButtonGeneral extends Component {

    getIconButton = () => {
        const { typeButton } = this.props;
        const listIcons = new Map([
            ['add','AddCircle'],
            ['notsave','SaveCancel'],
            ['excel','export'],
            ['approve','approval'],
            ['custom_edit','edit'],
            ['excel-no-boder','export'],
            ['confirm_list','ListApproval']
        ]);

        return {icon: listIcons.get(typeButton) || typeButton}
    };

    getButtonDefaultTypes = () => {
        const { typeButton, variant, color } = this.props;
        let _variant = variant, _color = color;
        switch (typeButton) {
            case "add":
                _color = color || "primary";
                _variant = variant || "contained";
                break;
            case "save":
                _color = color || "info";
                _variant = variant || "contained";
                break;
            case "edit":
            case "cancel":
            case "attachment":
            case "export":
            case "excel":
            case "pdf":
                _color = color || "primary";
                _variant = variant || "outlined";
                break;
            default:
                break;
        }

        return { color: _color, variant: _variant };
    };

    onClick = () => {
        const { loading } = this.props;
        if (this.props.onClick && !loading) this.props.onClick();
    };

    _getIcons = () => {
        let {icon, reverseIcon, startIcon, endIcon} = this.props;
        if (icon && typeof icon === "string") {
            icon = <InlineSVG className={'btn-icon-svg'} src={icon} style={{ width: 20, height: 20 }} />
        }
        if (!icon) {
            const _icon = this.getIconButton();
            icon = _icon.icon;
        }
        startIcon = startIcon || icon;
        if (reverseIcon) return {startIcon: endIcon, endIcon: startIcon};
        return {startIcon, endIcon};
    };

    _getViewType = (variant) => {
        const {viewType} = this.props;
        let _viewType = "";
        switch (variant || viewType) {
            case "custom":
            case "text":
                _viewType = "text";
                break;
            case "contained":
            case "filled":
                _viewType = "filled";
                break;
            default:
                _viewType = variant || viewType || "outlined";
                break;

        }
        return {viewType: _viewType};
    };

    _getSize = () => {
        const {size} = this.props;
        switch (size) {
            case "large":
                return "medium";
            case "medium":
                return "small";
            case "small":
                return "tiny";
            default:
                return size;
        }
    };

    render() {
        const { name, label, style, className, disabled, loading, btnProps } = this.props;
        const { variant, color } = this.getButtonDefaultTypes();
        const {viewType} = this._getViewType(variant);
        const {startIcon, endIcon} = this._getIcons();
        const size = this._getSize();

        return (
            <>
                <Button
                    {...btnProps}
                    disabled={disabled}
                    style={style}
                    className={className}
                    size={size}
                    color={color}
                    loading={loading}
                    startIcon={startIcon}
                    endIcon={endIcon}
                    label={name || label}
                    viewType={viewType}

                    onClick={() => this.onClick()}
                />
            </>
        );
    }
}

ButtonGeneral.propTypes = {
    style: PropTypes.any,
    typeButton: PropTypes.string,
    disabled: PropTypes.bool,
    name: PropTypes.string,
    label: PropTypes.string,
    color: PropTypes.string,
    variant: PropTypes.string,
    viewType: PropTypes.string,
    className: PropTypes.string,
    iconProps: PropTypes.any,
    loading: PropTypes.bool,
    size: PropTypes.string,

    btnProps: PropTypes.object,
    onClick: PropTypes.func,
};

export default ButtonGeneral;
