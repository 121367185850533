/**
 * @copyright 2022 @ DigiNet
 * @author TRANGHOANG
 * @create 04/01/2022
 * @Example
 */

import React, { useEffect, useRef, useState, useMemo, Fragment } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Config from "../../../config";

import { Column } from "devextreme-react/data-grid";
import GridContainer from "../../grid-container/grid-container";
import * as generalActions from "../../../redux/general/general_actions";
import {
    Button,
    Icon,
    CircularProgress,
    Modal,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Popover,
    PopoverBody,
    PopoverFooter,
    Typography,
    ButtonIcon,
} from "diginet-core-ui/components";
import { useTheme, makeStyles } from "diginet-core-ui/theme";
const { spacing } = useTheme();

const useStyles = makeStyles(themes => ({
    box: {
        minWidth: spacing(7),
    },
}));

const ApprovalMulti = props => {
    const {
        column,
        title = Config.lang("Qua_trinh_duyet_hang_loat"),
        open,
        onClose,
        action,
        mode,
        FormID,
        keyExpr,
        dataSource,
        onConfirm,
    } = props;

    const [indexApproval, setIndexApproval] = useState(0);
    const [stop, setStop] = useState(false);
    const [dataSourceGrid, setDataSourceGrid] = useState(dataSource || []);

    const gridRef = useRef();
    const refPopover = useRef([]);

    const dispatch = useDispatch();
    const classes = useStyles();

    useEffect(() => {
        if (indexApproval >= dataSource.length || stop) return;
        setTimeout(() => approval(indexApproval), 200);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [indexApproval, stop]);

    useEffect(() => {
        setDataSourceGrid(dataSource);
    }, [dataSource]);

    const approval = idxItem => {
        const item = dataSourceGrid[idxItem];
        if (!item) return;
        const params = {
            ...item,
            FormID: FormID,
            Num01: +!mode,
            Num02: mode,
            Mode: mode + 1,
            Key01ID: item[keyExpr],
        };
        dispatch(
            action.approval(params, (err, dataApproval) => {
                const temp = [...dataSourceGrid];
                temp[idxItem] = {
                    ...temp[idxItem],
                    Status: dataApproval.Status ?? 1,
                    MsgAsk: dataApproval.MsgAsk ?? 0,
                    Message: dataApproval?.Message ?? dataApproval?.message,
                };
                if (dataApproval?.code) temp[idxItem].Code = dataApproval?.code || "";
                setDataSourceGrid(temp);

                // if (idxItem + 1 < dataSource.length) {
                setIndexApproval(indexApproval + 1);
            })
        );
    };

    const confirmApproval = e => {
        const { rowIndex } = e;
        const data = dataSourceGrid[rowIndex];
        const params = {
            ...data,
            FormID: FormID,
            Approval: +!mode,
            NotApproval: mode,
            TransID: data[keyExpr],
            code: data.Code,
            Mode: mode + 1,
        };
        dispatch(
            action.confirmApproval(params, (err, dataApproval) => {
                const temp = [...dataSourceGrid];
                temp[rowIndex] = {
                    ...temp[rowIndex],
                    Status: dataApproval.Status,
                    MsgAsk: dataApproval.MsgAsk,
                    Message: dataApproval?.Message ?? dataApproval?.message,
                };
                setDataSourceGrid(temp);
            })
        );
    };

    const _onClose = () => {
        if (indexApproval < dataSourceGrid.length) {
            setStop(true);
            Config.popup.show(
                "YES_NO",
                Config.lang("Con_phieu_dang_trong_qua_trinh_duyet._Ban_co_chac_muon_dung_lai_khong?"),
                // YES
                () => {
                    onClose && onClose();
                },
                //NO
                () => {
                    setStop(false);
                },
                // CLOSE
                () => {
                    setStop(false);
                }
            );
        } else {
            onClose && onClose();
        }
    };

    const renderStatus = e => {
        const { Status, MsgAsk, Message } = e?.data || {};
        const { rowIndex } = e;

        let nameIcon = "";
        let color = "";

        switch (Status) {
            case -1:
                //netword error
                nameIcon = "Refresh";
                color = "danger";
                break;
            case 0:
                //success
                nameIcon = "ApprovalFilled";
                color = "success";
                break;
            case 1:
                if (MsgAsk === 1) {
                    // confirm approval
                    nameIcon = "Warning";
                    color = "warning";
                } else {
                    // fail
                    nameIcon = "CancelFilled";
                    color = "red";
                }
                break;
            default:
                nameIcon = "Loading";
                color = "";
                break;
        }
        return (
            <div className={"display_row align-center valign-middle"}>
                {nameIcon === "Loading" ? (
                    <CircularProgress size={24} color={"info"} />
                ) : nameIcon === "Refresh" ? (
                    <ButtonIcon
                        color={"red"}
                        circular
                        viewType={"text"}
                        name={nameIcon}
                        onClick={() => {
                            gridRef.current.instance.cellValue(e.rowIndex, "Status", null);
                            gridRef.current.instance.saveEditData();
                            confirmApproval(e);
                        }}
                    />
                ) : (
                    <Popover
                        ref={ref => {
                            refPopover.current[rowIndex] = ref;
                        }}
                        // open={nameIcon==="ApprovalFilled" ? false : undefined}
                        width={480}
                        anchor={
                            <Icon
                                name={nameIcon}
                                color={color}
                                // onClick={() => this.onCopyRow(e)}
                            />
                        }
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                        transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                        }}
                    >
                        <PopoverBody>
                            <div className={"display_row"}>
                                <Icon width={40} height={40} name={nameIcon} color={color} className={"mgr4x"} />
                                <Typography style={{ display: "flex" }} className={"align-center"}>
                                    {nameIcon === "ApprovalFilled"
                                        ? mode === 0
                                            ? Config.lang("Duyet_thanh_cong")
                                            : Config.lang("Tu_choi_thanh_cong")
                                        : Message}
                                </Typography>
                            </div>
                        </PopoverBody>
                        {nameIcon === "Warning" && (
                            <PopoverFooter boxShadow={false}>
                                <Button
                                    label={Config.lang("Co")}
                                    viewType={"filled"}
                                    color={"warning"}
                                    className={"mgr2x"}
                                    onClick={() => {
                                        confirmApproval(e);
                                    }}
                                />
                                <Button
                                    label={Config.lang("Khong")}
                                    viewType={"outlined"}
                                    color={"warning"}
                                    onClick={() => {
                                        refPopover.current[rowIndex].close();
                                    }}
                                />
                            </PopoverFooter>
                        )}
                    </Popover>
                )}
            </div>
        );
    };

    const renderInfo = () => {
        const data = [
            {
                name: "Warning", //warning
                color: "warning",
                value: dataSourceGrid.filter(i => i.Status === 1 && i.MsgAsk === 1)?.length ?? 0,
            },
            {
                name: "CancelFilled", //fail
                color: "danger",
                value: dataSourceGrid.filter(i => i.Status === 1 && i.MsgAsk === 0)?.length ?? 0,
            },
            {
                name: "ApprovalFilled", //success
                color: "success",
                value: dataSourceGrid.filter(i => i.Status === 0)?.length ?? 0,
            },
            {
                value: `${Config.lang("Tong")}: ${dataSourceGrid?.length ?? 0}`, //tổng
            },
        ];
        return (
            <div className={"display_row align-center valign-bottom mgb4x"}>
                {/*loading*/}
                <CircularProgress className={"mgr1x"} size={24} color={"info"} />
                <Typography className={`mgr2x ${classes.box}`}>
                    {dataSourceGrid.filter(i => !Number.isInteger(i.Status))?.length ?? 0}
                </Typography>

                {data.map((item, idx) => {
                    return (
                        <Fragment key={idx}>
                            {item.name && <Icon className={"mgr1x"} name={item.name} color={item.color} size={24} />}
                            <Typography className={`mgr2x ${classes.box}`}>{item.value}</Typography>
                        </Fragment>
                    );
                })}
            </div>
        );
    };

    return (
        <Modal open={open} pressESCToClose={false}>
            <ModalHeader showClose={false} className={"display_row align-center justify-between"}>
                {title}
                <ButtonIcon name={"Close"} onClick={_onClose} viewType={"ghost"} />
            </ModalHeader>
            <ModalBody>
                {renderInfo()}
                {useMemo(
                    () => (
                        <GridContainer
                            dataSource={dataSourceGrid}
                            pagerFullScreen={false}
                            reference={ref => (gridRef.current = ref)}
                            keyExpr={keyExpr}
                            paging={{ pageSize: dataSourceGrid.length, enabled: false }}
                            pager={{ visible: false }}
                        >
                            {column?.map((item, idx) => (
                                <Column {...item} key={idx} />
                            ))}
                            <Column dataField={"Status"} visible={false} />
                            <Column dataField={"MsgAsk"} visible={false} />
                            <Column dataField={"Message"} visible={false} />
                            <Column dataField={"Code"} visible={false} />
                            <Column
                                caption={Config.lang("Trang_thai")}
                                alignment={"center"}
                                cellRender={renderStatus}
                            />
                        </GridContainer>
                    ),
                    // eslint-disable-next-line react-hooks/exhaustive-deps
                    [dataSourceGrid]
                )}
            </ModalBody>
            <ModalFooter>
                <Button
                    disabled={indexApproval < dataSourceGrid.length}
                    onClick={onConfirm}
                    viewType={"filled"}
                    color={"info"}
                    label={Config.lang("Xac_nhan")}
                />
            </ModalFooter>
        </Modal>
    );
};

ApprovalMulti.defaultProps = {
    column: [],
    dataSource: [],
    keyExpr: "TransID",
    mode: 0,
    action: {
        approval: generalActions.approval,
        confirmApproval: generalActions.confirmApproval,
    },
};

ApprovalMulti.propTypes = {
    open: PropTypes.bool.isRequired,
    /* mode = 0 là duyệt, = 1 là từ chối */
    mode: PropTypes.oneOfType([
        PropTypes.oneOf([0,1]),
    ]),
    column: PropTypes.array.isRequired,
    dataSource: PropTypes.array.isRequired,
    title: PropTypes.string,
    onClose: PropTypes.func,
    onConfirm: PropTypes.func.isRequired,
    keyExpr: PropTypes.string.isRequired,
    action: PropTypes.shape({
        approval: PropTypes.func,
        confirmApproval: PropTypes.func,
    }),
    FormID: PropTypes.string.isRequired,
};

export default ApprovalMulti;
