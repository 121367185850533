/**
 * @copyright 2019 @ DigiNet
 * @author THANH TRAM
 * @create 2017/06/26 13:00
 * @update 2017/06/26 13:00
 */

import React, {Component} from 'react';
import {Image} from "react-bootstrap";
import Config from "../../config";

export default class ModuleError extends Component {


    render() {

        return (
            <div className="page-container align-center valign-middle wrap"
                 id="loading-Error500"
                 style={{backgroundColor:'#FFFBFF', flexDirection:'row', height:'100vh', position:'fixed', top:50, zIndex:791, margin:0, marginLeft: -15, width: '100%'}}>
                <Image className="col-lg-6 col-md-6 col-sm-12 col-xs-12" src={require('../../assets/images/errorr500.png')} />
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 display_col align-left">
                    <div className="loading-title">
                        <label>Kết nối đã mất quá nhiều thời gian</label>
                    </div>
                    <div className="loading-content" style={{float: 'left', padding:'5px'}}>
                        <span style={{padding:'5px', fontSize:'16px'}} className={''}>Máy chủ tại <a href={Config.env.url}>{Config.env.url}</a> phản hồi quá lâu</span>
                        <li className={'nav-item pd5'}>
                            <span>Trang web này có thể bị gián đoạn tạm thời hoặc do quá tải. Hãy thử lại trong chốc lát.</span>
                        </li>
                        <li className={'nav-item pd5'}>
                            <span>Nếu bạn không thể mở bất cứ trang nào, hãy kiểm tra kết nối mạng.</span>
                        </li>
                        <li className={'nav-item pd5'}>
                            <span>Nếu máy tính hoặc mạng của bạn được bảo vệ bởi tường lửa hoặc proxy, hãy chắc rằng trình duyệt được truy cập web.</span>
                        </li>
                    </div>
                </div>
            </div>
        )
    }
}
