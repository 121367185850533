/**
 * @copyright 2022 @ DigiNet
 * @author TRANGHOANG
 * @create 04/01/2022
 * @Example
 */

import React, { useEffect, useImperativeHandle, useRef, useState, forwardRef, useMemo } from "react";
import { useDispatch } from "react-redux";
import {
    ButtonIcon,
    Typography,
    Icon,
    Row,
    Col,
    Divider,
    Button,
    Popover,
    PopoverBody,
    TextInput,
    Tooltip,
} from "diginet-core-ui/components";
import { makeStyles, useColor } from "diginet-core-ui/theme";
import PropTypes from "prop-types";
import Config from "../../../config";
import GridContainer from "../../grid-container/grid-container";
import * as generalActions from "../../../redux/general/general_actions";
import { Column } from "devextreme-react/data-grid";
import Status from "../../common/status/status";
import W84F3005 from "../../W8X/W84/W84F3005/W84F3005";
import { browserHistory } from "react-router";
import ActionToolbar from "../../common/toolbar/action-toolbar";
import UserImage from "../../common/user/user-image";
import UserName from "../../common/user/user-name";
import Filter from "../../filter/filter";

const useStyles = makeStyles(theme => ({
    row: {
        display: "flex !important",
        whiteSpace: "pre !important",
    },
    paddingVertical: theme.spacing([4, 0]),
}));

const Index = forwardRef((props, ref) => {
    const {
        renderInfo,
        renderEmployee,
        perPage,
        isLoadPermission,
        onLoadedGrid,
        action,
        FormID,
        onAdd,
        actionToolbar,
        title,
        renderHeader,
        onApproval,
        renderFilter: renderFilterProps,
        onLoadCboFilter,
        keyExpr,
        filterProps,
        convertData,
        onChangePermission,
        renderAction,
        col,
        ...rest
    } = props;

    const dispatch = useDispatch();
    const classes = useStyles();

    const [showW84F3005, setShowW84F3005] = useState(false);
    const [permission, setPermission] = useState(null);
    const [dataGrid, setDataGrid] = useState(null);
    const [dataStatus, setDataStatus] = useState([]);
    const [loading, setLoading] = useState(false);

    const toolFilter = useRef(null);

    const filter = useRef({
        skip: 0,
        limit: perPage,
        FormID,
    });

    const listBtnSpecial = useMemo(
        () => [
            {
                name: "Approval",
            },
            {
                name: "Reject",
            }
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [renderAction]
    );

    /**
     * Load permission
     */
    useEffect(() => {
        loadPermission();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Load Grid after load permission success and permission > 0
     */
    useEffect(() => {
        if (permission) {
            if (action.loadStatus) {
                const param = {
                    FormID: FormID,
                    Language: Config.language || 84,
                };

                dispatch(
                    action.loadStatus(param, (err, dataStatus) => {
                        if (err) Config.popup.show("ERROR", err);
                        setDataStatus(dataStatus);
                        loadGrid();
                    })
                );
            } else {
                loadGrid();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [permission]);

    /**
     * call props onLoadedGrid() after func loadGrid success
     */
    useEffect(() => {
        onLoadedGrid && onLoadedGrid(dataGrid);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataGrid]);

    useImperativeHandle(ref, () => ({
        // instance: gridRef.current.instance,
        loadGrid: params => {
            loadGrid(params, true);
        },
    }));

    const getBtnActionList = (e, key) => {
        let btnActions = renderAction?.(e) || {};
        Object.keys(btnActions).forEach(key => {
            if (btnActions[key] === true) btnActions[key] = { isShow: true };
            if (btnActions[key]?.["isShow"] === undefined) btnActions[key]["isShow"] = true;
            if (e && key === "History") btnActions[key].onClick = () => _toggleW84F3005(e);
            else if (e && key === "Delete") btnActions[key].onClick = () => _confirmDelete(e);
        });
        return btnActions[key] || btnActions;
    };

    /**
     * Show popup confirm before Delete
     * @param data {object} data row
     * @private
     */
    const _confirmDelete = e => {
        Config.popup.show("YES_NO", Config.lang("Ban_co_chac_muon_xoa?"), () => {
            _onDelete(e);
        });
    };

    /**
     * Delete the row
     * @param dt {object} data row
     * @return {*}
     */
    const _onDelete = e => {
        const { data } = e.row;
        let btnDelete = getBtnActionList(e, "Delete") || {};
        if (!data || !btnDelete?.action) return false;
        let params = {
            FormID: FormID,
            ...data,
        };
        dispatch(
            btnDelete.action(params, (errors, dataDelete) => {
                if (errors) {
                    Config.popup.show("ERROR", errors);
                    return false;
                }
                if (dataDelete) {
                    if (dataDelete.Status === 1) {
                        Config.popup.show("INFO", dataDelete.Message);
                        return false;
                    } else {
                        Config.notify.show("success", Config.lang("Xoa_thanh_cong"), 2000);
                        loadGrid && loadGrid();
                    }
                }
            })
        );
    };

    /**
     * action click Avatar
     * @param dataProfile
     * @private
     */
    const _onProfile = dataProfile => {
        browserHistory.push({
            pathname: Config.getRootPath() + "W09F2000",
            state: { EmployeeID: dataProfile.EmployeeID },
        });
    };

    /**
     * render Column Profile
     * @param e.row.data {object}
     * @return {*}
     * @private
     */
    const _renderEmpProfile = e => {
        const { data: dataRow } = e.row;

        const dataEmployee = Config.getUser({
            EmployeeID: dataRow?.EmployeeID,
        });

        // [is Function] render function
        if (renderEmployee instanceof Function) {
            return <div className={classes.paddingVertical}>{renderEmployee(e)}</div>;
            // [is Object] render from data is object
        } else {
            return (
                <Row className={`align-center pdt10 pdb10 ${classes.paddingVertical}`}>
                    <Col style={{ maxWidth: 80 }}>
                        <UserImage
                            data={dataEmployee}
                            width={64}
                            height={64}
                            onClick={() => _onProfile(dataEmployee)}
                        />
                    </Col>
                    <Col>
                        <Typography type={"h3"}>
                            <UserName data={dataEmployee} />
                        </Typography>
                        <div className={"display_row align-center mgt1x"}>
                            <Icon name={"Calendar"} className={"mgr1x"} />
                            <Typography type={"p2"} color={useColor.text.sub}>
                                {Config.convertDate(dataRow?.CreateDate, null, "DD/MM/YYYY, HH:mm:ss")}
                            </Typography>
                        </div>
                    </Col>
                </Row>
            );
        }
    };

    /**
     * Render column Info
     * [is Function] render function
     * [is Object] render UI from data is object ([key]: data[key])
     * [is String] render one line from key (default field is Notes).
     * @param e {object}
     * @return {*}
     * @private
     */
    const _renderInfo = e => {
        const { data: dataInfo } = e.row;
        // [is Function] render function
        if (renderInfo instanceof Function) {
            return <div className={classes.paddingVertical}>{renderInfo(e)}</div>;
            // [is Object] render from data is object
        } else if (renderInfo instanceof Object) {
            return _renderInfoObject(dataInfo, renderInfo);
            // [is String] render one line from key (default field is Notes).
        } else if (renderInfo) {
            return (
                <Typography lineClamp={3} hoverTooltip className={classes.paddingVertical}>
                    {dataInfo?.[renderInfo] || dataInfo?.Note}
                </Typography>
            );
        }
    };

    /**
     * render UI Info with data is Object
     * @param dataInfo {object} data row
     * @param dataInfoObject {object} data object includes {key: value}
     * @return {*}
     * @private
     */
    const _renderInfoObject = dataInfo => {
        return (
            <>
                {Object.keys(renderInfo).map(key => {
                    if (!dataInfo?.[renderInfo?.[key]]) return null;
                    return (
                        <div className={"display_row"} key={key}>
                            <Typography type={"h3"} className={classes.row}>
                                {key || " "}:
                            </Typography>
                            <Typography className={"mgl1x"} lineClamp={1} hoverTooltip>
                                {dataInfo[renderInfo[key]] ?? ""}
                            </Typography>
                        </div>
                    );
                })}
            </>
        );
    };
    /**
     * render Column Status
     * @param e.row.data {object}
     * @return {*}
     * @private
     */
    const _renderAppStatus = e => {
        const { data: dataAppStatus } = e.row;
        const status =
            dataStatus?.find(rs => Number(rs.AppStatusID) === Number(dataAppStatus.AppStatusID)) || dataStatus;
        return <Status className={"valign-top"} data={status} />;
    };

    const _toggleW84F3005 = e => {
        const { data } = e?.row || {};
        setShowW84F3005({
            isShowing: !!data,
            TransID: data?.[keyExpr] || null,
        });
    };

    /**
     * Update filter load grid
     * @param page
     * @param per
     */
    const updateFilter = (page, per, isReset) => {
        if (page || page === 0) {
            filter.current.skip = page * filter.current.limit;
        } else if (per) {
            filter.current.skip = 0;
            filter.current.limit = per;
        }
        loadGrid && loadGrid(null, isReset);
    };

    /**
     * load data Grid
     * @param params {object} params use in action load grid left.
     * @param isReset {boolean} reload page to first page.
     */
    const loadGrid = (params = {}, isReset) => {
        if (isReset) {
            filter.current.skip = 0;
        }

        filter.current = { ...filter.current, ...params };

        setLoading(true);
        const callbackSave = (errors, dataCb) => {
            if (errors) {
                Config.popup.show("ERROR", errors);
            }
            setLoading(false);
            if (convertData instanceof Object) {
                dataCb.rows = dataCb.rows.map(i => {
                    const item = { ...i };
                    Object.keys(convertData).forEach(key => {
                        item[convertData[key]] = item[key];
                    });
                    return item;
                });
            }
            const dataNew = { total: dataCb?.total ?? dataCb?.length ?? 0, rows: dataCb?.rows || dataCb || [] };
            setDataGrid(dataNew);
        };

        dispatch(action.loadGrid({ ...filter.current }, callbackSave));
    };

    useEffect(() => {
        onChangePermission && onChangePermission(permission);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [permission]);

    /**
     * load permission screen
     */
    const loadPermission = () => {
        if (!isLoadPermission) setPermission(4);
        else
            dispatch(
                generalActions.getPermission(FormID, isPer => {
                    setPermission(isPer);
                })
            );
    };

    /**
     * Check props is function
     * @param func - {function}
     * @param def - {function}
     * @param params {object}
     * @return {function}
     */
    // const checkProps = (propName, def, params) => {
    //     if (propName instanceof Function) return propName(params);
    //     else if (def instanceof Function) return def(params);
    //     else return def;
    // };

    /**
     * render Column Button Action
     * @param e.row.data {object}
     * */

    const _renderAction = e => {
        const { data: dataAction } = e.row;
        const btnActionList = getBtnActionList(e);
        return (
            <div className={"display_row align-center valign-bottom"}>
                {Object.keys(btnActionList).map(key => {
                    const { action, isShow, tooltip, ...rest } = btnActionList[key];
                    if (!isShow || listBtnSpecial.find(i => i.name === key)) return null;
                    return (
                        <Tooltip title={tooltip || ""} key={key}>
                            <ButtonIcon
                                className={"mgr5"}
                                circular={true}
                                viewType={"text"}
                                name={key}
                                onClick={() => action?.(e)}
                                {...rest}
                            />
                        </Tooltip>
                    );
                })}
                {(btnActionList.Approval?.isShow || btnActionList.Reject?.isShow) && (
                    <PopoverApproval
                        anchor={[
                            btnActionList.Approval?.isShow && "Approval",
                            btnActionList.Reject?.isShow && "Reject",
                        ]}
                        onSubmit={onApproval}
                        data={dataAction}
                        keyExpr={keyExpr}
                    />
                )}
            </div>
        );
    };

    /**
     * render Row with Mobile Display
     * */
    const _renderMobile = e => {
        return (
            <Row className={"valign-bottom align-right"}>
                <Col xs={6}>{_renderEmpProfile(e)}</Col>
                <Col xs={6}>
                    {_renderInfo(e)}
                    <Divider className={"mgt4x"} />
                    <div className={"display_row align-between flex-wrap pdt2x"}>
                        {_renderAppStatus(e)}
                        {_renderAction(e)}
                    </div>
                </Col>
            </Row>
        );
    };

    /**
     * onChange Filter on Header
     * @param key {string}
     * @param e {object}
     * @param type {string}
     * */
    const filterChange = (key, e, type) => {
        const value = e?.value ?? e?.data?.value ?? e?.target?.value ?? e;
        switch (type) {
            case "Date":
                filter.current[`${key}From`] = value?.[0] ?? "";
                filter.current[`${key}To`] = value?.[1] ?? "";
                break;
            case "Checkbox":
                filter.current[key] = e.target.checked;
                break;
            default:
                filter.current[key] = value;
                break;
        }
    };

    /**
     * reload Grid when click the Search button on the Filter in the Header Page
     * @private
     */
    const _onSearch = () => {
        // loadGrid
        loadGrid(null, true);

        // close Dropdown Filter
        if (toolFilter.current) toolFilter.current.close();
    };

    /**
     * render Filter on Header
     * */
    const renderFilter = () => {
        return (
            <Filter
                isUseDDCore
                dropdownProps={{
                    ref: toolFilter,
                }}
                placeholder={Config.lang("Noi_dung_can_tim_kiem")}
                openOnFieldClick={true}
                onOpenLoaded={onLoadCboFilter}
                {...filterProps}
                renderFilter={() => {
                    return (
                        <Row>
                            {renderFilterProps(filter.current, filterChange)}
                            <Col xs={12} className={"display_row align-center valign-middle"}>
                                <Button
                                    size={"medium"}
                                    color={"primary"}
                                    viewType={"outlined"}
                                    label={Config.lang("Tim_kiem")}
                                    startIcon={"Search"}
                                    onClick={_onSearch}
                                />
                            </Col>
                        </Row>
                    );
                }}
            />
        );
    };

    return (
        <>
            <ActionToolbar title={title} {...actionToolbar}>
                {onAdd && (
                    <Button
                        size={"medium"}
                        color={"primary"}
                        viewType={"filled"}
                        startIcon={"AddFilled"}
                        disabled={permission <= 1 || loading}
                        label={Config.lang("Them")}
                        onClick={() => {
                            if (onAdd instanceof Function) onAdd();
                            else onAdd.action();
                        }}
                    />
                )}
                {actionToolbar?.children}
            </ActionToolbar>

            {renderHeader && renderHeader()}

            {/*Lưới danh sách*/}
            {useMemo(() => {
                return (
                    <GridContainer
                        style={{ border: "none" }}
                        keyExpr={keyExpr}
                        dataSource={dataGrid?.rows}
                        showRowLines={true}
                        showBorders={false}
                        showColumnLines={false}
                        showColumnHeaders={false}
                        height={Config.getHeightGrid()}
                        onChangePage={page => updateFilter(page)}
                        onChangePerPage={per => updateFilter(null, per, true)}
                        loading={loading}
                        hoverStateEnabled={true}
                        totalItems={dataGrid?.total}
                        itemPerPage={filter.current?.limit}
                        skipPerPage={filter.current?.skip}
                        typePaging={"remote"}
                        {...rest}
                    >
                        <Column cellRender={_renderEmpProfile} width={col?.[0] || 350} visible={!Config.isMobile} />
                        <Column
                            cellRender={_renderInfo}
                            width={col?.[1] || null}
                            visible={!Config.isMobile && renderInfo}
                        />
                        <Column
                            cellRender={_renderAppStatus}
                            width={col?.[2] || 200}
                            alignment="left"
                            visible={!Config.isMobile}
                        />
                        <Column
                            cellRender={_renderAction}
                            width={col?.[3] || "auto"}
                            alignment="right"
                            visible={!Config.isMobile}
                        />
                        <Column cellRender={_renderMobile} alignment="left" width={"100%"} visible={Config.isMobile} />
                    </GridContainer>
                );
                // eslint-disable-next-line react-hooks/exhaustive-deps
            }, [dataGrid])}

            {/*render Filter*/}
            {renderFilterProps && <div className={"hidden"}>{renderFilter()}</div>}

            {/*popup History*/}
            {showW84F3005.isShowing && (
                <W84F3005
                    open={showW84F3005.isShowing}
                    onClose={() => _toggleW84F3005(null)}
                    FormID="W09F2225"
                    TransID={showW84F3005?.TransID}
                />
            )}
        </>
    );
});

const PopoverApproval = props => {
    const { anchor, action, data, onClose, keyExpr } = props;

    const dispatch = useDispatch();

    const [note, setNote] = useState("");
    const [actionName, setActionName] = useState("");
    const [loading, setLoading] = useState(false);

    const dataButton = useMemo(
        () => [
            {
                key: "Approval",
                name: "Approval",
                colorHover: "success",
            },
            {
                key: "Reject",
                name: "Close",
                colorHover: "danger",
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [anchor]
    );

    const _onApproval = numberStatus => {
        if (numberStatus === 100 && !note) return;

        const params = {
            DivisionID: Config.getDivisionID(),
            HostID: "",
            Language: Config.language || "84",
            ApprovalStatus: String(numberStatus),
            ApprovalsNotes: note,
            Mode: numberStatus === 1 ? 1 : 0,
            [keyExpr]: data[keyExpr],
            ...data,
        };
        setLoading(true);
        dispatch(
            action.approval(params, (errors, data) => {
                setLoading(false);
                if (errors) Config.popup.show("ERROR", errors);
                else if (data) {
                    if (data.Status === 1) {
                        const msg = data.Message || Config.lang("Loi_chua_xac_dinh");
                        Config.popup.show("INFO", msg);
                        return false;
                    } else {
                        const msg =
                            numberStatus === 1 ? Config.lang("Duyet_thanh_cong") : Config.lang("Tu_choi_thanh_cong");
                        Config.notify.show("success", msg, 3000);
                        onClose && onClose();
                    }
                }
            })
        );
    };

    const _onCancel = numberStatus => {
        if (!note) return;
        const params = {
            HostID: "",
            DivisionID: Config.getDivisionID(),
            Language: Config.language || "84",
            ApprovalStatus: String(numberStatus),
            ApprovalsNotes: note,
            Mode: 2,
            [keyExpr]: data[keyExpr],
            ...data,
        };
        setLoading(true);
        Config.popup.show("YES_NO", Config.lang("Ban_co_chac_chan_bo_duyet"), () => {
            setLoading(false);
            dispatch(
                action.cancelApproval(params, (error, dt) => {
                    if (error) {
                        Config.popup.show("ERROR", error);
                        return false;
                    } else if (dt) {
                        const { Status, Message } = dt;
                        if (Status === 0) {
                            Config.notify.show("success", Config.lang("Bo_duyet_thanh_cong"), 3000);
                            onClose && onClose();
                        } else {
                            Config.popup.show("INFO", Message);
                        }
                    }
                })
            );
        });
    };

    const _renderAnchor = (
        <div>
            {anchor.map((item, index) => {
                if (!item) return null;
                const infoButton = dataButton.find(info => info.key === item);
                return (
                    <ButtonIcon
                        key={index}
                        circular
                        viewType={"text"}
                        className={"mgr5"}
                        disabled={loading}
                        {...infoButton}
                        onClick={() => setActionName(item)}
                    />
                );
            })}
        </div>
    );

    return (
        <>
            <Popover
                anchor={_renderAnchor}
                anchorOrigin={{
                    vertical: "center",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "center",
                    horizontal: "right",
                }}
                arrow
            >
                {/*<Row style={{ padding: spacing([12, 7]) }}>*/}
                <PopoverBody>
                    <Row>
                        <Col xs={12}>
                            <TextInput
                                label={Config.lang("Ghi_chu_duyet")}
                                multiline={true}
                                maxRows={3}
                                required
                                value={note}
                                error={true}
                                onChange={e => setNote(e.target.value)}
                            />
                        </Col>
                        <Col className={"display_row valign-bottom"}>
                            {actionName === "Approval" && (
                                <Button
                                    text={Config.lang("Duyet")}
                                    viewType={"filled"}
                                    color={"primary"}
                                    className={"mgr5"}
                                    disabled={loading}
                                    onClick={() => _onApproval(1)}
                                />
                            )}
                            {actionName === "Reject" && (
                                <Button
                                    text={Config.lang("Tu_choi")}
                                    viewType={"filled"}
                                    color={"danger"}
                                    className={"mgr5"}
                                    disabled={loading}
                                    onClick={() => _onApproval(100)}
                                />
                            )}
                            {actionName === "Cancel" && (
                                <Button
                                    text={Config.lang("Huy_phep")}
                                    viewType={"filled"}
                                    color={"danger"}
                                    className={"mgr5"}
                                    disabled={loading}
                                    onClick={() => _onApproval(100)}
                                />
                            )}
                            {actionName !== "Cancel" && actionName && Number(data?.AppStatusID) !== 0 && (
                                <Button
                                    text={Config.lang("Bo_duyet")}
                                    viewType={"filled"}
                                    color={"primary"}
                                    disabled={loading}
                                    onClick={() => _onCancel(0)}
                                />
                            )}
                        </Col>
                    </Row>
                </PopoverBody>
            </Popover>
        </>
    );
};

Index.defaultProps = {
    isLoadPermission: false,
    disabledDelete: false,
    disabledEdit: false,
    perPage: 20,
    actionToolbar: {},
    col: [],
    // convertData={{
    //     "EmployeeID": "HREmployeeID"
    // }}
};
Index.propTypes = {
    FormID: PropTypes.string.isRequired,
    keyExpr: PropTypes.string.isRequired,
    action: PropTypes.shape({
        loadGrid: PropTypes.func.isRequired,
        loadStatus: PropTypes.func,
        delete: PropTypes.func,
        approval: PropTypes.func,
        cancel: PropTypes.func,
    }).isRequired,
    title: PropTypes.string.isRequired,

    perPage: PropTypes.number,

    onDelete: PropTypes.bool,
    disabledDelete: PropTypes.bool,

    onEdit: PropTypes.func,
    disabledEdit: PropTypes.bool,
    disabledView: PropTypes.bool,

    onHistory: PropTypes.bool,
    isLoadPermission: PropTypes.bool,
    onApproval: PropTypes.bool,
    onReject: PropTypes.bool,

    // array width columns
    col: PropTypes.array,

    onAdd: PropTypes.func,
    onView: PropTypes.func,
    onDetail: PropTypes.func,
    renderFilter: PropTypes.func,
    renderHeader: PropTypes.func,
    onLoadedGrid: PropTypes.func,
    onChangePermission: PropTypes.func,
    renderEmployee: PropTypes.func,
    renderAction: PropTypes.func,

    filterProps: PropTypes.object,
    convertData: PropTypes.object,

    renderInfo: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.object, PropTypes.bool]),
    actionToolbar: PropTypes.object,
    gridProps: PropTypes.object,
};

export default Index;
