/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 1/7/2020
 * @Example
 */
import React from "react";
import PropTypes from "prop-types";
import Config from "../../config";
import {connect} from "react-redux";
import * as ApprovalActions from "../../redux/approvals/approvals_actions";
import {Col, FormGroup, Image, Row} from "react-bootstrap";
import {bindActionCreators, compose} from "redux";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "../grid-container/grid-container";
import {Column} from "devextreme-react/data-grid";
import Drawer from "@material-ui/core/Drawer";
import Icon from 'diginet-core-ui/icons';
import {
    Button,
    ButtonIcon,
    DatePicker,
    Divider,
    Status as StatusCore,
    TabContainer,
    TabHeader,
    TabItem,
    TabPanel,
    Tooltip,
    Typography,
    Dropdown,
    Avatar,
} from "diginet-core-ui/components";
import DOMPurify from 'dompurify';
import Filter from "../filter/filter";
import {MForm} from "../common/form-material";
import ApprovalsPopover from "./approvalspopover";
import Modal from "../common/modal/modal";
import ApprovalPopup from "./approvalspopup";
import FileViewer from "../common/fileviewer/fileviewer";
import moment from "moment";
import {LoadPanel}         from "devextreme-react";
import Attachments         from "../common/attachments/attachments";
import _                   from "lodash";
import Api                 from "../../services/api"
import Status              from "../common/status/status";
import * as generalActions from "../../redux/general/general_actions";
import W84F3005 from "../W8X/W84/W84F3005/W84F3005";
import W09F2081Popup from "../W0X/W09/W09F2081/W09F2081Popup";

const drawerWidth = '47%';
const heightHeader = 50;
const styles = theme => {
    return {
        chip: {
            fontSize: '1rem',
            fontWeight: 400,
            border: 'none',
            backgroundColor: "#EBEBEB"
        },
        btnAction: {
            color: "#FFF",
            borderColor: "#FFF",
            "& svg path": {
                fill: '#FFF'
            },
            '&.Mui-disabled': {
                color: "#FFFFFF4F",
                borderColor: "#FFFFFF4F",
            }
        },
        btnPrimary: {
            "&:hover": {
                color: theme.palette.primary.main,
                "& svg path": {
                    fill: theme.palette.primary.main
                }
            }
        },
        btnSecondary: {
            "&:hover": {
                color: theme.palette.secondary.main,
                "& svg path": {
                    fill: theme.palette.secondary.main
                }
            }
        },
        btnDefault: {
            "&:hover": {
                color: "#FFFFFF99",
                "& svg path": {
                    fill: "#FFFFFF99"
                }
            }
        },
        btnClose: {
            '&:hover': {
                backgroundColor: '#7b7b7b'
            }
        },
        drawer: {
            width: drawerWidth,
            maxWidth: 700,
            flexShrink: 0,
            [theme.breakpoints.down('sm')]: {
                zIndex: '1502 !important',
            },
        },
        drawerHeader: {
            // height: heightHeader,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            padding: '5px 15px',
            margin: "0 -15px",
            marginBottom: 2,
            background: '#F7F9FC',
            boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)'
        },
        drawerPaper: {
            width: drawerWidth,
            maxWidth: 700,
            [theme.breakpoints.down(768)]: {
                width: '100%',
            },
            backgroundColor: '#FFFFFF',
            opacity: 1,
            // zIndex: 1502,
            padding: '0 15px 15px 15px',
            overflow: 'hidden',
            height: 'calc(100% - 50px)'
        },
        desktopScreen: {
            top: heightHeader
        },
        mobileScreen: {
            top: 0
        },
        panel: {
            backgroundColor: "#FFF",
            padding: 15,
            overflowY: Config.isMobile ? 'auto' : 'hidden',
            '&:hover': {
                overflowY: 'auto'
            }
        },
        content: {
            transition: 'transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms'
        },
        contentDrawer: {
            width: '50%'
        },
        tabs: {
            minHeight: 42,
            borderBottom: '1px solid #E0E0E0'
        },
        tabContent: {
            padding: '10px 0',
            minHeight: 400
        },
        attachItem: {
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            boxShadow: '0 0 10px #00000034',
            borderRadius: 5,
            overflow: 'hidden',
            cursor: 'pointer',
            '& .att-wrap-icon': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '60%'
            },
            '&:hover': {
                boxShadow: '0 0 20px #00000051'
            }
        },
        scrollContent: {
            overflowY: 'auto',
            overflowX: 'hidden'
        },
        iconHoverRed: {
            '&:hover': {
                color: theme.palette.secondary.main,
            },
        },
        iconHoverGreen: {
            '&:hover': {
                color: theme.palette.primary.main,
            },
        },
        danger: {
            color: "#fff",
            backgroundColor: theme.palette.danger.main,
        },
        VoucherNo: {
            maxWidth: "100%",
            textOverflow: "ellipsis",
            overflow: "hidden",
            textAlign: "left",
        },
    };
};

class Approvals extends React.Component {
    constructor(props) {
        super(props);
        this.instanceApprovals = null;
        this.state = {
            uploading: false,
            dataOldAttachments: [],
            open: false,
            tab: props.detailRender ? 0 : 1,
            selectedRow: null,
            showApprovals: false,
            showApprovalPopup: false,
            showFileViewer: false,
            fileOpened: {},
            approving: false,
            gridLoading: false,
            cboEmployeeLoading: false,
            dataCboEmployees: {
                rows: [],
                total: 0
            },
            showW84F3005Popup: false,
            w84F3005PopupData: {
                TransID: null
            },
            errorNote: "",
            filter: {
                IsRegisterType: null,
                AppStatusID: null,
                EmployeeID: "",
                DepartmentID: "",
                DateFrom: null,
                DateTo: null,
            },
            W09F2081PopupData: {
                selectedRow: null,
                notes: "",
                status: 1,
            },
            isOpenW09F2081Popup: false,
        };
        this.newAttachments = [];
        this.oldAttachUpload = [];
        this.oldAttachDel = [];
        this.VoucherID = "";
        this.filter = {
            Search: "",
            skip: 0,
            limit: 10
        };
        this.timer = null;
        this.interval = null;
        this.filterCboEmployees = {
            strSearch: "",
            skip: 0,
            limit: 50
        };
        this.refApprovalPopover = null;
    }

    loadCboStatus = () => {
        const params = {
            FormID: "STANDARD",
            Language: Config.language || '84'
        };
        this.props.approvalActions.getCboStatus(params, (error) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };
    loadGrid = (voucher_id) => {
        const {FormID, dataFilter, selectedRowKey,isShowCboDepartment,isShowCboRegisterType} = this.props;
        const { filter: { IsRegisterType, AppStatusID, EmployeeID, DepartmentID, DateFrom, DateTo } } = this.state;
        const { skip, limit } = this.filter;
        const defaultFilter = {

            ApprovalStatus: AppStatusID,
            EmployeeID: EmployeeID,
            DateFrom: DateFrom,
            DateTo: DateTo,
        };
        if (isShowCboDepartment) {
            defaultFilter.DepartmentID=DepartmentID
        }
        if(isShowCboRegisterType){
            defaultFilter.IsRegisterType= IsRegisterType
        }
        const params = {
            VoucherID: voucher_id || "",
            DivisionID: Config.getDivisionID(),
            FormID: FormID,
            Language: Config.language || '84',
            skip: skip,
            limit: limit,
            ...(dataFilter ? dataFilter : defaultFilter)
        };
        this.setState({gridLoading: true});
        this.props.approvalActions.getGridApprovals(params, (error) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }

            if (this.interval) clearInterval(this.interval);
            let count = 0;
            this.interval = setInterval(() => {
                if (this.props.selectedRowKey) {
                    this.onSelected();
                    clearInterval(this.interval);
                }
                if (count === 10) {
                    clearInterval(this.interval);
                }
                count++;
            }, 500);
            this.setState({gridLoading: false});
            if (selectedRowKey) {
                this.onSelected();
            }
        });
    };

    loadListAttachments = (VoucherID) => {
        const {FormID, allowAddingAttachment} = this.props;
        const params = {
            DivisionID: Config.getDivisionID(),
            FormID: FormID,
            VoucherID: VoucherID
        };
        this.props.approvalActions.getAttachments(params, (error,data) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if(allowAddingAttachment && data){
                this.setState({
                    dataOldAttachments: data
                });
            }
        });
    };

    loadListHistorys = (VoucherID) => {
        const {FormID} = this.props;
        let formID = {};
        if(FormID === "W77F1012" || FormID === "W77F1014" || FormID === "W09F1000") formID = { FormID };
        const params = {
            VoucherID: VoucherID,
            ...formID
        };
        this.props.approvalActions.getHistorys(params, (error) => {
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
        });
    };

    loadCboEmployees = (isReset) => {
        const {FormID} = this.props;
        const param = {
            HostID: "",
            Type: "EmployeeID",
            FormID: FormID,
            Language: Config.language || "84",
            skip: this.filterCboEmployees.skip,
            limit: this.filterCboEmployees.limit,
            search: this.filterCboEmployees.strSearch
        };
        this.setState({cboEmployeeLoading: true});
        this.props.generalActions.getCboEmployees(param, (error, data) => {
            this.setState({cboEmployeeLoading: false});
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const {dataCboEmployees} = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboEmployees: {
                        rows: isReset ? rows : dataCboEmployees.rows.concat(rows),
                        total: total
                    }
                })
            }
        });
    };
    loadCboDepartment = () => {
        const params = {
          HostID: "",
          FormID: this.props.formID,
          DivisionID: Config.getDivisionID(),
          Language: Config.language || "84"
        };
        this.props.generalActions.getCboDepartments(params, error => {
            if (error) {
            Config.popup.show("ERROR", error);
            return false;
            }
        });
      };

    loadCboRegisterTypes = () => {
        this.props.approvalActions.getRegisterTypes(err => {
            if (err) {
                Config.popup.show('ERROR', err);
                return false;
            }
        });
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const isShowW13F1003props = this.props && this.props.isShowW13F1003 !== undefined;
        const parentProps = this.props && this.props.parentProps;
        if (parentProps) {
            const {FormID, getGridApproval, onSelectionChanged} = this.props;
            const {voucher_id}                                  = Config.getUrlParams(parentProps);
            Config.callChildForm({
                ID:       voucher_id,
                FormID:   FormID,
                data:     getGridApproval.rows || [],
                keyExpr:  "VoucherID",
                onLoad:   (params) => this.loadGrid(params && params.ID),
                onAction: (e, data) => {
                    this.showPopup(data);
                    if (onSelectionChanged) onSelectionChanged(data);
                }
            }, parentProps);
        }
        if (JSON.stringify(this.props.dataFilter) !== JSON.stringify(prevProps.dataFilter)) {
            this.filter.skip = 0;
            this.onReset(true);
        }
        if(isShowW13F1003props){
            const {isShowW13F1003} = this.props;
            (!isShowW13F1003 && prevProps.isShowW13F1003) && this.onReset(true);
        }
    }

    componentWillUnmount() {
        const {disableEscapeKeyDown} = this.props;
        if (!disableEscapeKeyDown) window.removeEventListener("keydown", this.onKeyDown);
    }

    onKeyDown = (e) => {
        if (!e && !e.keyCode) return false;
        if (e.keyCode === 27) {
            this.onDrawerClose();
        }
    };

    componentDidMount() {
        const {dataSource, disableEscapeKeyDown,isShowCboDepartment,isShowCboRegisterType, parentProps} = this.props;
        const {voucher_id} = Config.getUrlParams(parentProps);
        this.loadCboStatus();
        this.loadCboEmployees();
        isShowCboDepartment&& this.loadCboDepartment();
        isShowCboRegisterType&& this.loadCboRegisterTypes();
        // this.loadListAttachments();
        if (!dataSource) {
            if (parentProps) {
                if (!voucher_id) this.loadGrid();
            } else {
                this.loadGrid();
            }
        }
        if (!disableEscapeKeyDown) window.addEventListener("keydown", this.onKeyDown);
    }

    onAttachment = () => {
        if (this.attRef) {
            this.attRef.onAttachment();
        }
    };

    onChangeAttachments = async e => {
        const {tableNameAttached} = this.props;
        const {selectedRow: { VoucherID }, dataOldAttachments} = this.state;
        const TableName = tableNameAttached ? tableNameAttached : "D09T0021";
        const uploadedFiles = _.get(e,"uploadedFiles", []);
        const deletedFiles = _.get(e,"deletedFiles",[]);
        const dataNewAttachments = dataOldAttachments.filter(item => deletedFiles.map(itemDel => itemDel.AttachmentID).indexOf(item.AttachmentID) === -1);
        const delOldFileAttach = this.oldAttachDel < deletedFiles.length;
        const delNewFileAttach = _.isEmpty(this.oldAttachUpload) ? false : this.oldAttachUpload.length > uploadedFiles.length;

        if(delOldFileAttach){
            this.oldAttachDel = [...deletedFiles].length;
            const AttachmentID = _.get(_.last(deletedFiles),"AttachmentID", false);
            this.setState({dataOldAttachments: dataNewAttachments},() => { this.onDeleteAttachment({ VoucherID, TableName, AttachmentID})});
        } else if(delNewFileAttach){
            const attachDeletedURL  = _.get(this.oldAttachUpload.filter(itemOld => uploadedFiles.map(itemCurrent => itemCurrent.key).indexOf(itemOld.key) === -1),"[0]url", "");
            const attachIDeleted = _.get(this.newAttachments.filter(itemNew => itemNew.URL.includes(attachDeletedURL)),"[0]AttachmentID", false);
            this.oldAttachUpload = this.oldAttachUpload.filter(itemOld => !itemOld.url.includes(attachDeletedURL));
            this.setState({dataOldAttachments: dataNewAttachments},() => { this.onDeleteAttachment({ VoucherID, TableName, AttachmentID: attachIDeleted})});
        } else {
            let uploadFilesNew = uploadedFiles.filter(itemCurrent => !this.oldAttachUpload.map(itemOld => itemOld.key).includes(itemCurrent.key));
            const list = _.isEmpty(uploadFilesNew) ? uploadedFiles : uploadFilesNew;
            this.oldAttachUpload = [...uploadedFiles];
            const listAttachment = list.map(itemNew => ({
                URL: itemNew.url,
                FileName: itemNew.fileName,
                FileSize: itemNew.fileSize,
                FileExt: itemNew.fileExt,
                Description: "",
                DescriptionU: "",
            }));

            const params = {
                VoucherID,
                TableName,
                DivisionID: Config.getDivisionID(),
                Sequence: "1",
                arrAttachment: JSON.stringify(listAttachment),
                CreateDate: moment().format("DD/MM/YYYY"),
            };
            this.onAddAtachment(params, e.uploadedFiles);
        }

    };

    onAddAtachment = async params => {
        try {
            const res = await Api.put("/attachment/add-attachment", params);
            const isAdded = _.get(res,"code", 100) === 200;
            if(isAdded) {
                this.newAttachments = [...this.newAttachments,..._.get(res,"data", [])];
            } else {
                Config.popup.show("INFO",_.get(res,"message", Config.lang("Loi_chua_xac_dinh")));
            }
        } catch (error) {
            console.log(error);
        }
    }

    onDeleteAttachment = async params => {
        try {
            const res = await Api.put("/attachment/delete-attachment", params);
            const isDeleted = _.get(res,"code", 100) === 200;
            !isDeleted && Config.popup.show("INFO",_.get(res,"message", Config.lang("Loi_chua_xac_dinh")));
        } catch (error) {

        }
    }

    onUploading = (value) => {
        this.setState({uploading: value});
    };

    onReset = (resetData) => {
        const { reloadGrid } = this.props;
        this.setState({
            open: false,
            showApprovals: false,
            showApprovalPopup: false,
            selectedRow: null,
            errorNote: ""
        }, () => {
            this.panelLeft.style.width = '100%';
            if (resetData) {
                if (reloadGrid) {
                    reloadGrid();
                } else {
                    this.loadGrid();
                }
            }
        });
    };

    onDrawerClose = () => {
        const {onDrawerClosed, onSelectionChanged} = this.props;
        this.VoucherID = "";
        this.setState({
            VoucherID: "",
            selectedRow: null,
            open: false
        }, () => {
            this.panelLeft.style.width = '100%';
            if (this.toolFilter) {
                this.toolFilter.close();
            }
            if (onDrawerClosed) onDrawerClosed();
        });
        if (onSelectionChanged) onSelectionChanged(null);
    };

    onSelected = (e) => {

        const {dataSource, getGridApproval, selectedRowKey} = this.props;
        const {selectedRow} = this.state;
        let VoucherID = "";
        if (selectedRowKey) {
            VoucherID = selectedRowKey;
            if (Array.isArray(selectedRowKey)) {
                VoucherID = selectedRowKey[0];
            }
        }
        let data = e && e.data ? e.data : {VoucherID: VoucherID};
        if ((e && e.column && e.column.dataField !== "Action") || !e) {
            // const {data} = e;
            const {attachments, history, onSelectionChanged} = this.props;
            if (data && selectedRow && selectedRow.VoucherID === data.VoucherID) return false;
            const _dataSource = dataSource && dataSource.rows ? dataSource.rows : (getGridApproval && getGridApproval.rows ? getGridApproval.rows : []);
            const selectRows = _dataSource.filter((e) => {
                return e.VoucherID === data.VoucherID;
            });
            data = selectRows && selectRows.length > 0 ? selectRows[0] : null;
            if (data) {
                this.setState({
                    selectedRow: data,
                    open: true
                }, () => {
                    if (this.toolFilter) {
                        this.toolFilter.close();
                    }
                    if (this.drawer) {
                        const drawerWidth = this.drawer.clientWidth;
                        this.panelLeft.style.width = (window.innerWidth - drawerWidth - 77) + 'px';
                    }
                    if (!attachments) {
                        this.loadListAttachments(data.VoucherID);
                    }
                    if (!history) {
                        this.loadListHistorys(data.VoucherID);
                    }
                });
                if (onSelectionChanged) onSelectionChanged(data);
            }
        }
    };

    showPopup = (data) => {
        const {attachments, history} = this.props;

        this.setState({
            selectedRow: data,
            open: true
        }, () => {
            if (this.toolFilter) {
                this.toolFilter.close();
            }
            if (this.drawer) {
                const drawerWidth = this.drawer.clientWidth;
                this.panelLeft.style.width = (window.innerWidth - drawerWidth - 77) + 'px';
            }
            if (!attachments) {
                this.loadListAttachments(data.VoucherID);
            }
            if (!history) {
                this.loadListHistorys(data.VoucherID);
            }
        });
    };

    onChangeTab = (e, value) => {
        this.setState({tab: value})
    };

    handleFilterChange = (key, e) => {
        const {onTextFilterChanged} = this.props;
        switch (key) {
            case "Search":
                this.filter.Search = e.target.value;
                if (onTextFilterChanged) {
                    if (this.timer) clearTimeout(this.timer);
                    this.timer = setTimeout(() => {
                        onTextFilterChanged({ ...this.filter, ...this.state.filter });
                    }, 500);
                }
                break;
            case "AppStatusID":
            case "IsRegisterType":
            case "EmployeeID":
            case "DepartmentID":
            case "DateFrom":
            case "DateTo":
                this.setState({ filter: { ...this.state.filter, [key]: e.value } });
                break;
            default:
                break;
        }
    };

    onFilter = () => {
        const {filterRender, onFilterChanged} = this.props;
        this.filter.skip = 0;
        this.onReset();
        if (!filterRender) {
            this.loadGrid();
        }
        if (onFilterChanged) onFilterChanged({ ...this.filter, ...this.state?.filter });
        if (this.toolFilter) {
            this.toolFilter.close();
        }
    };

    onOpenApproval = (type, e, data) => {
        this.setState({
            showApprovals: true,
            anchorEl: e.currentTarget,
            type: type,
            selectedRow: data
        },() => {
            if(this.refApprovalPopover) this.refApprovalPopover.onOpenPopover();
        });
    };

    onClosePopoverApproval = () => {
        this.setState({
            showApprovals: false,
            selectedRow: null,
            errorNote:''
        })
    };

    onHistoryApproval = (e, data) => {
        this.setState({ w84F3005PopupData: { TransID: data?data.VoucherID:null }, showW84F3005Popup: true })
    };

    renderItem = e => {
        const { data } = e.row;
        const info = {
            [Config.lang("Nhan_vien")]: `${data?.EmployeeID || ""} - ${data?.EmployeeName || ""}`,
            [Config.lang("Phong_ban")]: data?.DepartmentName || "",
            [Config.lang("Du_an")]: data?.ProjectName || "",
            [Config.lang("Chuc_vu")]: data?.DutyName || "",
            [Config.lang("Ngay_vao_lam")]: Config.convertDate(data?.DateJoined, "", "L") || '',
        };
        return (
            <div className={"display_row align-center pdt10 pdb10"} style={{ minHeight: 100 }}>
                <Avatar
                    hoverAble
                    data={info}
                    readOnly
                    src={Config.getCDNPath(data.UserPictureURL)}
                    className={"mgr5"}
                />
                <div>
                    <Typography type={"h3"}>{data.CreateUserName}</Typography>
                    {data?.VoucherDate && (
                        <div className={"display_row align-center mgt5"}>
                            <Icon
                                width={20}
                                height={20}
                                name={"Calendar"}
                                color={Config.coreTheme?.colors?.system.rest}
                                className={"mgr5"}
                            />
                            <Typography color={Config.coreTheme?.colors?.system.rest}>
                                {Config.helpers.convertDate(data.VoucherDate, "", "DD/MM/YYYY kk:mm:ss")}
                            </Typography>
                        </div>
                    )}
                    {data?.CountFile && (
                        <div className={"display_row align-center mgt5"}>
                            <Icon name={"Attachment"} viewBox />
                            <Typography>{data.CountFile || ""}</Typography>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    renderDescription = e => {
        const { data } = e.row;
        const { classes } = this.props;
        const description =
            data && data.VoucherNo ? (
                <div
                    className={`approvals-description ${classes.VoucherNo}`}
                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.VoucherNo) }}
                />
            ) : null;

        return (
            <>
                <Tooltip style={{width:'100%'}} title={description}>{description}</Tooltip>
                {data && data.AttachmentCount > 0 && (
                    <div className={"align-center"} style={{display: "inline-flex"}}>
                        <Icon name={"Attachment"} viewBox/>
                        <Typography>{`(${data.AttachmentCount})`}</Typography>
                    </div>
                )}
                {Boolean(data.IsRegisterType) ? (
                    <StatusCore text={Config.lang("Phieu_huy")} color="danger" viewType={"filled"} />
                ) : null}
            </>
        );
    };

    renderStatus = (e) => {
        const {data} = e.row;
        const status = this._getStatusInfo(data && data.ApprovalStatus ? data.ApprovalStatus : 0);
        return (
            <Status data={status} />
        );
    };

    renderAction = (e) => {
        const { labelBtnApprove, labelBtnNotApprove, allowHistoryApproval, isActionPermission, disabledApproval } = this.props;
        const {data} = e.row;
        if (!isActionPermission) {
            return null;
        }
        return (
            <div className={"display_row align-center"} style={{ minHeight: 32 }}>
                {allowHistoryApproval && (
                    <ButtonIcon
                        circular
                        viewType={"text"}
                        name={"History"}
                        className={'mgr5'}
                        onClick={() => this.onHistoryApproval(e, data)}
                    />
                )}
                <Tooltip title={labelBtnApprove ? labelBtnApprove : Config.lang("Duyet")}>
                    <ButtonIcon
                        circular
                        viewType={"text"}
                        name={"Approval"}
                        colorHover={"success"}
                        className={'mgr5'}
                        disabled={(data && (Number(data.ApprovalStatus) === 1 || Number(data.ApprovalStatus) === 100)) || disabledApproval}
                        onClick={e => this.onOpenApproval("approve", e, data)}
                    />
                </Tooltip>
                <Tooltip title={labelBtnNotApprove ? labelBtnNotApprove : Config.lang("Tu_choi")}>
                    <ButtonIcon
                        circular
                        viewType={"text"}
                        name={"Cancel"}
                        colorHover={"danger"}
                        disabled={(data && (Number(data.ApprovalStatus) === 1 || Number(data.ApprovalStatus) === 100)) || disabledApproval}
                        onClick={e => this.onOpenApproval("cancel", e, data)}
                    />
                </Tooltip>
            </div>
        );
    };

    renderDefaultFilter = () => {
        const {getCboStatus, getRegisterTypes, getCboDepartments,isShowCboDepartment,isShowCboRegisterType,} = this.props;
        const {dataCboEmployees, cboEmployeeLoading, filter} = this.state;
        return (
            <Filter
                isUseDDCore
                dropdownProps={{
                    ref: ref => this.toolFilter = ref
                }}
                // acceptCustomValue={false}
                // onTextChanged={(e) => this.handleFilterChange("Search", e)}
                placeholder={Config.lang("Noi_dung_can_tim_kiem")}
                showHeader={false}
                openOnFieldClick={true}
                renderFilter={() => {
                    return (
                        <>
                            <FormGroup>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Dropdown
                                            clearAble
                                            dataSource={getCboStatus}
                                            viewType={"outlined"}
                                            keyExpr={"AppStatusName"}
                                            displayExpr={"AppStatusName"}
                                            valueExpr={"ApprovalStatus"}
                                            label={Config.lang("Trang_thai_duyet")}
                                            value={filter?.AppStatusID}
                                            onChange={e => this.handleFilterChange("AppStatusID", e)}
                                        />
                                    </Col>
                                </Row>
                                {
                                    isShowCboRegisterType&&<Row>
                                        <Col xs={12} sm={12} md={12} lg={12}>
                                            <Dropdown
                                                clearAble
                                                dataSource={getRegisterTypes}
                                                viewType={"outlined"}
                                                keyExpr={"RegisterType"}
                                                displayExpr={"RegisterType"}
                                                valueExpr={"IsRegisterType"}
                                                label={Config.lang("Hinh_thuc")}
                                                value={filter?.IsRegisterType}
                                                onChange={e => this.handleFilterChange("IsRegisterType", e)}
                                            />
                                        </Col>
                                    </Row>
                                }

                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Dropdown
                                            clearAble
                                            allowSearch
                                            searchDelayTime
                                            dataSource={_.get(dataCboEmployees, "rows", [])}
                                            total={_.get(dataCboEmployees, "total", 0)}
                                            skip={this.filterCboEmployees.skip}
                                            limit={this.filterCboEmployees.limit}
                                            value={filter?.EmployeeID}
                                            loading={cboEmployeeLoading}
                                            viewType={"outlined"}
                                            valueExpr={"EmployeeID"}
                                            keyExpr={"EmployeeName"}
                                            displayExpr={"{EmployeeID} - {EmployeeName}"}
                                            label={Config.lang("Nhan_vien")}
                                            onChange={(e) => this.handleFilterChange("EmployeeID", e)}
                                            onLoadMore={(e) => {
                                                this.filterCboEmployees.skip = e.skip;
                                                this.filterCboEmployees.limit = e.limit;
                                                this.loadCboEmployees();
                                            }}
                                            onInput={(e) => {
                                                this.filterCboEmployees.strSearch = e?.target?.value;
                                                this.filterCboEmployees.skip = 0;
                                                this.loadCboEmployees(true);
                                            }}
                                        />
                                    </Col>
                                </Row>
                                {
                                    isShowCboDepartment&& <Row>
                                        <Col xs={12}>
                                            <Dropdown
                                                clearAble
                                                dataSource={getCboDepartments}
                                                viewType={"outlined"}
                                                keyExpr={"DepartmentName"}
                                                displayExpr={"DepartmentName"}
                                                valueExpr={"DepartmentID"}
                                                label={Config.lang("Phong_ban")}
                                                value={filter?.DepartmentID}
                                                onChange={e => this.handleFilterChange("DepartmentID", e)}
                                            />
                                        </Col>
                                    </Row>
                                }
                                <Row>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <DatePicker
                                            clearAble
                                            viewType={"outlined"}
                                            placeholder={"DD/MM/YYYY"}
                                            displayFormat={"DD/MM/YYYY"}
                                            label={Config.lang("Tu")}
                                            max={filter?.DateTo}
                                            value={filter?.DateFrom}
                                            onChange={e => this.handleFilterChange("DateFrom", e)}
                                        />
                                    </Col>
                                    <Col xs={12} sm={6} md={6} lg={6}>
                                        <DatePicker
                                            clearAble
                                            viewType={"outlined"}
                                            placeholder={"DD/MM/YYYY"}
                                            displayFormat={"DD/MM/YYYY"}
                                            label={Config.lang("Den")}
                                            min={filter?.DateFrom}
                                            value={filter?.DateTo}
                                            onChange={e => this.handleFilterChange("DateTo", e)}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup className={"display_row align-center valign-middle"}>
                                <Button
                                    label={Config.lang('Tim_kiem')}
                                    viewType={"outlined"}
                                    color={"primary"}
                                    startIcon={"Search"}
                                    onClick={this.onFilter}
                                />
                            </FormGroup>
                        </>
                    );
                }}
            />
        );
    };

    renderCellHistory = (e) => {
        const {getCboStatus} = this.props;
        const {data} = e.row;
        const status = getCboStatus.filter((f) => {
            return f.ApprovalStatus === data.ApprovalStatus;
        });

        const info = {
            [Config.lang("Nhan_vien")]: `${data?.EmployeeID || ""} - ${data?.EmployeeName || ""}`,
            [Config.lang("Phong_ban")]: data?.DepartmentName || "",
            [Config.lang("Du_an")]: data?.ProjectName || "",
            [Config.lang("Chuc_vu")]: data?.DutyName || "",
            [Config.lang("Ngay_vao_lam")]: Config.convertDate(data?.DateJoined, "", "L") || '',
        };
        return (
            <div className={"display_row align-center"}>
                <Avatar
                    hoverAble
                    data={info}
                    readOnly
                    src={Config.getCDNPath(data.UserPictureURL)}
                    className={"mgr5"}
                />
                <div style={{ lineHeight: "21px" }}>
                    <div className={'display_row'}>
                        <Typography type={'h3'} className={'mgr5'}>{data.ApproverName}</Typography>
                        <Typography>
                            {status && status.length > 0 ? status[0].AppStatusName : Config.lang("Khong_trang_thai")} -{" "}
                            {Config.lang("Cap")}&nbsp;{data.ApprovalLevel}
                        </Typography>
                    </div>
                    <Typography>{data.ApprovalNotesU}</Typography>
                    <Typography style={{ fontSize: 10 }} className={"text-muted"}>
                        {Config.helpers.convertDate(data.ApprovalDate, "", "DD/MM/YYYY LT")}
                    </Typography>
                </div>
            </div>
        );
    };

    saveApprovals = (Status, ApprovalNotes, selected) => {
        const {selectedRow} = this.state;
        const selectedRowApprovals = selectedRow ?? selected;
        const {FormID, dataForm} = this.props;
        const validate = MForm.isRequired(ApprovalNotes, "errorNote");
        if (!selectedRowApprovals || !selectedRowApprovals.VoucherID)  {
            Config.popup.show('INFO', Config.lang("Khong_the_duyet"));
            return false;
        }
        if(Status === 100) {
            if (validate && validate.errorNote) {
                this.setState(validate);
                return false;
            }
        }
        this.setState({approving: true});
        const params = {
            DivisionID: Config.getDivisionID(),
            FormID: FormID,
            EmployeeID : selectedRowApprovals.EmployeeID  ? selectedRowApprovals.EmployeeID : "",
            VoucherID: selectedRowApprovals.VoucherID ? selectedRowApprovals.VoucherID : "",
            ApprovalLevel: selectedRowApprovals.ApprovalLevel ? selectedRowApprovals.ApprovalLevel : "",
            ApprovalFlowID: selectedRowApprovals.ApprovalFlowID ? selectedRowApprovals.ApprovalFlowID : "",
            ApprovalStatus: Status ? Status : 1,
            ApprovalNotesU: ApprovalNotes ? ApprovalNotes : "",
            AttendanceDate: selectedRowApprovals.AttendanceDate ||  "",
            IsSalaryAdjustment: dataForm?.IsSalaryAdjustment ?? 0,
            DepartmentID: dataForm?.NewDepartmentID ?? '',
            NewDutyID: dataForm?.NewDutyID ?? '',
            ValidDate: dataForm?.Validdate ?? null,
        };
        this.props.approvalActions.saveApprovals(params, (error,data) => {
            this.setState({approving: false});
            if (error) {
                let message = Config.lang("Loi_chua_xac_dinh");
                switch (error.code) {
                    case "F1002E007":
                        message = params.ApprovalStatus === 1 ? Config.lang("Duyet_khong_thanh_cong") : Config.lang("Tu_choi_khong_thanh_cong");
                        break;
                    default:
                        break;
                }
                Config.popup.show('INFO', message);
                return false;
            }
            if(data.Status === 1) {
                let message = data.Message || (params.ApprovalStatus === 1 ? Config.lang("Duyet_khong_thanh_cong") : Config.lang("Tu_choi_khong_thanh_cong"));
                if (this.refApprovalPopover) {
                    this.refApprovalPopover.onClosePopover(() => {
                        Config.popup.show('INFO', message);
                    });
                } else {
                    Config.popup.show('INFO', message);
                }
                return false;
            }

            this.onReset(true);
            if (this.refApprovalPopover) this.refApprovalPopover.onClosePopover();
            Config.notify.show('success', Config.lang("Thuc_hien_thanh_cong"), 2000);
        });
    };

    cancelApprovals = () => {
        const {selectedRow} = this.state;
        const {FormID, dataForm} = this.props;
        if (!selectedRow || !selectedRow.VoucherID)  {
            Config.popup.show('INFO', Config.lang("Khong_the_duyet"));
            return false;
        }
        this.setState({approving: true});
        const params = {
            DivisionID: Config.getDivisionID(),
            FormID: FormID,
            Language: Config?.language ?? '84',
            VoucherID: selectedRow.VoucherID ? selectedRow.VoucherID : "",
            EmployeeID : selectedRow.EmployeeID  ? selectedRow.EmployeeID : "",
            ApprovalLevel: selectedRow.ApprovalLevel ? selectedRow.ApprovalLevel : "",
            ApprovalFlowID: selectedRow.ApprovalFlowID ? selectedRow.ApprovalFlowID : "",
            ApprovalStatus: selectedRow.ApprovalStatus || selectedRow.ApprovalStatus === 0 ? selectedRow.ApprovalStatus : "",
            AttendanceDate: selectedRow.AttendanceDate ||  "",
            IsSalaryAdjustment: dataForm?.IsSalaryAdjustment ?? 0,
            DepartmentID: dataForm?.NewDepartmentID ?? '',
            NewDutyID: dataForm?.NewDutyID ?? '',
            ValidDate: dataForm?.Validdate ?? null,
        };
        this.props.approvalActions.cancelApprovals(params, (error,data) => {
            this.setState({approving: false});
            if (error) {
                Config.popup.show('ERROR', error);
                return false;
            }
            if(data.Status === 1) {
                let message = data.Message || Config.lang("Cap_tiep_theo_da_duyet._Ban_khong_the_bo_duyet");
                Config.popup.show('INFO', message);
                return false;
            }
            this.onReset(true);
            Config.notify.show('success', Config.lang("Thuc_hien_thanh_cong"), 2000);
        });
    };

    onCancelApprovals = () => {
        const message = Config.lang("Ban_co_chac_muon_bo_trang_thai_nay?");
        Config.popup.show('YES_NO', message, () => {
            this.setState({approving: true});
            this.cancelApprovals();

        });
    };

    onApproval = (notes) => {
        const {selectedRow} = this.state;
        const {onApproval} = this.props;
        if (onApproval) {
            onApproval(notes);
        } else {
            !!selectedRow?.IsShowReceiverScreen ? this.onOpenReceiverModal("approve", notes) :
            this.saveApprovals(1, notes);
        }
    };

    onReject = (notes) => {
        const {selectedRow} = this.state;
        const {onReject} = this.props;
        if (onReject) {
            onReject(notes);
        } else {
            if(!!selectedRow?.IsShowReceiverScreen) {
                const validate = MForm.isRequired(notes, "errorNote");
                if (validate && validate.errorNote) {
                    this.setState(validate);
                    return false;
                }
                this.onOpenReceiverModal("reject", notes)
            }
            else {
                this.saveApprovals(100, notes);
            }
        }
    };

    onHidden = () => {
        this.setState({errorNote : ""});
    };

    onApprovalModal = (type) => {
        this.setState({
            type: type,
            showApprovalPopup: true
        });
    };
    onCloseApprovalModal = () => {
        this.setState({showApprovalPopup: false, errorNote: ""})
    };

    onOpenReceiverModal = (status, notes) => {
        const {selectedRow} = this.state;
        if (this.refApprovalPopover) {
            this.refApprovalPopover.onClosePopover();
        }
        this.setState({
            isOpenW09F2081Popup: true,
            W09F2081PopupData: {
                selectedRow,
                notes,
                status,  
            }   
        })
    }

    _getIconFile = (fileName) => {
        let icon = require('../../assets/images/files/file.svg');
        try {
            icon = require('../../assets/images/files/' + fileName);
        } catch (e) {

        }
        return icon;
    };

    onViewFile = (data) => {
        this.setState({
            fileOpened: {
                FileName: data.FileName ? data.FileName : "",
                FileExt: data.FileExt ? data.FileExt : "",
                URL: data.URL ? Config.getCDNPath() + data.URL : ""
            },
            showFileViewer: true,
        });
    };

    onCloseViewFile = () => {
        this.setState({showFileViewer: false});
    };

    _getStatusInfo = (ApprovalStatus) => {
        const status = ApprovalStatus ? ApprovalStatus : 0;
        const {getCboStatus} = this.props;
        const color = Number(status) === 1 ? "success" : (Number(status) === 100 ? "danger" : "");
        const itemStatus = getCboStatus.filter((e) => {
            return Number(e.ApprovalStatus) === Number(status);
        });
        let _icon = "HourGlass";
        switch (Number(status)) {
            case 0:
                _icon = "HourGlass";
                break;
            case 1:
                _icon = "Approval";
                break;
            case 4:
            case 100:
                _icon = "Close";
                break;
            default:
                break;
        }
        return {
            AppStatusColor: color,
            AppStatusID: status,
            AppStatusIcon: _icon,
            AppStatusName: itemStatus && itemStatus.length > 0 ? itemStatus[0].AppStatusName : Config.lang("Khong_trang_thai")
        };
    };

    onChangePage = (page)=>{
        this.filter.skip = page * this.filter.limit;
        this.loadGrid();
    };

    onChangePerPage = (perPage)=>{
        this.filter.skip = 0;
        this.filter.limit = perPage;
        this.loadGrid();
    };

    onCloseReceiverPopup = () => {
        this.setState({isOpenW09F2081Popup: false})
    }

    render() {
        const {open, tab, showFileViewer, showApprovals, showApprovalPopup,
            anchorEl, type, selectedRow, fileOpened, approving, gridLoading, uploading,dataOldAttachments,
            showW84F3005Popup, w84F3005PopupData={}, errorNote, isOpenW09F2081Popup, W09F2081PopupData
        } = this.state;
        const {classes, loading, getGridApproval, getAttachments, getHistorys, disabled,
            dataSource, labelBtnApprove, labelBtnCancelApprove, labelBtnNotApprove, showFilters, singleClick,
            dataAttachments, dataHistory, dataForm, dataDetail, disabledApproval,
            filterRender, formInfoRender, detailRender, attachRender, historyRender, FormID,
            disabledBtnApprove, disabledBtnCancelApprove, disabledBtnNotApprove,allowAddingAttachment, loadingWithDataSource,
            allowHistoryApproval,isActionPermission,
        } = this.props;
        const _attachments = dataAttachments ? dataAttachments : getAttachments;
        const _history = dataHistory ? dataHistory : getHistorys;
        const _currentStatus = this._getStatusInfo(selectedRow ? selectedRow.ApprovalStatus : 0);
        const _fullTop = Config.isMobile ? classes.mobileScreen : classes.desktopScreen;

        const selectedRowKeys = selectedRow ? [selectedRow['VoucherID']] : [];
        const utc = selectedRow && selectedRow.ApprovalDate && (/t.*?z/i).test(selectedRow.ApprovalDate); // Contains both t and z (allow both uppercase and lowercase)
        let groupingColumn = "";
        if(FormID === "W77F1012" || FormID === "W77F1014") {
            groupingColumn = <Column
                groupCellTemplate={(cellElement, cellInfo) => {
                    return cellElement.innerHTML = cellInfo.displayValue ? cellInfo.displayValue : Config.lang("Khong_ten");
                }}
                dataField={"ApprovalFlowName"} groupIndex={0}/>
        }
        return (
            <React.Fragment>
                {
                    allowHistoryApproval &&
                    <W84F3005 open={showW84F3005Popup} onClose={e => this.setState({ showW84F3005Popup: false })} FormID={FormID} TransID={w84F3005PopupData.TransID} />

                }

                <ApprovalsPopover
                    ref={ref => this.refApprovalPopover = ref}
                    open={showApprovals} onClose={this.onClosePopoverApproval}
                    anchorEl={anchorEl} type={type}
                    labelBtnApprove={labelBtnApprove}
                    labelBtnNotApprove={labelBtnNotApprove}
                    errorNote={errorNote}
                    onApproval={notes => this.onApproval(notes)}
                    onCancel={notes =>this.onReject(notes)}
                />
                {isOpenW09F2081Popup && (
                    <W09F2081Popup
                        saveApprovals={this.saveApprovals}
                        data={W09F2081PopupData}
                        open={isOpenW09F2081Popup}
                        onClose={this.onCloseReceiverPopup}
                    />
                )}
                <Modal open={showApprovalPopup} maxWidth={"sm"} fullWidth={true}
                       onClose={this.onCloseApprovalModal}
                >
                    <ApprovalPopup
                        type={type}
                        loading={approving}
                        labelBtnApprove={labelBtnApprove}
                        labelBtnNotApprove={labelBtnNotApprove}
                        errorNote={errorNote}
                        onApproval={this.onApproval}
                        onCancel={this.onReject}
                    />
                </Modal>
                <Modal style={{zIndex: 1504}} open={showFileViewer} maxWidth={"lg"} fullWidth={true}
                       paperProps={{style: {height: '100%'}}}
                       title={Config.lang('Xem_tap_tin')}
                       type={"file"}
                       download={{
                           filePath: fileOpened.URL || "",
                           FileName: fileOpened.FileName,
                           fileType: fileOpened.FileExt
                       }}
                       onClose={this.onCloseViewFile}>
                    <FileViewer fileName={fileOpened.FileName} filePath={fileOpened.URL} fileType={fileOpened.FileExt} height={'100%'} />
                </Modal>
                <div style={{position: 'relative'}}>
                    <div ref={ref => this.panelLeft = ref} className={classes.content + ' ' + (open ? classes.contentDrawer : "")}>
                        {(showFilters || typeof showFilters === "undefined") && <FormGroup>
                            <Row className={"display_row align-center valign-middle"}>
                                <Col xs={12} sm={open ? 12 : 8} md={open ? 12 : 6} lg={open ? 8 : 6}>
                                    {filterRender ? (
                                        typeof filterRender === "function" ? filterRender() : filterRender
                                    ) : (
                                        this.renderDefaultFilter()
                                    )}
                                </Col>
                            </Row>
                        </FormGroup>}
                        <div>
                            <GridContainer
                                style={{border: 'none'}}
                                totalItems={dataSource && dataSource.total ? dataSource.total : (getGridApproval && getGridApproval.total ? getGridApproval.total : 0)}
                                dataSource={dataSource && dataSource.rows ? dataSource.rows : (getGridApproval && getGridApproval.rows ? getGridApproval.rows : [])}
                                loading={gridLoading || loading || loadingWithDataSource}
                                disabled={disabled || approving}
                                itemPerPage={this.filter.limit}
                                skipPerPage={this.filter.skip}
                                showBorders={false}
                                height={Config.getHeightGrid() - (showFilters || typeof showFilters === "undefined" ? 75 : 0)}
                                showColumnLines={false}
                                showColumnHeaders={false}
                                hoverStateEnabled={true}
                                typePaging={"remote"}
                                keyExpr={'VoucherID'}
                                noDataText={Config.lang("Khong_co_du_lieu")}
                                selectedRowKey={selectedRowKeys}
                                onChangePage={this.onChangePage}
                                onChangePerPage={this.onChangePerPage}
                                onDbCellClick={(e) => {
                                    if (!singleClick) {
                                        this.onSelected(e);
                                    }
                                }}
                                onCellClick={(e) => {
                                    if (singleClick) {
                                        this.onSelected(e);
                                    }
                                }}
                            >
                                <Column dataField={"Name"} minWidth={300} width={"20%"} cellRender={this.renderItem} />
                                <Column dataField={"Description"} width={"55%"} cellRender={this.renderDescription} />
                                <Column
                                    dataField={"ApprovalStatus"}
                                    cellRender={this.renderStatus}
                                    alignment={"center"}
                                    minWidth={100}
                                    width={"10%"}
                                    visible={!open}
                                />
                                <Column
                                    minWidth={150}
                                    width={"15%"}
                                    dataField={"Action"}
                                    cellRender={this.renderAction}
                                    visible={!open}
                                />
                            </GridContainer>
                        </div>
                    </div>
                    <div>
                        <LoadPanel
                            shadingColor="rgba(0,0,0,0.4)"
                            position={{my: 'center', of: "#drawer-form-approval-info"}}
                            visible={disabled || loading}
                            showIndicator={true}
                            shading={true}
                            showPane={true}
                        />
                        <Drawer
                            className={classes.drawer}
                            variant={window.innerWidth < 768 ? "temporary" : "persistent"}
                            anchor="right"
                            open={open}
                            PaperProps={{
                                id: "drawer-form-approval-info",
                                ref: ref => this.drawer = ref
                            }}
                            classes={{
                                paper: classes.drawerPaper + " "+ _fullTop,
                            }}
                        >
                            <div className={classes.drawerHeader}>
                                <div className={"display_row align-center"}>
                                    <ButtonIcon
                                        circular
                                        viewType={'filled'}
                                        size={'tiny'}
                                        color={'primary'}
                                        name="Close"
                                        className={"mgr5"}
                                        onClick={this.onDrawerClose}
                                    />
                                    <Status data={_currentStatus} />
                                    {selectedRow && (Number(selectedRow.ApprovalStatus) === 1 || Number(selectedRow.ApprovalStatus) === 100) &&
                                    <span className={"mgl5"} style={{color: "#222B45", fontWeight: 200}}>
                                            <span style={{textTransform: 'lowercase'}}>{Config.lang("Boi")}</span>&nbsp;
                                        <b>{selectedRow.ApproverName}</b>&nbsp;
                                        {Config.convertDate(selectedRow.ApprovalDate, '', "DD/MM/YYYY", utc)}
                                        </span>
                                    }
                                </div>
                                <div>
                                    {isActionPermission &&
                                        selectedRow &&
                                        selectedRow.VoucherID &&
                                        (Number(selectedRow.ApprovalStatus) === 1 ||
                                            Number(selectedRow.ApprovalStatus) === 100) &&
                                        selectedRow.IsApprover === 1 &&
                                        !disabledBtnCancelApprove && (
                                            <Button
                                                label={
                                                    labelBtnCancelApprove
                                                        ? labelBtnCancelApprove
                                                        : Config.lang("Bo_duyet")
                                                }
                                                viewType={"filled"}
                                                color={"danger"}
                                                disabled={disabledApproval}
                                                className={"mgr5"}
                                                startIcon={"Deny"}
                                                onClick={this.onCancelApprovals}
                                            />
                                        )}
                                    {isActionPermission &&
                                        selectedRow &&
                                        selectedRow.VoucherID &&
                                        Number(selectedRow.ApprovalStatus) !== 1 &&
                                        Number(selectedRow.ApprovalStatus) !== 100 && (
                                            <div className="display_row">
                                                {!disabledBtnApprove && (
                                                    <Button
                                                        label={labelBtnApprove ? labelBtnApprove : Config.lang("Duyet")}
                                                        viewType={"filled"}
                                                        color={"primary"}
                                                        disabled={
                                                            (selectedRow &&
                                                                (Number(selectedRow.ApprovalStatus) === 1 ||
                                                                    Number(selectedRow.ApprovalStatus) === 100)) ||
                                                            disabledApproval
                                                        }
                                                        className={"mgr5"}
                                                        startIcon={"Approval"}
                                                        onClick={() => this.onApprovalModal("approve")}
                                                    />
                                                )}
                                                {(FormID !== "W09F1005" || !disabledBtnNotApprove) && (
                                                    <Button
                                                        label={
                                                            labelBtnNotApprove
                                                                ? labelBtnNotApprove
                                                                : Config.lang("Tu_choi")
                                                        }
                                                        viewType={"filled"}
                                                        color={"danger"}
                                                        disabled={
                                                            (selectedRow &&
                                                                (Number(selectedRow.ApprovalStatus) === 1 ||
                                                                    Number(selectedRow.ApprovalStatus) === 100)) ||
                                                            disabledApproval
                                                        }
                                                        startIcon={"Close"}
                                                        onClick={() => this.onApprovalModal("cancel")}
                                                    />
                                                )}
                                            </div>
                                        )}
                                </div>
                            </div>
                            <Divider />
                            <div className={classes.scrollContent}>
                                {dataForm && formInfoRender && (
                                    <div
                                        className={"mgb15 " + classes.panel}
                                        style={{ maxHeight: "calc(100% - 150px)" }}
                                    >
                                        {formInfoRender(dataForm)}
                                    </div>
                                )}
                                <Divider />
                                <div className={classes.panel}>
                                    <TabContainer tabIndex={tab}>
                                        <TabHeader centered>
                                            {dataDetail && detailRender && (
                                                <TabItem
                                                    index={0}
                                                    label={Config.lang("Chi_tiet")}
                                                    onClick={this.onChangeTab}
                                                />
                                            )}
                                            <TabItem
                                                index={1}
                                                label={Config.lang("Dinh_kem")}
                                                onClick={this.onChangeTab}
                                            />
                                            <TabItem
                                                index={2}
                                                label={Config.lang("Lich_su")}
                                                onClick={this.onChangeTab}
                                            />
                                        </TabHeader>
                                        {dataDetail && detailRender && (
                                            <TabPanel index={0}>{detailRender(dataDetail)}</TabPanel>
                                        )}
                                        <TabPanel index={1}>
                                            {allowAddingAttachment ? (
                                                <>
                                                    <Attachments
                                                        isAttachInfo
                                                        voucherID={selectedRow && selectedRow.VoucherID}
                                                        ref={ref => (this.attRef = ref)}
                                                        showButton={true}
                                                        files={dataOldAttachments}
                                                        disabled={disabled || uploading || loading}
                                                        maxLength={5}
                                                        uploading={loading || uploading}
                                                        onUploading={this.onUploading}
                                                        onChanged={this.onChangeAttachments}
                                                    />
                                                    <ButtonIcon
                                                        circular
                                                        viewType={"text"}
                                                        name={"Attachment"}
                                                        size={"large"}
                                                        disabled={loading || disabled || uploading}
                                                        onClick={this.onAttachment}
                                                    />
                                                </>
                                            ) : dataAttachments && attachRender ? (
                                                attachRender(dataAttachments)
                                            ) : (
                                                <>
                                                    {_attachments && _attachments.length > 0 ? (
                                                        <div className={"display_row align-center"}>
                                                            {_attachments.map((e, indx) => {
                                                                const ext = e?.FileExt
                                                                    ? e.FileExt.replace(".", "")
                                                                    : e?.FileExt;
                                                                return (
                                                                    <Col
                                                                        key={indx}
                                                                        xs={6}
                                                                        sm={6}
                                                                        md={4}
                                                                        lg={3}
                                                                        style={{ height: 165, padding: 10 }}
                                                                    >
                                                                        <div
                                                                            className={classes.attachItem}
                                                                            onClick={() => this.onViewFile(e)}
                                                                        >
                                                                            <div className={"att-wrap-icon"}>
                                                                                <Image
                                                                                    style={{ height: 50 }}
                                                                                    src={this._getIconFile(
                                                                                        ext + ".svg"
                                                                                    )}
                                                                                />
                                                                            </div>
                                                                            <div
                                                                                style={{
                                                                                    textAlign: "center",
                                                                                    fontSize: 12,
                                                                                    maxHeight: "42%",
                                                                                    padding: "0 10px",
                                                                                    wordBreak: "break-all",
                                                                                }}
                                                                            >
                                                                                {e.FileName}
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                );
                                                            })}
                                                        </div>
                                                    ) : (
                                                        <div className={"display_row align-center valign-middle"}>
                                                            <Typography type={"h2"} color={"secondary"}>
                                                                {Config.lang("Khong_co_du_lieu")}
                                                            </Typography>
                                                        </div>
                                                    )}
                                                </>
                                            )}
                                        </TabPanel>
                                        <TabPanel index={2}>
                                            {_history && historyRender ? (
                                                historyRender(_history)
                                            ) : (
                                                <>
                                                    <GridContainer
                                                        style={{ border: "none" }}
                                                        dataSource={_history}
                                                        keyExpr={"UserID"}
                                                        showBorders={false}
                                                        showColumnLines={false}
                                                        showColumnHeaders={false}
                                                        noDataText={Config.lang("Khong_co_du_lieu")}
                                                        grouping={{ autoExpandAll: true }}
                                                    >
                                                        {groupingColumn}
                                                        <Column width={"100%"} cellRender={this.renderCellHistory} />
                                                    </GridContainer>
                                                </>
                                            )}
                                        </TabPanel>
                                    </TabContainer>
                                </div>
                            </div>
                        </Drawer>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
Approvals.defaultProps = {
    //isShowCboDepartment:true,
    isActionPermission:true,
    isShowCboRegisterType:true
};
Approvals.propTypes = {
    dataSource: PropTypes.any, //Ex: {rows: [], total: 10}
    loading: PropTypes.bool,
    labelBtnApprove: PropTypes.string, //custom label for button approval..
    labelBtnCancelApprove: PropTypes.string, //custom label for button cancel approval..
    labelBtnNotApprove: PropTypes.string, //custom label for button reject..
    disabledBtnApprove: PropTypes.bool,
    disabledBtnCancelApprove: PropTypes.bool,
    disabledBtnNotApprove: PropTypes.bool,
    showFilters: PropTypes.bool, //allow filter component...
    FormID: PropTypes.string.isRequired, //Form ID with every screen..
    singleClick: PropTypes.bool, //Enable one click in row, not set or false is allow double click..
    disabled: PropTypes.bool,
    loadingWithDataSource: PropTypes.bool,
    selectedRowKey: PropTypes.any,
    opened: PropTypes.bool,
    disabledApproval: PropTypes.any,
    allowAddingAttachment: PropTypes.bool,

    dataFilter: PropTypes.object, //data filtered outside component..
    filterRender: PropTypes.any, //render custom filter...
    dataForm: PropTypes.any,
    formInfoRender: PropTypes.func, //render form info ...
    dataDetail: PropTypes.any,
    detailRender: PropTypes.func, //render detail tab (panel 2)..
    dataAttachments: PropTypes.any, //list data attachments (info). not set will autoload with default..
    attachRender: PropTypes.func, //render list attachments (panel 2)...
    dataHistory: PropTypes.any, //list data history (info). not set will autoload with default..
    historyRender: PropTypes.func, //render list historys (panel 2)...

    onTextFilterChanged: PropTypes.func,
    onFilterChanged: PropTypes.func,
    onApproval: PropTypes.func,
    onReject: PropTypes.func,
    onSelectionChanged: PropTypes.func,
    onDrawerClosed: PropTypes.func,
    onShowReceiver: PropTypes.func,
    disableEscapeKeyDown: PropTypes.bool,
    allowHistoryApproval:PropTypes.bool, //show history approval popover
    isShowCboDepartment: PropTypes.bool,
    isShowCboRegisterType: PropTypes.bool,

    isActionPermission: PropTypes.bool,
    parentProps: PropTypes.any,
    reloadGrid: PropTypes.func
};

export default compose(connect((state) => ({
        getCboStatus: state.approvals.getCboStatus,
        getCboDepartments: state.general.getCboDepartments,
        getGridApproval: state.approvals.getGridApproval,
        getAttachments: state.approvals.getAttachments,
        getHistorys: state.approvals.getHistorys,
        getRegisterTypes: state.approvals.getRegisterTypes,
    }),
    (dispatch) => ({
        approvalActions: bindActionCreators(ApprovalActions, dispatch),
        generalActions: bindActionCreators(generalActions, dispatch)
    }), null, {forwardRef: true}
),withStyles(styles, {withTheme: true}))(Approvals);
