/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 11/20/2019
 * @Example 
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button} from "@material-ui/core";

class ButtonBasic extends Component {

    onClick() {
        if(this.props.onClick) this.props.onClick();
    }

    render() {
        const {name, disabled, className, ...props} = this.props;
        const children = name ? name : this.props.children;
        const style = props && props.style ? props.style : {};

        return (
            <Button {...props}
                    style={{
                        minHeight: 33,
                        minWidth: 40,
                        ...style
                    }}
                    margin={'dense'}
                    disabled={disabled}
                    className={className}
                    type="button"
                    onClick={()=>this.onClick()}>
                {children}
            </Button>
        );
    }
}

ButtonBasic.propTypes = {
    name: PropTypes.string,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func,
};

export default ButtonBasic;
