/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 3/28/2020
 * @Example
 */

import React, {PureComponent} from 'react';

import PropTypes from "prop-types";
import {DropDownBox, LoadIndicator, Template, TextBox} from "devextreme-react";
import Config from "../../../../config";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {Column} from "devextreme-react/data-grid";
import GridContainer from "../../../grid-container/grid-container";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FormHelperText } from "@material-ui/core";
import _ from "lodash";

class LoadMore extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            value: props.multiple && props.value ? (Array.isArray(props.value) ? props.value : [props.value]) : (Array.isArray(props.value) ? props.value[0] : props.value ? props.value : null),
            selectedRowKeys: props.value,
            searchValue: null,
            opened: false,
            shrink: props.shrink,
        };
        this.filter = {
            skip: props.skip ? props.skip : 0,
            limit: props.limit ? props.limit : 10
        };
        this.inputSearch = null;
        this.dropdownBox = null;
        this.storeValueNotSet = [];
        this.dataGrid = null;
        this.timer = null;
        this.value = null;
        this.isDataFirst = true;
        this.isClearing = false;
        this.isSelecting = false;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(this.state.value) !== JSON.stringify(this.props.value) && JSON.stringify(prevProps.value) !== JSON.stringify(this.props.value)) {
            this.setState({
                value: this.props.value,
                selectedRowKeys: this.props.value
            });
        }
        // if (this.dropdownBox && this.dropdownBox.instance) {
        //     if (this.focusTimer) clearTimeout(this.focusTimer);
        //     this.focusTimer = setTimeout(() => {
        //         if (this.dropdownBox && this.dropdownBox.instance.option('opened'))
        //             this.dropdownBox.instance.focus();
        //     }, 300);
        // }
    }

    syncDataGridSelection = (e) => {
        const value = e.value;
        if (_.isEqual(e.previousValue, e.value)) return;
        const isValue = Array.isArray(value) ? value.length > 0 : !!value;
        if (isValue) return;
        const {onInputChanged, onValueChanged} = this.props;
        this.isClearing = true;
        this.setState({
            value: value,
            selectedRowKeys: value,
            searchValue: null
        }, () => {
            if (onInputChanged && !isValue && !this.isDataFirst) onInputChanged({target: {value: null}});
            const data = {
                value: value
            };
            if (onValueChanged) onValueChanged(data);
        });
    };

    dataGrid_onSelectionChanged = (e) => {
        if (this.isSelecting) {
            setTimeout(() => {
                this.isSelecting = false;
            }, 200);
            return false;
        } else {
            this.isSelecting = true;
            const {selectedRowsData}         = e;
            const {multiple, onValueChanged} = this.props;

            let newValue = null;
            if (multiple) {
                newValue = selectedRowsData;
            } else if (selectedRowsData.length > 0) {
                newValue = selectedRowsData[0];
            }

            const data = {
                value: newValue
            };

            if (newValue) {
                const {multiple, closeWhenSelected} = this.props;
                // let isSearch = !!this.state.searchValue;
                this.setState({
                    value:           newValue,
                    selectedRowKeys: e.selectedRowKeys,
                    // searchValue: null,
                    shrink:          Array.isArray(newValue) ? newValue.length > 0 : !!newValue || !!this.props.shrink,
                }, () => {
                    if (!multiple && closeWhenSelected && this.dropdownBox && this.dropdownBox.instance) {
                        this.dropdownBox.instance.close();
                    }
                    // isSearch = false;
                    if (onValueChanged) onValueChanged(data);
                });
            } else {
                // this.setState({value: null, selectedRowKeys: []});
                // if (onValueChanged) onValueChanged(data);
            }
        }

    };

    onContentReady = (e) => {
        const {onContentReady} = this.props;
        if (onContentReady) onContentReady(e);
        if (this.selectTimer) clearTimeout(this.selectTimer);
        this.selectTimer = setTimeout(() => {
            this.isSelecting = false;
        }, 300);
    };

    loadMore = (cb) => {
        const {dataSource, totalItems, onLoadMore} = this.props;
        const _totalItems = dataSource && dataSource.total ? dataSource.total : totalItems
        if (_totalItems) {
            if (this.filter.skip + this.filter.limit < _totalItems) {
                this.filter.skip = this.filter.skip + this.filter.limit;
                if (onLoadMore) onLoadMore({
                    skip: this.filter.skip,
                    limit: this.filter.limit,
                })
            }
            if (cb) cb();
        }
    };

    contentRender = () => {
        const { loading, totalItems, children, valueExpr, displayExpr, height, gridProps,
            keyExpr, showColumnHeaders, multiple, showCheckBoxMode, itemRender, dataSource, acceptCustomValue
        } = this.props;
        let _dataSource = dataSource && dataSource.rows ? dataSource.rows : dataSource;
        const _totalItems = dataSource && dataSource.total ? dataSource.total : totalItems;
        let {selectedRowKeys, value, searchValue} = this.state;
        let _selectedRowKeys = [];
        const key = keyExpr ? keyExpr : valueExpr;
        if (selectedRowKeys && key) {
            if (Array.isArray(selectedRowKeys)) {
                _selectedRowKeys = selectedRowKeys.map(item => item[key] ? item[key] : item);
            } else if (typeof selectedRowKeys === "object" && selectedRowKeys[key]){
                _selectedRowKeys = [selectedRowKeys[key]]
            } else {
                _selectedRowKeys = [selectedRowKeys];
            }
        }
        if (value) {
            const _value = multiple ? value : [value];
            const _data = _dataSource.map(data => data[key] && data[key]);
            this.storeValueNotSet = _value.filter(v => {
                if (typeof v === "object") {
                    return v[key] && _data.indexOf(v[key]) < 0
                } else {
                    return v && _data.indexOf(v) < 0
                }
            });
            let dataValueNotSet = [];
            this.storeValueNotSet.forEach(item => {
                if (typeof item === "object") {
                    const tmp = {
                        [key]: item[key],
                        [displayExpr]: item[displayExpr]
                    };
                    dataValueNotSet.push(tmp);
                } else {
                    const tmp = {
                        [key]: item,
                        [displayExpr]: item
                    };
                    dataValueNotSet.push(tmp);
                }
            });
            _dataSource = dataValueNotSet.concat(_dataSource);
        }

        return (
            <>
                {acceptCustomValue && <TextBox ref={ref => this.inputSearch = ref} value={searchValue} stylingMode={"outlined"} onInput={this.onInput}/>}
                <GridContainer
                    reference={ref => this.dataGrid = ref}
                    loadMore={this.loadMore}
                    columnResizingMode={'widget'}
                    allowColumnResizing={true}
                    columnAutoWidth={true}
                    width={"100%"}
                    height={ height || 300}
                    hoverStateEnabled={true}
                    style={{border: 'none'}}
                    dataSource={_dataSource}
                    keyExpr={key}
                    showRowLines={false}
                    showBorders={false}
                    selection={{
                        allowSelectAll: !!multiple,
                        mode: multiple ? "multiple" : "single",
                        selectAllMode: "allPages",
                        showCheckBoxesMode: showCheckBoxMode === true ? "always" : showCheckBoxMode
                    }}
                    paging={{pageSize: _dataSource.length, enabled: false}}
                    pager={{visible: false}}
                    showColumnLines={false}
                    showColumnHeaders={showColumnHeaders}
                    noDataText={Config.lang("DHR_Khong_co_du_lieu")}
                    loading={loading}
                    selectedRowKey={_selectedRowKeys}
                    onSelectionChanged={this.dataGrid_onSelectionChanged}
                    onContentReady={this.onContentReady}
                    {...gridProps}
                >
                    {children && children}
                    {!children && valueExpr && <Column
                        dataField={valueExpr}
                        alignment={"center"}
                        visible={false}
                    />}
                    {!children && keyExpr && keyExpr !== valueExpr && <Column
                        dataField={keyExpr}
                        alignment={"center"}
                        visible={false}
                    />}
                    {!children && <Column
                        {...(typeof displayExpr === "string" ? {dataField: displayExpr} : {})}
                        cellRender={itemRender}
                    />}
                </GridContainer>
                <div className={"display_row align-center valign-middle"}>
                    <ExpandMoreIcon color={_dataSource.length < _totalItems ? "action" : "disabled"} />
                </div>
            </>
        );
    };

    onInput = (e) => {
        const {onInputChanged, dropdownProps, skip, limit} = this.props;
        const event = e.event ? e.event : e;
        if (this.isClearing) {
            this.isClearing = false;
            return;
        }
        if (this.timer) clearTimeout(this.timer);
        const _search = event.target.value || "";
        this.timer = setTimeout(() => {
            this.setState({searchValue: _search});
            this.isDataFirst = !_search;
            this.filter.skip = skip ? skip : 0;
            this.filter.limit = limit ? limit : 50;
            if (dropdownProps && dropdownProps.onInput) dropdownProps.onInput(event);
            if (onInputChanged) onInputChanged(event);
            // if (onValueChanged && !multiple) onValueChanged({value: null});
        }, 1000);
    };

    onKeyUp = (e) => {
        if (!e || !e.event) return;
        const evt = e.event;
        if (evt.keyCode === 13) {
            this.setState({searchValue: evt.target.value || ""});
            if (this.timer) clearTimeout(this.timer);
            const {onInputChanged, dropdownProps, skip, limit} = this.props;
            this.filter.skip = skip ? skip : 0;
            this.filter.limit = limit ? limit : 50;
            if (dropdownProps && dropdownProps.onInput) dropdownProps.onInput(evt);
            if (onInputChanged) onInputChanged(evt);
            // if (onValueChanged && !multiple) onValueChanged({value: null});
        }
    };

    onOpened = (e) => {
        const {onOpened, dropdownProps} = this.props;
        if (this.inputSearch) setTimeout(() => this.inputSearch.instance.focus(), 200);
        if (dropdownProps && dropdownProps.onOpened) dropdownProps.onOpened(e);
        if (onOpened) onOpened(e);
    };

    onClosed = (e) => {
        const {onClosed} = this.props;
        const {value} = this.state;
        this.setState({shrink: Array.isArray(value) ? value.length > 0 : !!value || !!this.props.shrink});
        e && e.component.blur();
        if (onClosed) onClosed(e);
    };

    onFocusIn = (e) => {
        const {dropdownProps} = this.props;
        if (dropdownProps && dropdownProps.readOnly) return false;
        this.setState({shrink: true});
        if (!e.component.option("opened")) {
            if (this.dropdownBox) {
                setTimeout(() => {
                    this.dropdownBox.instance.open();
                });
            } else {
                e.component.open();
            }
        }
    };

    renderLoadIndicator = () => {
        const {loading} = this.props;
        return (
            <div className={"display_row align-center"} style={{height: "100%"}}>
                <LoadIndicator visible={loading} width={24} height={24} />
            </div>
        );
    };

    render() {
        const {dataSource, label, style, keyExpr, valueExpr, displayExpr, required, placeholder, loading,
            showClearButton, stylingMode, height, className, dropdownProps, InputLabelProps, margin, disabled, error,
            multiple, helperText, reference, fieldRender
        } = this.props;
        let {value, shrink, opened} = this.state;

        const variant = stylingMode === 'underlined' ? "standard" : (stylingMode === 'filled' ? 'filled' : 'outlined');
        const _shrink = (Array.isArray(value) ? value.length > 0 : !Config.isEmpty(value, true)) || shrink;
        let _dataSource = dataSource && dataSource.rows ? dataSource.rows : dataSource;
        const key = keyExpr ? keyExpr : valueExpr;

        if (value) {
            const _value = multiple ? value : [value];
            const _data = _dataSource.map(data => data?.[key] && data[key]);
            this.storeValueNotSet = _value.filter(v => {
                if (typeof v === "object") {
                    return v[key] && _data.indexOf(v[key]) < 0
                } else {
                    return v && _data.indexOf(v) < 0
                }
            });
            let dataValueNotSet = [];
            this.storeValueNotSet.forEach(item => {
                if (typeof item === "object") {
                    const tmp = {
                        [key]: item[key],
                        [displayExpr]: item[displayExpr]
                    };
                    dataValueNotSet.push(tmp);
                } else {
                    const tmp = {
                        [key]: item,
                        [displayExpr]: item
                    };
                    dataValueNotSet.push(tmp);
                }
            });
            _dataSource = dataValueNotSet.concat(_dataSource);
        }

        if (multiple) {
            let _value = [];
            Array.isArray(value) && value.forEach(_v => {
                if (typeof _v === "object") {
                    if (_v[keyExpr] && !_v[displayExpr]) {
                        _v[displayExpr] = _v[keyExpr];
                    }
                    _value.push(_v);
                } else {
                    const rowData = _dataSource && _dataSource.filter((data) => data[valueExpr] === _v);
                    _value.push(rowData && rowData.length > 0 ? rowData[0] : {
                        [keyExpr]: _v,
                        [displayExpr]: _v
                    });
                }
            });
            value = _value.map(val => val[key] && val[key]);
        } else {
            if (value) {
                const rowData = _dataSource && _dataSource.filter((data) => data[valueExpr] === (typeof value === "object" ? value[valueExpr] : value));
                value = rowData && rowData[0] ? rowData[0] : value;
                if (!value[displayExpr]) {
                    value[displayExpr] = value[valueExpr] ? value[valueExpr] : (value[keyExpr] ? value[keyExpr] : Config.lang("DHR_Trong"));
                }
            }
            value = value && value[key] ? value[key] : value;
        }

        return (
            <React.Fragment>
                <FormControl
                    error={!!error && !disabled && (Array.isArray(value) ? value.length <= 0 : Config.isEmpty(value, true))}
                    className={className}
                    // classes={{root: classes.root}}
                    variant={variant}
                    disabled={disabled || loading}
                    margin={margin ? margin : "dense"}
                    fullWidth={true} style={style}>
                    {label &&
                    <InputLabel
                        required={required}
                        className={opened ? "Mui-focused" : ""}
                        shrink={_shrink}
                        disabled={disabled || loading}
                        {...InputLabelProps}
                    >{label}</InputLabel>}
                    <DropDownBox
                        // opened={opened}
                        ref={ref => {
                            this.dropdownBox = ref;
                            reference && reference(ref);
                        }}
                        // acceptCustomValue={acceptCustomValue && !multiple}
                        value={value}
                        valueExpr={key}
                        deferRendering={true}
                        displayExpr={displayExpr}
                        showClearButton={showClearButton}
                        stylingMode={stylingMode ? stylingMode : "outlined"}
                        height={height}
                        isValid={!error || disabled || (Array.isArray(value) ? value.length > 0 : !Config.isEmpty(value, true))}
                        disabled={disabled || loading}
                        placeholder={placeholder}
                        dataSource={_dataSource}
                        onInput={this.onInput}
                        onOpened={this.onOpened}
                        onClosed={this.onClosed}
                        // onFocusIn={this.onFocusIn}
                        onKeyUp={this.onKeyUp}
                        fieldRender={this.fieldRender}
                        {...(fieldRender ? {fieldRender: (e) => fieldRender(e)} : {})}
                        openOnFieldClick={true}
                        // onContentReady={(e) => {if (e.component.option('opened')) e.component.focus();}}
                        onValueChanged={this.syncDataGridSelection}
                        {...dropdownProps}
                        contentRender={this.contentRender}
                        {...(!disabled && loading ? {dropDownButtonTemplate: "conditionalIcon"} : {})}
                    >
                        <Template name='conditionalIcon' render={this.renderLoadIndicator} />
                    </DropDownBox>
                    <FormHelperText>{typeof error === "string" && !value ? error : helperText}</FormHelperText>
                </FormControl>
            </React.Fragment>
        )
    }
}

LoadMore.defaultProps = {
    showColumnHeaders: false,
    acceptCustomValue: true,
    closeWhenSelected: true,
    showCheckBoxMode: "always"
};

LoadMore.propTypes = {
    reference: PropTypes.func,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    dataSource: PropTypes.any,
    skip: PropTypes.number,
    limit: PropTypes.number,
    totalItems: PropTypes.number,
    label: PropTypes.string,
    value: PropTypes.any,
    error: PropTypes.any,
    className: PropTypes.string,
    dropdownProps: PropTypes.any,
    InputLabelProps: PropTypes.any,
    keyExpr: PropTypes.string,
    valueExpr: PropTypes.string,
    displayExpr: PropTypes.any,
    placeholder: PropTypes.string,
    showClearButton: PropTypes.bool,
    stylingMode: PropTypes.string,
    height: PropTypes.any,
    acceptCustomValue: PropTypes.bool,
    closeWhenSelected: PropTypes.bool,
    showColumnHeaders: PropTypes.bool,
    loading: PropTypes.bool,
    multiple: PropTypes.bool,
    showCheckBoxMode: PropTypes.any,
    helperText: PropTypes.string,
    fieldRender: PropTypes.any,
    itemRender: PropTypes.any,

    onOpened: PropTypes.func,
    onClosed: PropTypes.func,
    onValueChanged: PropTypes.func,
    onInputChanged: PropTypes.func,
    onLoadMore: PropTypes.func,
};

export default LoadMore;
