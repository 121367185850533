/**
 * @copyright 2021 @ DigiNet
 * @author tranghoang
 * @src src/components/communication/index.js
 */

import React, {useEffect, useState} from 'react';
import { Image, Row, Col} from 'react-bootstrap';
import {connect} from "react-redux";
import {bindActionCreators, compose} from "redux";
import * as W00F6000Actions from "../../redux/W2X/W25F2303/W25F2303_actions";
import { Typography } from 'diginet-core-ui/components';

const logoImg = require('../../assets/images/general/logodhr.svg');

const Communication = (props) => {

    const [data, setData] = useState( null);

    useEffect(()=>{

        // string of a URL
        const URL = new URLSearchParams(window.location.search);

        const params = {
            InterviewFileID: URL.get('InterviewFileID'),
            CanConfirmStatusID:URL.get('CanConfirmStatusID')
        };

        // call api confirm email
        props.W00F6000Actions.confirmEmail(params,(err, data)=>{
            // if success, off loading then show message.
            if(data) setData(data);
            // if fail, off loading and show error
            else setData({...data, Message:err.message})
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);


    return(
        <div className="login-container page-login">
            <div className="login-bound">
                <Image className="logo" src={logoImg} style={{marginBottom:20}}/>
                <Typography variant={"h4"}>{!data ? "Loading..." : data.Message}</Typography>
            </div>

            <div className={'login-wrapper-footer'}>
                <Row className={'login-footer'}>
                    <Col sm={6} md={6} xs={12}>
                        <span>DIGINET CORPORATION</span>
                        <label>© Copyright 2020 DigiNet Corporation.</label>
                    </Col>

                    <Col sm={6} md={6} xs={12}>
                        <Image src={require('../../assets/images/logo.png')}/>
                    </Col>
                </Row>
            </div>
        </div>
    )
};

export default compose(
    connect(null,
        (dispatch) => ({
            W00F6000Actions: bindActionCreators(W00F6000Actions, dispatch),
        })
    ),
)(Communication);

