/**
 * @copyright 2019 @ DigiNet
 * @author tranghoang
 * @create 2019/08/09 10:37
 * @update 2017/08/12 10:37
 */

import React from "react";
import Config from "../../config";
import { browserHistory } from "react-router";

import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import * as homeActions from "../../redux/home/home_actions";
import * as generalActions from "../../redux/general/general_actions";

import UserImage from "../common/user/user-image";
import UserName from "../common/user/user-name";
import W00F1000 from "../W0X/W00/W00F1000/W00F1000";

import moment from "moment";
import Slider from "react-slick";
import _ from "lodash";
import { Animated } from "react-animated-css";

import {
    Typography,
    Badge,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    AccordionGroup,
    ButtonIcon,
    Row,
    Col,
} from "diginet-core-ui/components";
import { useTheme, makeStyles } from "diginet-core-ui/theme";
import IconMenu from "diginet-core-ui/icons/menu/v2";

const {
    colors: {
        white,
        system: { active: clSystemActive },
        text: { sub: clTextSub },
        line: { category: clLineCategory },
    },
    spacing,
} = useTheme();

const drawerWidth = "40%";
const heightHeader = 50;
const widthItem = spacing(40);
const spacingItem = 5; // spacingItem*root;
const useStyles = makeStyles(theme => ({
    collapseBoard: {
        boxShadow: "initial !important",
        "& .DGN-UI-Divider": {
            opacity: 0,
        },
        maxWidth: widthItem * 4,
        margin: "auto !important",
        [theme.breakpoints.down("md")]: {
            maxWidth: widthItem * 3,
        },
    },
    ExpansionPanelSummary: {
        backgroundColor: "transparent !important",
    },
    Animated: {
        height: "100%",
        width: "100%",
        backgroundColor: white,
        borderRadius: spacing(4),
        padding: spacing([6, 2, 5]),
        border: `1px solid ${clLineCategory}`,
        cursor: "pointer",
        transition: "all 0.2s ease-in-out",
        position: "relative",
        [theme.breakpoints.down("md")]: {
            maxWidth: widthItem,
        },
        "&:hover": {
            boxShadow: "0 4px 8px rgba(17, 29, 94, 0.25)",
        },
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        [theme.breakpoints.down("sm")]: {
            zIndex: "1502 !important",
        },
    },
    drawerHeader: {
        // height: heightHeader,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexWrap: "wrap",
        padding: 15,
        margin: "0 0 0 -15px",
        marginBottom: 2,
        background: "#F7F9FC",
        boxShadow: "0px 2px 2px rgba(0, 0, 0, 0.25)",
    },
    drawerPaper: {
        width: drawerWidth,
        [theme.breakpoints.down(768)]: {
            width: "80%",
        },
        [theme.breakpoints.down(480)]: {
            width: "100%",
        },
        backgroundColor: white,
        opacity: 1,
        top: "50px !important",
        // zIndex: 1502,
        padding: 15,
        paddingRight: 0,
        paddingTop: 0,
        overflow: "hidden",
        height: "calc(100% - 50px)",
    },
    scrollContent: {
        overflowY: "auto",
        overflowX: "hidden",
    },
    desktopScreen: {
        top: heightHeader,
    },
    mobileScreen: {
        top: 0,
        height: "100%",
    },
    contentDrawer: {
        width: "59%",
    },
    iconNews: {
        position: "absolute",
        top: 0,
        right: 0,
        padding: 10,
        margin: 3,
        borderRadius: 5,
        backgroundColor: "#f1f1f1",
        boxShadow: "1px 1px 6px #999",
        zIndex: 300,
    },

    badge: {
        position: "absolute",
        top: spacing(2),
        right: spacing(2),
    },
}));

const RenderBtnSlide = props => {
    const { type, className, style, onClick } = props;
    return <ButtonIcon className={className} style={style} onClick={onClick} name={type} />;
};
class Dashboard extends React.Component {
    constructor(props) {
        const dataShowHide = Config.parseJson(Config.getLocalStorage("W00F1000"));
        super(props);
        this.state = {
            isTab: Config.menuType,
            expand: {},
            newsLoading: false,
            currentModule: Config.getLocalStorage("CURRENTMODULE", true),
            W00F1000Open: (dataShowHide?.[Config.profile.UserID] || dataShowHide?.[Config.profile.UserID] === null) ? dataShowHide[Config.profile.UserID] : "W00F1000_01",
        };
        this.slider = null;
    }

    checkShowEOffice = () => {
        const { W00F1000Open } = this.state;
        this.props.homeActions.getFlagShowEOffice((error, data) => {
            if (error) {
                let message = error.message || Config.lang("Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (data?.NumValue === 1) {
                this.setState({
                    allowNews: true,
                });
            } else if (W00F1000Open === "W00F1000_01") {
                this.toggleW00F1000Open();
            }
        });
    };

    toggleNews = flag => {
        this.setState({ openNews: typeof flag !== "undefined" ? flag : !this.state.openNews });
    };

    loadSetting = () => {
        this.props.generalActions.loadSetting(err => {
            if (err) {
                Config.popup.show("ERROR", err);
                return false;
            }
        });
    };

    loadBadge = () => {
        this.props.homeActions.getMenuV2();
    };

    componentDidMount() {
        const { W00F1000Open } = this.state;
        this.loadSetting();
        const eOfficeLink = Config.getSetting("EOFFICE_URL");
        if (eOfficeLink) {
            this.checkShowEOffice();
        }
        else if (W00F1000Open === "W00F1000_01") {
            this.toggleW00F1000Open();
        }
        this.loadBadge();
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.currentModule &&
            JSON.stringify(this.props.currentModule) !== JSON.stringify(this.state.currentModule)
        ) {
            this.setState({ currentModule: this.props.currentModule });
        }
    }

    toggleW00F1000Open = (key = null) => {
        this.setState({ W00F1000Open: key });
    };

    getEmployeeHireInWeek = () => {
        const listUsers = Config.listUsers;
        if (!listUsers || listUsers.length < 1) return [];
        // return listUsers.filter(user => moment(user.DateJoined, "DD/MM/YYYY").month() > moment().month() - 1 && moment(user.DateJoined, "DD/MM/YYYY").month() < moment().month() + 2);
        return listUsers.filter(user => moment(user.DateJoined).isSame(moment(), "week"));
    };

    renderItemMenu = (menu, dataMenu, dataBadge) => {
        if (!menu || !menu.Type) return null;
        const { loadSetting } = this.props;
        const classes = useStyles();
        const type = menu.Type ? menu.Type.toUpperCase() : "";
        const settings = {
            className: classes.slider,
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            prevArrow: <RenderBtnSlide type={"ArrowLeft"} />,
            nextArrow: <RenderBtnSlide type={"ArrowRight"} />,
        };
        switch (type) {
            case "MENU":
                return (
                    <Row columnSpacing={spacingItem}>
                        {menu.MenuItem?.map((item, idx) => {
                            const itemBadge = dataBadge?.MenuItem?.find(i => i.FormID === item.FormID);
                            return <ItemMenu key={idx} index={idx} data={item} dataBadge={itemBadge} />;
                        })}
                    </Row>
                );
            case "EMPLOYEE":
                const newsEvent = this.getEmployeeHireInWeek();
                const itemPerSlide = 4;
                let data = _.chunk(newsEvent, itemPerSlide);
                return (
                    <Slider {...settings} ref={slider => (this.slider = slider)}>
                        {data.map((items, idx1) => {
                            return (
                                <Row key={idx1}>
                                    {items.map((event, idx2) => {
                                        return <ListUser key={idx2} data={event} setting={loadSetting} />;
                                    })}
                                </Row>
                            );
                        })}
                    </Slider>
                );
            default:
                return null;
        }
    };

    render() {
        const { currentModule, W00F1000Open, allowNews } = this.state;
        const { badgeNumber } = this.props;
        const classes = useStyles();

        let type = Config.menuType === 1 ? "listMSS" : "listESS";
        const data = JSON.parse(JSON.stringify((Config.menu && Config.menu[type]) || []));

        let dataMenu = data.filter(i => i.IsAW !== 1);
        if (currentModule && currentModule?.ModuleID !== "ALL") {
            dataMenu = dataMenu.map(menu => {
                menu.MenuItem = menu?.MenuItem?.filter(item => item?.ModuleID?.includes(currentModule.ModuleID));
                return menu;
            });
        }

        return (
            <Row>
                <Col xs={W00F1000Open && !Config.isMobile ? 6 : 12} style={{ transition: "0.2s all" }}>
                    {/*DASHBOARD*/}
                    <AccordionGroup collapseOther={false}>
                        {dataMenu?.map((menu, idx) => {
                            if (menu.MenuGroupID !== "NewsEvent" && menu.MenuItem && menu.MenuItem.length <= 0)
                                return null;

                            // if (menu.MenuGroupID === "NewsEvent" && newsEvent.length <= 0) return null;
                            if (menu.MenuGroupID === "NewsEvent") return null;

                            return (
                                <Accordion key={menu.MenuGroupID} expand={true} className={classes.collapseBoard}>
                                    <AccordionSummary className={classes.ExpansionPanelSummary}>
                                        <div>
                                            <Typography type={"h1"} color={clSystemActive}>
                                                {menu.MenuGroupName || ""}
                                            </Typography>
                                            <Typography type={"p1"} color={clTextSub} className={"mgt5"}>
                                                {menu.Description || ""}
                                            </Typography>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {this.renderItemMenu(menu, dataMenu, badgeNumber?.[type]?.[idx])}
                                    </AccordionDetails>
                                </Accordion>
                            );
                        })}
                    </AccordionGroup>
                </Col>

                {/*COMPONENT CẢNH BÁO NHẮC VIỆC TẠICẢNH BÁO NHẮC VIỆC TẠI*/}
                <Col xs={W00F1000Open && !Config.isMobile ? 6 : 0}>
                    <W00F1000 open={W00F1000Open} allowNews={allowNews} setOpen={this.toggleW00F1000Open} />
                </Col>
            </Row>
        );
    }
}

class ListUser extends React.Component {
    onLink = () => {
        const { data } = this.props;
        if (data.link) {
            let url =
                Config.getSetting("WEB5_AUTH_URL") +
                "/login?auth=web5&username=" +
                Config.profile.UserID +
                "&token=" +
                Config.profile.NewLogonToken +
                "&redirect=" +
                encodeURIComponent(Config.getSetting("WEB5_AUTH_URL") + "/" + data.link);
            // let url = Config.getSetting("WEB5_AUTH_URL")+"/login?auth=web5&username="+Config.profile.UserID+"&token="+Config.profile.NewLogonToken+"&redirect="+(Config.getSetting('WEB5_AUTH_URL')+'/'+data.link);
            window.open(url, "_self");
            return;
        }
        if (!data.FormCall) return;
        browserHistory.push(Config.getRootPath() + data.FormCall);
    };

    onClickUser = data => {
        if (!data || !data.EmployeeID) return false;
        browserHistory.push({
            pathname: Config.getRootPath() + "W09F2000",
            state: { EmployeeID: data.EmployeeID },
        });
    };

    render() {
        const { data, type, setting } = this.props;
        const classes = useStyles();
        if (!data.EmployeeID) return null;
        const user = Config.getUser({ EmployeeID: data.EmployeeID });
        const IsUseProject = _.get(setting, "IsUseProject", _.get(setting, "[0]IsUseProject", 0));
        let label = "";
        switch (type) {
            case 1:
                label = Config.lang("Chuc_mung_sinh_nhat");
                break;
            case 0:
            default:
                label = Config.lang("Chao_mung_ban");
                break;
        }
        return (
            <Col xs={12} sm={6} className={"mgb15"} style={{ minHeight: 120 }}>
                <Animated
                    className={classes.Animated}
                    animationIn="flipInX"
                    animationInDuration={1000}
                    isVisible={true}
                >
                    <div className={"display_row align-center"} onClick={() => this.onClickUser(data)}>
                        <UserImage data={data} allowHover={false} />
                        <div>
                            <div style={{ fontWeight: 200 }}>{label}</div>
                            <div className={"display_row align-center flex-wrap"} style={{ fontWeight: "bold" }}>
                                <UserName data={data} allowHover={false} />
                                &nbsp;-&nbsp;{data.EmployeeID || ""}
                            </div>
                            <div style={{ fontWeight: 450 }}>
                                {user && user.DepartmentName ? user.DepartmentName : ""}
                            </div>
                            {IsUseProject === 1 && user && user.ProjectName && (
                                <div>
                                    <span style={{ fontWeight: "bold" }}>{`${Config.lang("Du_an")}: `}</span>
                                    <span style={{ fontWeight: 450 }}>
                                        {user && user.ProjectName ? user.ProjectName : ""}
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                </Animated>
            </Col>
        );
    }
}

class ItemMenu extends React.Component {
    onLink = () => {
        const { data } = this.props;
        if (!data.FormCall) return;

        if (data.link) {
            let url =
                Config.getSetting("WEB5_AUTH_URL") +
                "/login?auth=web5&username=" +
                Config.profile.UserID +
                "&token=" +
                Config.profile.NewLogonToken +
                "&redirect=" +
                encodeURIComponent(Config.getSetting("WEB5_AUTH_URL") + "/" + data.link);
            // let url = Config.getSetting("WEB5_AUTH_URL")+"/login?auth=web5&username="+Config.profile.UserID+"&token="+Config.profile.NewLogonToken+"&redirect="+(Config.getSetting('WEB5_AUTH_URL')+'/'+data.link);
            window.open(url, "_self");
            return;
        }

        const parameter = data.Parameters ? JSON.parse(data.Parameters) : {};
        const params = {
            menu: data,
            ...parameter,
        };
        browserHistory.push({
            pathname: Config.getRootPath() + data.FormCall,
            state: params,
        });
    };

    render() {
        const { data, dataBadge } = this.props;
        const classes = useStyles();

        return (
            <Col xs={12} sm={12} md={4} lg={3}>
                <div onClick={this.onLink}>
                    <Animated
                        className={`${classes.Animated} display_col align-center`}
                        animationIn="flipInX"
                        animationInDuration={1000}
                        isVisible={true}
                    >
                        <IconMenu type={"dhr"} name={data.MenuID} width={40} height={40} />
                        <Typography
                            type={"p1"}
                            lineClamp={2}
                            center
                            style={{
                                marginTop: spacing(3),
                                height: 40,
                                wordBreak: "break-word",
                            }}
                        >
                            {data.FormDesc || data.name}
                        </Typography>
                        {!!dataBadge?.NumWaitApproval && (
                            <Badge
                                className={classes.badge}
                                size={"giant"}
                                color={"danger"}
                                content={dataBadge?.NumWaitApproval}
                            />
                        )}
                    </Animated>
                </div>
            </Col>
        );
    }
}

export default compose(
    connect(
        state => ({
            getListEOffice: state.home.getListEOffice,
            currentModule: state.home.currentModule,
            loadSetting: state.general.loadSetting,
            badgeNumber: state.home.badgeNumber,
        }),
        dispatch => ({
            homeActions: bindActionCreators(homeActions, dispatch),
            generalActions: bindActionCreators(generalActions, dispatch),
        })
    )
)(Dashboard);
