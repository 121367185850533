/**
 * @copyright 2019 @ DigiNet
 * @author tranghoang
 * @create 2019/03/06 12:39
 * @update 2019/03/06 12:39
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {OverlayTrigger, Tooltip, Popover,} from 'react-bootstrap';
import ButtonBasic from "../common/button/button-basic";

class GridActionBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            placement: 'bottom',

        };
        this.optionPopover = ''
    }

    onView(classNameIs) {
        if (typeof classNameIs == 'undefined') {
            if (this.props.onDetailAction && this.props.isPer >= 1)
                this.props.onDetailAction();
        } else {
            if (this.props.onDetailAction && this.props.isPer >= 1 && classNameIs>=4)
                this.props.onDetailAction();
        }
    }
    onEdit(classNameIs) {
        if (typeof classNameIs == 'undefined') {
            if (this.props.onEditAction && this.props.isPer >= 3)
                this.props.onEditAction();
        } else {
            if (this.props.onEditAction && this.props.isPer >= 3 && classNameIs>=4)
                this.props.onEditAction();
        }
    }

    onDelete(classNameIs) {
        if (typeof classNameIs == 'undefined') {
            if (this.props.onDeleteAction && this.props.isPer >= 4)
                this.props.onDeleteAction();
        } else {
            if (this.props.onDeleteAction && this.props.isPer >= 4 && classNameIs>=4)
                this.props.onDeleteAction();
        }
    }

    onHistory() {
        if (this.props.onHistoryAction && this.props.isPer >= 1 && this.state.classNameIs)
            this.props.onHistoryAction();
    }

    showMoreOptions(e,classNameIs) {
        let height = document.getElementsByClassName('paging-crm')[0].getBoundingClientRect().top
        let pos = e.target.getBoundingClientRect().top;
        const {btnHamburger} = this.props;
        if (height - pos < 180) {
            this.setState({
                placement: 'top'
            })
        }
        else {
            this.setState({
                placement: 'bottom'
            })
        }

        this.optionPopover = (
            <Popover id={'hamburgerContent'}>
                {btnHamburger.map((value, index) => {
                    return (<div className={'btnContainer'} style={{
                        borderBottom: index % 2 && index !== btnHamburger.length - 1 && '1px dotted #c7d5e0',
                        borderTop: index % 2 && '1px dotted #c7d5e0'
                    }} key={index}><ButtonBasic onClick={value.onClick} disabled={value.disabled}
                                                className={`${value.className && value.className} btn-crm`}>
                        {value.icon && <i className={value.icon && value.icon}> &nbsp; </i>}{value.name && value.name}

                    </ButtonBasic></div>)
                })}
            </Popover>
        )


    }

    render() {
        const {tooltipDetail, onDetailAction, tooltipEdit, onEditAction, tooltipDelete, onDeleteAction, onHistoryAction, tooltipHistory, tooltipDownload, onDownloadAction, isPer, className, classNameIs, btnHamburger} = this.props;
        const overlayDetail = <Tooltip id="ttDetail" className="tooltip">{tooltipDetail}</Tooltip>;
        const overlayEdit = <Tooltip id="ttEdit" className="tooltip">{tooltipEdit}</Tooltip>;
        const overlayDelete = <Tooltip id="ttDelete" className="tooltip">{tooltipDelete}</Tooltip>;
        const overlayHistory = <Tooltip id="ttDelete" className="tooltip">{tooltipHistory}</Tooltip>;
        const overlayDownload = <Tooltip id="ttDelete" className="tooltip">{tooltipDownload}</Tooltip>;

        return (
            <table style={{zIndex: 1000, width: '100%'}}>
                <tbody>
                <tr>
                    <td align={"center"} style={{border: 'none'}}>
                        {/* eslint-disable */}
                        {typeof onDetailAction === "function" &&
                        <OverlayTrigger placement="bottom" trigger={["hover", "focus"]} overlay={overlayDetail}>
                            <a className="action-view-link" onClick={() => this.onView()}><i
                                className={`${isPer >= 1 ? '' : 'isDisabled'} ${className} action-button fas fa-eye text-orange`}/></a>
                        </OverlayTrigger>}

                        {typeof onEditAction === "function" &&
                        <OverlayTrigger placement="bottom" trigger={["hover", "focus"]} overlay={overlayEdit}>
                            <a className="action-edit-link " onClick={() => this.onEdit(classNameIs)}><i
                                className={`${isPer >= 3 ? '' : 'isDisabled'} ${className} action-button fas fa-edit text-orange`}/></a>
                        </OverlayTrigger>}

                        {typeof onDeleteAction === "function" &&
                        <OverlayTrigger placement="bottom" trigger={["hover", "focus"]} overlay={overlayDelete}>
                            <a className="action-del-link" onClick={() => this.onDelete(classNameIs)}><i
                                className={`${isPer >= 4 ? '' : 'isDisabled'} action-button fas fa-trash-alt text-red ${className}`}/></a>
                        </OverlayTrigger>}

                        {typeof onHistoryAction === "function" &&
                        <OverlayTrigger placement="bottom" trigger={["hover", "focus"]} overlay={overlayHistory}>
                            <a className="action-his-link" onClick={() => this.onHistory()}><i
                                className={`${isPer >= 1 ? '' : 'isDisabled'} ${className} action-button fas fa-ellipsis-h text-black`}/></a>
                        </OverlayTrigger>}

                        {typeof onDownloadAction === "function" &&
                        <OverlayTrigger placement="bottom" trigger={["hover", "focus"]} overlay={overlayDownload}>
                            <a className="action-del-link" onClick={() => onDownloadAction()}><i
                                className="action-button fas fa-download text-red"/></a>
                        </OverlayTrigger>}
                        {btnHamburger &&
                        <OverlayTrigger rootClose={true} placement={this.state.placement} onClick={(e) => {
                            this.showMoreOptions(e,classNameIs)
                        }} trigger={["click"]} overlay={this.optionPopover}>
                            <a className="action-expand"><i className="action-button fas fa-bars text-orange"/></a>
                        </OverlayTrigger>}

                        {/* eslint-disable */}
                    </td>
                </tr>
                </tbody>
            </table>
        );
    }
}

GridActionBar.propTypes = {
    tooltipDetail: PropTypes.string,
    tooltipEdit: PropTypes.string,
    tooltipDelete: PropTypes.string,
    tooltipHistory: PropTypes.string,
    tooltipDownload: PropTypes.string,
    tooltipMore: PropTypes.string,
    isPer: PropTypes.number,
    classNameIs: PropTypes.number,
    className: PropTypes.string,

    onDetailAction: PropTypes.func,
    onEditAction: PropTypes.func,
    onDeleteAction: PropTypes.func,
    onHistoryAction: PropTypes.func,
    onDownloadAction: PropTypes.func,
    btnHamburger: PropTypes.array,
};

export default GridActionBar;
