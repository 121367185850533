/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 3/28/2020
 * @Example 
 */

import React, {PureComponent} from 'react';

import PropTypes from "prop-types";
import {bindActionCreators, compose} from "redux";
import {connect} from "react-redux";
import * as generalActions from "../../../../redux/general/general_actions";
import Config from "../../../../config";
import {Image} from "react-bootstrap";
import InlineSVG from "react-inlinesvg";
import {TextBox} from "devextreme-react";
import withStyles from "@material-ui/core/styles/withStyles";
import {Typography} from "@material-ui/core";
import Combo from "./combo";
import UserImage from "../../user/user-image";

const styles = {
    divAvatar: {
        width: 32,
        height: 32,
        borderRadius: '50%',
        border: '1px solid #ddd',
        overflow: 'hidden',
        marginRight: 8
    },
    imgAvatar: {
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    textBox: {
        width: 'calc(100% - 32px)'
    }
};
class Employees extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            shrink: Array.isArray(props.value) ? props.value.length > 0 : !!props.value || props.shrink,
            value: props.value,
            searchValue: null,
            dataSource: {
                rows: props.dataSource && !props.autoLoad ? (props.dataSource.rows ? props.dataSource.rows : props.dataSource) : [],
                total: props.dataSource && !props.autoLoad ? (props.dataSource.total || props.dataSource.total === 0 ? props.dataSource.total : (props.totalItems ? props.totalItems : 0)) : 0
            },
            loading: false,
        };
        this.textBoxSearch = null;
        this.filterCboEmployees = {
            timer: null,
            skip: props.skip ? props.skip : 0,
            limit: props.limit ? props.limit : 50
        };
        // this.searchValue = "";
    }

    loadCboEmployees = (isReset) => {
        const {FormID} = this.props;
        const {pathname} = window.location;
        let _FormID = "";
        if (pathname.indexOf('/') === 0) {
            _FormID = pathname.substring(1, 9);
        }
        const param = {
            HostID: "",
            Type: "EmployeeID",
            FormID: FormID || _FormID,
            Language: Config.language || '84',
            skip: this.filterCboEmployees.skip,
            limit: this.filterCboEmployees.limit,
            search: this.state.searchValue ?? ''
        };
        this.setState({loading: true});
        this.props.generalActions.getCboEmployees(param, (error, data) => {
            this.setState({loading: false});
            if (error) {
                Config.popup.show("ERROR", error);
                return false;
            }
            if (data) {
                const {dataSource} = this.state;
                const d = data.rows ? data.rows : data;
                const total = data.total ? data.total : 0;
                this.setState({
                    dataSource: {
                        rows: isReset ? d : dataSource.rows.concat(d),
                        total: total
                    }
                });
            }
        });
    };

    componentDidMount() {
        const {autoLoad, dataSource} = this.props;
        if (typeof dataSource === "undefined" || autoLoad) {
            this.loadCboEmployees();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {totalItems, dataSource} = this.props;
        if (!this.props.autoLoad && typeof dataSource !== "undefined" && JSON.stringify(prevProps.dataSource) !== JSON.stringify(dataSource)) {
            this.setState({
                dataSource: {
                    rows: dataSource.rows ? dataSource.rows : dataSource,
                    total: dataSource.total || dataSource.total === 0 ? dataSource.total : totalItems ? totalItems : 0
                }
            });
        }
    }

    renderItemCboEmployee = (e) => {
        const {data} = e;
        const {classes, displayExpr,subTextExpr} = this.props;
        if (data && data.UserPictureURL) {
            if (data.UserPictureURL.indexOf('http') < 0) {
                data.UserPictureURL = Config.getCDNPath() + data.UserPictureURL;
            }
        }
        if (data) {
            return (
                <div className={"display_row align-center"}>
                    <div className={classes.divAvatar}>
                        {data && data.UserPictureURL ? (
                            <Image className={classes.imgAvatar} src={data.UserPictureURL} />
                        ) : (
                            <InlineSVG className={classes.imgAvatar} src={require('../../../../assets/images/general/user_default.svg')} />
                        )}
                    </div>
                    <div className="cbo-employee-name">
                        {data[displayExpr]}
                        <Typography component={"div"} variant={'body2'}
                            style={{fontWeight: 200}}
                        >
                            {subTextExpr ? (data[subTextExpr] ? data[subTextExpr] : subTextExpr) : ""}
                        </Typography>
                    </div>
                </div>
            );
        }
    };

    renderFieldCboEmployee = (data) => {
        const {classes, value, multiple, valueExpr, displayExpr, acceptCustomValue, dropdownProps, onHoverAvatar, allowHover} = this.props;
        const {dataSource, searchValue} = this.state;
        const readOnly = dropdownProps && dropdownProps.readOnly && dropdownProps.readOnly === true;
        const _dataSource = dataSource && dataSource.rows ? dataSource.rows : dataSource;
        if (valueExpr) {
            if (multiple) {
                const _data = [];
                data && data.forEach((d) => {
                    if (!d[displayExpr]) {
                        const rowData = _dataSource.filter(row => row[valueExpr] === d[valueExpr]);
                        _data.push(rowData && rowData.length > 0 ? rowData[0] : d);
                    } else {
                        _data.push(d);
                    }
                });
                data = _data;
            } else if (typeof data !== "object") {
                const rowData = _dataSource.filter(row => row[valueExpr] === data);
                data = rowData && rowData.length > 0 ? rowData[0] : (value ? value : null);
            }
        }

        if (searchValue === null && data) {
            if (multiple) {
                return (
                    <div className={"display_row align-right flex-wrap"}
                         style={{width: "100%", height: '100%', paddingBottom: 3}}>
                        {data && data.map((_d, indx) => {
                            // console.log('hao_d', _d);
                            if (_d && _d.UserPictureURL) {
                                if (_d.UserPictureURL.indexOf('http') < 0) {
                                    _d.UserPictureURL = Config.getCDNPath() + _d.UserPictureURL;
                                }
                            }
                            return (
                                <div key={indx} className={"display_row"}>
                                    {_d && <div className={classes.divAvatar}
                                                onMouseOver={() => {
                                                    if (onHoverAvatar) onHoverAvatar(data);
                                                }}
                                                onMouseOut={() => {
                                                    if (onHoverAvatar) onHoverAvatar(null);
                                                }}
                                    >
                                        <UserImage data={data} width={32} height={32} allowHover={allowHover} />
                                    </div>}
                                    <TextBox className={"cbo-employee-name " + classes.textBox}
                                             defaultValue={_d && _d[displayExpr] ? _d[displayExpr] : ""}
                                             readOnly={!acceptCustomValue || readOnly || multiple}
                                    />
                                </div>
                            )
                        })}
                        {acceptCustomValue && !readOnly && <div className={"display_row"}>
                            <InlineSVG className={classes.imgAvatar}
                                       src={require('../../../../assets/images/general/user_default.svg')}/>
                            <TextBox ref={ref => this.textBoxSearch = ref}
                                     className={"cbo-employee-name " + classes.textBox}
                                     defaultValue={""}
                                     onKeyUp={(e) => this.onInputChanged(e.event)}
                                     readOnly={false}
                            />
                        </div>}
                    </div>
                );
            } else {
                if (data && data.UserPictureURL) {
                    if (data.UserPictureURL.indexOf('http') < 0) {
                        data.UserPictureURL = Config.getCDNPath() + data.UserPictureURL;
                    }
                }
                return (
                    <div className={"display_row align-right"}
                         style={{width: "100%", height: '100%', paddingBottom: 3}}>
                        {data && <div className={classes.divAvatar}
                                      onMouseOver={() => {
                                          if (onHoverAvatar) onHoverAvatar(data);
                                      }}
                                      onMouseOut={() => {
                                          if (onHoverAvatar) onHoverAvatar(null);
                                      }}
                        >
                            <UserImage data={data} width={32} height={32} allowHover={allowHover} />
                        </div>
                        }
                        <TextBox className={"cbo-employee-name " + classes.textBox}
                                 defaultValue={data && data[displayExpr] ? data[displayExpr] : ""}
                                 readOnly={!acceptCustomValue || readOnly}

                        />
                        {/*<div style={{padding: '7px 0'}}>{data && data.EmployeeName ? data.EmployeeName : ""}</div>*/}
                    </div>
                );
            }
        } else {
            return (
                <div className={"display_row align-right pdb5"} style={{width: "100%", height: '100%'}}>
                    <div className={classes.divAvatar}>
                        <InlineSVG className={classes.imgAvatar}
                                   src={require('../../../../assets/images/general/user_default.svg')}/>
                    </div>
                    <TextBox className={"cbo-employee-name " + classes.textBox}
                             value={this.state.searchValue}
                             readOnly={false}
                    />
                    {/*<div style={{padding: '7px 0'}}>{data && data.EmployeeName ? data.EmployeeName : ""}</div>*/}
                </div>
            );
        }
    };

    onOpened = () => {
        if (this.textBoxSearch) {
            setTimeout(() => {
                this.textBoxSearch.instance.focus();
            }, 300);
        }
    };

    onClosed = () => {
        this.setState({searchValue: null});
        if (this.dropdown) this.dropdown.instance.option('value', this.props.value);
    };

    onInputChanged = (e) => {
        const {skip, limit, autoLoad, dataSource, onInputChanged} = this.props;
        this.setState({searchValue: e.target && e.target.value ? e.target.value : e.value}, () => {
            if (autoLoad || typeof dataSource === "undefined") {
                if (this.filterCboEmployees.timer) clearTimeout(this.filterCboEmployees.timer);
                this.filterCboEmployees.timer = setTimeout(() => {
                    this.filterCboEmployees.skip = skip ? skip : 0;
                    this.filterCboEmployees.limit = limit ? limit : 20;
                    this.loadCboEmployees(true);
                }, 700);
            }
        });
        if (onInputChanged) onInputChanged(e);
    };

    onLoadMore = (e) => {
        const {autoLoad, dataSource, onLoadMore} = this.props;
        if (autoLoad || typeof dataSource === "undefined") {
            this.filterCboEmployees.skip = e.skip;
            this.filterCboEmployees.limit = e.limit;
            this.loadCboEmployees();
        }
        if (onLoadMore) onLoadMore(e);
    };

    render() {
        const {totalItems, label, multiple, value, shrink, error, disabled,
            displayExpr, valueExpr, showClearButton, stylingMode, height, className, dropdownProps,
            InputLabelProps, onValueChanged, acceptCustomValue, helperText,
            required, loading
        } = this.props;
        const {dataSource} = this.state;

        const _dataSource = dataSource && dataSource.rows ? dataSource.rows : dataSource;
        const _totalItem = dataSource && dataSource.total ? dataSource.total : totalItems;

        return (
            <React.Fragment>
                <Combo.LoadMore
                    reference={ref => this.dropdown = ref}
                    error={error}
                    acceptCustomValue={acceptCustomValue}
                    dataSource={_dataSource}
                    totalItems={_totalItem}
                    skip={this.filterCboEmployees.skip}
                    className={className}
                    limit={this.filterCboEmployees.limit}
                    displayExpr={displayExpr}
                    keyExpr={valueExpr}
                    valueExpr={valueExpr}
                    value={value}
                    height={height}
                    fieldRender={this.renderFieldCboEmployee}
                    disabled={disabled || this.state.loading}
                    stylingMode={stylingMode ? stylingMode : 'underlined'}
                    shrink={shrink}
                    label={label}
                    loading={loading || this.state.loading}
                    multiple={multiple}
                    required={required}
                    helperText={helperText}
                    itemRender={this.renderItemCboEmployee}
                    showClearButton={showClearButton}
                    InputLabelProps={InputLabelProps}
                    dropdownProps={dropdownProps}
                    onValueChanged={onValueChanged}
                    onOpened={this.onOpened}
                    onClosed={this.onClosed}
                    onInputChanged={this.onInputChanged}
                    onLoadMore={this.onLoadMore}
                />
            </React.Fragment>
        )
    }
}

Employees.propTypes = {
    error: PropTypes.any,
    helperText: PropTypes.string,
    acceptCustomValue: PropTypes.bool,
    dataSource: PropTypes.any,
    totalItems: PropTypes.number,
    skip: PropTypes.number,
    limit: PropTypes.number,
    label: PropTypes.string,
    value: PropTypes.any,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    shrink: PropTypes.bool,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    loading: PropTypes.bool,

    keyExpr: PropTypes.string,
    valueExpr: PropTypes.string,
    displayExpr: PropTypes.string,
    subTextExpr: PropTypes.string,
    FormID: PropTypes.string,
    autoLoad: PropTypes.bool,

    showClearButton: PropTypes.bool,
    stylingMode: PropTypes.string,
    height: PropTypes.any,
    multiple: PropTypes.bool,
    allowHover: PropTypes.bool,

    dropdownProps: PropTypes.any,
    InputLabelProps: PropTypes.any,

    onValueChanged: PropTypes.func,
    onInputChanged: PropTypes.func,
    onLoadMore: PropTypes.func,
};

export default compose(connect(null,
    (dispatch) => ({
        generalActions: bindActionCreators(generalActions, dispatch),
    })
), withStyles(styles))(Employees);