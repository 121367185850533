/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 11/4/2019
 * @Example
 */

import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import Config from "../../../config";
import {withStyles} from "@material-ui/styles";
import Divider from "@material-ui/core/Divider";
import { Image } from 'react-bootstrap';
const styles = {
    dpaper: {
        alignSelf: 'start',
        maxHeight: 'calc(100% - 30px)',
    },
    dwrapper: {
        maxHeight: 'calc(100vh - 30px)',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        height: '100%'
    },
    dactions: {
        padding: '12px 24px'
    },
    DialogTitle: {
        minHeight: 48
    }
};

class Modal extends PureComponent {
    constructor(props) {
        super(props);
        this.component = React.createRef();
        this.dialog = null;
        this.state={
            isContentWrapper: true
        }
    }

    componentDidMount() {
        const {disableEscapeKeyDown, onOpen} = this.props;
        if (onOpen) onOpen();
        if (!disableEscapeKeyDown) window.addEventListener("keydown", this.onKeyDown);
    }

    componentWillUnmount() {
        const {disableEscapeKeyDown} = this.props;
        if (!disableEscapeKeyDown) window.removeEventListener("keydown", this.onKeyDown);
    }

    onKeyDown = (e) => {
        if (!e && !e.keyCode) return false;
        if (e.keyCode === 27) {
            this.onClose(e);
        }
    };

    onEntering = () => {
        if (this.dialog) {
            if (this.dialog.getElementsByClassName("MuiDialogContent-root").length < 1) {
                this.setState({isContentWrapper: false})
            }
        }
    };

    onEntered = () => {
        setTimeout(() => {
            if (this.dialog) {
                this.dialog.getElementsByClassName("MuiDialog-container")[0].removeAttribute("tabindex");
            }
        }, 400);

    };

    onClose = (e) => {
        const {onClose} = this.props;
        if (onClose) onClose(e);
    };
    onDownload=(data)=>{
        console.log(data)
        fetch(data.filePath).then(function(response) {
            return response.blob();
        }).then(function(myBlob) {
            var objectURL = URL.createObjectURL(myBlob);
            const link = document.createElement('a');
            link.href = objectURL;
            link.setAttribute('download', data.FileName);
            document.body.appendChild(link);
            link.click();
        });
    }
    onNewTab=(data)=>{
        window.open(data.filePath)
    }
    render() {
        const {open, title, component, children, className, style, fullWidth, maxWidth, transition,
                  classes, paperProps, onOpen, onClose, disableBackdropClick, actionComponents,download,type, position, ...props} = this.props;
        const {isContentWrapper} = this.state;
        const popupTransitions = Config.helpers.popupTransitions;
        const Trans = transition && popupTransitions[transition] ? popupTransitions[transition] : Config.popupTransition;

        return (
            <React.Fragment>
                <Dialog
                    {...props}
                    ref={ref => this.dialog = ref}
                    open={open}
                    className={className}
                    style={style}
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                    classes={{paper: classes.dpaper}}
                    disableBackdropClick={typeof disableBackdropClick === "undefined" ? true : disableBackdropClick}
                    PaperProps={{
                        style: paperProps && paperProps.style ? {...styles.dpaper, ...paperProps.style} : styles.dpaper,
                        ...paperProps
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    onEntering={this.onEntering}
                    onEntered={this.onEntered}
                    onClose={this.onClose}
                    TransitionComponent={Trans}
                >
                    <div className={classes.dwrapper}>
                        {(title || onClose) && <DialogTitle className={classes.DialogTitle} disableTypography>
                            <Typography variant="h6">{title}</Typography>
                            <div>
                                {type === "file" &&
                                <>
                                    <IconButton aria-label="DownloadIcon" style={styles.closeButton} onClick={() => this.onDownload(download)}>
                                        <Image src={require('../../../assets/images/download.svg')} />
                                    </IconButton>
                                    <IconButton aria-label="DownloadIcon" style={styles.closeButton} onClick={() => this.onNewTab(download)} >
                                        <Image src={require('../../../assets/images/new_tab.svg')} />
                                    </IconButton>
                                </>
                                }
                                <IconButton aria-label="close" style={styles.closeButton} onClick={this.onClose}>
                                    <CloseIcon />
                                </IconButton>
                            </div>
                        </DialogTitle>}
                        {!!title && <Divider />}
                        {!isContentWrapper && <Content>
                            {component && component}
                            {!component && children}
                        </Content>}
                        {isContentWrapper && <>
                            {component && component}
                            {!component && children}
                        </>}
                    </div>
                </Dialog>
            </React.Fragment>
        );
    }
}

class Actions extends PureComponent {
    render() {
        const {children} = this.props;

        return (
            <React.Fragment>
                <Divider />
                <DialogActions style={styles.dactions} {...this.props}>
                    {children}
                </DialogActions>
            </React.Fragment>
        );
    }
}

class Content extends PureComponent {
    render() {
        const {children} = this.props;

        return (
            <React.Fragment>
                <DialogContent {...this.props}>
                    {children}
                </DialogContent>
            </React.Fragment>
        );
    }
}

class Title extends PureComponent {
    render() {
        const {children} = this.props;

        return (
            <React.Fragment>
                <DialogTitle {...this.props}>
                    {children}
                </DialogTitle>
            </React.Fragment>
        );
    }
}

Modal.propTypes = {
    open: PropTypes.bool,
    className: PropTypes.string,
    style: PropTypes.any,
    component: PropTypes.any,
    title: PropTypes.any,
    fullWidth: PropTypes.bool,
    maxWidth: PropTypes.string,
    transition: PropTypes.string,
    paperProps: PropTypes.any,
    disableBackdropClick: PropTypes.bool,
    type: PropTypes.oneOf(["file"]),

    /**
     * download={{
                        filePath: 'https://apricot.diginet.com.vn/cdn-dev/file/50481291-b223-4222-9327-2aaa9e3fff33.xlsx?path=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJmaWxlUGF0aCI6Ii8yMDIwLzA3LzFhM2ViOWZlLTcyZTUtNGQwNS1hMjc4LTBiZWVhYzQ3YTU5Mi54bHN4IiwiZmlsZU5hbWUiOiI1MDQ4MTI5MS1iMjIzLTQyMjItOTMyNy0yYWFhOWUzZmZmMzMueGxzeCIsImZpbGVTaXplIjozMjE0MzAsImZpbGVUeXBlIjoiYXBwbGljYXRpb24vdm5kLm9wZW54bWxmb3JtYXRzLW9mZmljZWRvY3VtZW50LnNwcmVhZHNoZWV0bWwuc2hlZXQiLCJpYXQiOjE1OTM4Mzc4MzQsImV4cCI6ODc5OTM4Mzc4MzR9.rfGkDJBOTz8xMLdl8ebNJCyNz8ZGI453Qb_oW8tEV6E',
                        FileName: 'test.xlsx',
                        fileType: 'xlsx'
                    }}
     */
    download: PropTypes.object,
    actionComponents: PropTypes.any,

    onClose: PropTypes.func,
    onOpen: PropTypes.func,
};
Modal.Title = Title;
Modal.Content = Content;
Modal.Actions = Actions;

export default withStyles(styles)(Modal);