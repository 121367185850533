/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 6/8/2020
 * @Example
 */


import React from "react";
import propTypes from "prop-types";
import {compose} from "redux";
import Config from "../../../config";
import moment from "moment";
import {Tooltip, Typography} from "diginet-core-ui/components";
import {makeStyles, useTheme} from "diginet-core-ui/theme";
import _ from "lodash";
const {colors} = useTheme();
const styles = makeStyles(()=>({
    divParent: {
        cursor: 'pointer',
    },
    stylePopup: {
        fontSize: '1rem',
        fontWeight: 400
    }
}));

class UserName extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
    }

    render() {
        const {
            data,
            valueExpr,
            keyExpr,
            displayExpr,
            allowHover,
            renderItem,
            renderItemHover,
            className,
            classNamePopup,
            style,
            stylePopup,
            onClick
        } = this.props;
        const classes = styles();
        let {user} = this.props;
        if (data && _.isEmpty(user)) {
            user = Config.getUser({ [valueExpr]: data[keyExpr] });
        }
        const dateJoined = user ? moment(user.DateJoined).isValid() ? moment(user.DateJoined).format('DD/MM/YYYY') : user.DateJoined : "";
        return (
            <Tooltip
                alignMode={'middle'}
                direction={'down'}
                forceDirection={true}
                textAlign={'left'}
                tooltipMaxWidth={300}
                disableInteractive={_.isEmpty(user) && !allowHover}
                title={
                    <>
                        {!renderItemHover && 
                            <div style={stylePopup} className={classes.stylePopup + " " + classNamePopup}>
                                {user && user.DepartmentName && <Typography type="paragraph1" color={colors.white}>{Config.lang('DHR_Phong_ban') + ': ' + user.DepartmentName}<br/></Typography>}
                                {user && user.ProjectName && <Typography type="paragraph1" color={colors.white}>{Config.lang('DHR_Du_an') + ': ' + user.ProjectName}<br/></Typography>}
                                {user && user.DutyName && <Typography type="paragraph1" color={colors.white}>{Config.lang('DHR_Chuc_vu') + ': ' +user.DutyName}<br/></Typography>}
                                {user && user.DateJoined && <Typography type="paragraph1" color={colors.white}>{Config.lang('DHR_Ngay_vao_lam') + ': ' + dateJoined}</Typography>}
                            </div>
                        }
                        {renderItemHover && 
                            (
                                typeof renderItemHover === "function" ? 
                                renderItemHover(user) : 
                                renderItemHover
                            )
                        }
                    </>
                }
            >
                <div
                    onClick={onClick}
                    style={style}
                    className={"user-name-c " + classes.divParent + " " + className}
                >
                    {!renderItem && <div>{data && data[displayExpr] ? data[displayExpr] : (user ? user[displayExpr] : "")}</div>}
                    {renderItem && (typeof renderItem === "function" ? renderItem(data, user) : renderItem)}
                </div>
            </Tooltip>
        );
    }
}

UserName.defaultProps = {
    valueExpr: "EmployeeID",
    keyExpr: "EmployeeID",
    displayExpr: "EmployeeName",
    allowHover: true
};
UserName.propTypes = {
    width: propTypes.any,
    height: propTypes.any,
    className: propTypes.any,
    classNamePopup: propTypes.any,
    style: propTypes.any,
    stylePopup: propTypes.any,
    /**
     * data: {EmployeeID: "abc", EmployeeName: ""}
     */
    data: propTypes.any,
    valueExpr: propTypes.string,
    keyExpr: propTypes.string,
    displayExpr: propTypes.string,
    allowHover: propTypes.bool,

    renderItem: propTypes.any,
    renderItemHover: propTypes.any,
    onClick: propTypes.func,
};

export default compose()(UserName)
