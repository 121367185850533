/**
 * @Copyright 2022 @DigiNet
 * @Author TRANGHOANG
 * @Create 28/04/2022
 * @Example
 */

import { Column } from "devextreme-react/data-grid";
import {
    Button,
    Popover,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
    TabHeader,
    TabItem,
    TabContainer,
} from "diginet-core-ui/components";
import PropTypes from "prop-types";
import React, { useRef, useImperativeHandle, useState, useEffect } from "react";
import GridContainer from "../../grid-container/grid-container";
import Config from "../../../config";

const ShowHideCol = React.forwardRef((props, ref) => {
    const { labelButton, data: dataProps, width, onSubmit, buttonProps, popoverProps, FormID, tabInfo, tabExp } = props;
    const gridRef = useRef(null);
    const popoverRef = useRef(null);
    const [data, setData] = useState([]);
    const [tabId, setTabId] = useState(null);

    let dataCache = Config.getLocalStorage("SHOW_HIDE_COLUMN", true)?.[Config.profile.UserID]?.[FormID] || [];

    useEffect(() => {
        if (tabInfo?.length) {
            setTabId(tabInfo[0]?.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let flagOnSubmit = false;
        let dataT = formatData();

        // compare dataProps and dataCache
        if (dataCache?.length > 0) {
            dataT = dataT.map(
                obj =>
                    dataCache.forEach(o => {
                        if (obj.visible !== o.visible && obj.dataField === o.dataField && obj[tabExp] === o[tabExp]) {
                            obj.visible = o.visible;
                            flagOnSubmit = true;
                        }
                    }) || obj
            );
        }
        setData(dataT);
        if (flagOnSubmit) _onSubmit(dataT);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataProps]);

    useEffect(() => {
        if (gridRef.current) {
            gridRef.current.instance.filter(tabExp, "=", tabId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tabId]);

    const formatData = () => {
        let dataT = [...dataProps].flatMap((i, index) => {
            const item = {
                visible: true,
                ...i,
                keyExpr: index,
            };

            if (Array.isArray(i[tabExp])) {
                return i[tabExp].map((j, indexJ) => ({ ...item, keyExpr: `${item.keyExpr}-${indexJ}`, [tabExp]: j }));
            } else {
                return item;
            }
        });
        return dataT;
    };

    /**
     * function return list column
     * @return {object} object whose key is dataField name and value is boolean: default false => hidden column
     * @private
     */
    const _onSave = () => {
        const dataGrid = gridRef.current.instance.option("dataSource");

        // save dataCache
        if (FormID) {
            dataCache = { [Config.profile.UserID]: { [FormID]: dataGrid } };
            Config.setLocalStorage("SHOW_HIDE_COLUMN", JSON.stringify(dataCache));
        }

        _onSubmit(dataGrid);
        onClose();
    };

    const _onSubmit = data => {
        onSubmit && onSubmit(data);
    };

    useImperativeHandle(ref, () => ({
        onClose: onClose,
    }));

    /**
     * Function to close popover
     */
    const onClose = () => {
        popoverRef.current.close();
    };

    return (
        <Popover
            width={width}
            // height={height}
            ref={popoverRef}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            anchor={
                <Button
                    label={labelButton || Config.lang("Hien_thi")}
                    viewType={"outlined"}
                    color={"primary"}
                    {...buttonProps}
                />
            }
            onClose={() => setData(formatData())}
            {...popoverProps}
        >
            {/** Header Popover */}
            <PopoverHeader title={Config.lang("Chon_cot_hien_thi")} />

            {/** Body Popover */}
            <PopoverBody>
                {tabInfo?.length && (
                    <TabContainer tabIndex={tabId} className={"mgb2x"}>
                        <TabHeader>
                            {tabInfo.map((tab) => (
                                <TabItem key={tab.id} index={tab.id} onClick={(item, index) => setTabId(index)} label={tab.title} />
                            ))}
                        </TabHeader>
                    </TabContainer>
                )}

                {/** Grid data* */}
                <GridContainer
                    dataSource={data}
                    showBorders={false}
                    reference={ref => (gridRef.current = ref)}
                    showRowLines={false}
                    paging={{ pageSize: data.length, enabled: false }}
                    pager={{ visible: false }}
                    showColumnLines={false}
                    pagerFullScreen={false}
                    allowCellSelection={false}
                    keyExpr={"keyExpr"}
                    onInitialized={e => {
                        if (tabId || tabId === 0) e.component.filter(tabExp, "=", tabId);
                    }}
                    editing={{
                        mode: "cell",
                        allowUpdating: true,
                        refreshMode: "reshape",
                    }}
                    sorting={{
                        mode: "none",
                    }}
                    onEditorPreparing={e => {
                        if (e?.dataType === "boolean" && e?.row?.data.disabled) {
                            e.editorOptions.disabled = true;
                        }
                    }}
                >
                    <Column
                        alignment={"center"}
                        dataField={"visible"}
                        caption={Config.lang("Chon")}
                        dataType={"boolean"}
                        width={70}
                        calculateDisplayValue={e => !!e.value}
                    />
                    <Column
                        dataField={"caption"}
                        caption={Config.lang("Ten_cot")}
                        calculateDisplayValue={e => e.caption || e.dataField}
                        allowEditing={false}
                    />
                    <Column dataField={"tabId"} visible={false} />
                    <Column dataField={"dataField"} visible={false} />
                </GridContainer>
            </PopoverBody>

            {/** Footer Popover */}
            <PopoverFooter>
                <Button
                    text={Config.lang("Luu")}
                    color={"info"}
                    startIcon={"save"}
                    viewType={"filled"}
                    onClick={_onSave}
                />
            </PopoverFooter>
        </Popover>
    );
});

ShowHideCol.defaultProps = {
    width: 400,
    height: 628,
    buttonProps: {},
    popoverProps: {},
    labelButton: null,
    data: [],
    tabExp: "tabId",
};

ShowHideCol.propTypes = {
    /** width of popover **/
    width: PropTypes.number,
    /** height of popover **/
    height: PropTypes.number,
    /** label of button **/
    labelButton: PropTypes.string,
    /** props of Popover **/
    popoverProps: PropTypes.object,
    /** props of Button **/
    buttonProps: PropTypes.object,
    /** function submit of Button **/
    onSubmit: PropTypes.func,
    /** FormID of screen - used to save localStorage **/
    FormID: PropTypes.string,
    /** tabExp - is fieldName used compare with tabId: tabId **/
    tabExp: PropTypes.string,
    /** dataGrid **/
    data: PropTypes.arrayOf(
        PropTypes.shape({
            caption: PropTypes.string.isRequired,
            dataField: PropTypes.string.isRequired,
        })
    ),
    /** tabInfo - list info Tab **/
    tabInfo: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string.isRequired,
            id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        })
    ),
};

export default ShowHideCol;
