/**
 * @copyright 2019 @ DigiNet
 * @author TRIHAO
 * @create 6/8/2020
 * @Example
 */


import React from "react";
import propTypes from "prop-types";
import {compose} from "redux";
import Config from "../../../config";
import {Avatar, Tooltip} from "diginet-core-ui/components";
import _ from "lodash";
class UserImage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        const {
            src,
            data,
            valueExpr,
            keyExpr,
            fieldImage,
            allowHover,
            renderItemHover,
            width,
            height,
            popoverOption,
            onClick,
            className,
            style
        } = this.props;
        let {user} = this.props;
        let popoverKey = [];
        
        const isShowEmployeeNameOnly = !popoverOption.showDepartment &&
        !popoverOption.showProject &&
        !popoverOption.showDuty &&
        !popoverOption.showDateJoined;

        if(isShowEmployeeNameOnly) {
            popoverKey = ["EmployeeName"];
        }
        else {
            popoverOption.showDepartment && popoverKey.push("DepartmentName");
            popoverOption.showProject && popoverKey.push("ProjectName");
            popoverOption.showDuty && popoverKey.push("DutyName");
            popoverOption.showDateJoined && popoverKey.push("DateJoined");
        }
        //get resource image...
        let avatar = src || user?.[fieldImage];
        if (data && !user) {
            user = Config.getUser({[valueExpr]: data[keyExpr]});
            if (!src) {
                avatar = data[fieldImage];
                if (avatar) {
                    avatar = avatar.indexOf('http') < 0 ? Config.getCDNPath() + avatar : avatar;
                } else if (data[keyExpr]) {
                    avatar = user?.[fieldImage] || ""
                }
            }
        }
        
        const infoAvatar = (!renderItemHover && !_.isEmpty(user)) ?
        Config.getInfoAvatar(user,popoverKey) :
        typeof renderItemHover === "function" ?
        renderItemHover(user) :
        renderItemHover;

        return !renderItemHover ? (
            <Avatar
                className={`${className} mgr2x`}
                style={{...style}}
                width={width}
                height={height}
                src={avatar}
                data={infoAvatar}
                lazyLoading
                hoverAble={!_.isEmpty(user) && !!allowHover}
                onClick={onClick}
                direction={"bottom"}
            />
        ) : (
            <Tooltip
                title={infoAvatar}
                hoverAble={!_.isEmpty(user) && !!allowHover}
            >
                <Avatar
                    className={`${className} mgr2x`}
                    style={{...style}}
                    width={width}
                    height={height}
                    src={avatar}
                    lazyLoading
                    hoverAble={false}
                    onClick={onClick}
                    direction={"bottom"}
                />
            </Tooltip>
        );
    }
}

UserImage.defaultProps = {
    valueExpr: "EmployeeID",
    keyExpr: "EmployeeID",
    fieldImage: "UserPictureURL",
    allowHover: true,
    popoverOption: {
        showDepartment: true,
        showProject: true,
        showDuty: true,
        showDateJoined: true
    }
};
UserImage.propTypes = {
    width: propTypes.any,
    height: propTypes.any,
    src: propTypes.string,
    className: propTypes.any,
    style: propTypes.any,
    /**
     * data: {EmployeeID: "abc", UserPicture: ""}
     */
    data: propTypes.any,
    valueExpr: propTypes.string,
    keyExpr: propTypes.string,
    fieldImage: propTypes.string,
    allowHover: propTypes.bool,
    popoverOption: propTypes.object,
    renderItemHover: propTypes.any,
    onClick: propTypes.func,
};

export default compose()(UserImage)